import {
  DEFAULT_QUERY_VALUES,
  HITS_PER_PAGE_ITEMS,
  MAX_PAGINATION,
  MIN_PAGINATION,
  QUERY_KEY
} from '~/constants/algolia';
import isString from '~/helpers/dataStructure/isString';
import { InvalidQueryParametersForSearch } from '~/types/search/InvalidQueryParametersForSearch';
import { SearchQueryParameters } from '~/types/search/SearchQueryParameters';

const isHitsPerPageValid = (parameters: SearchQueryParameters): boolean => {
  if (!(QUERY_KEY.HITS_PER_PAGE in parameters)) {
    return true;
  }
  const hitsPerPage = isString(parameters.hitsPerPage) ? parseInt(parameters.hitsPerPage) : parameters.hitsPerPage;
  return HITS_PER_PAGE_ITEMS.some(item => item.value === hitsPerPage);
};

const isPaginationValid = (parameters: SearchQueryParameters): boolean => {
  if (!(QUERY_KEY.PAGE in parameters)) {
    return true;
  }
  const page = isString(parameters.page) ? parseInt(parameters.page) : parameters.page;
  return !!(page !== undefined && page >= MIN_PAGINATION && page < MAX_PAGINATION && Number.isInteger(page));
};

const getInvalidAlgoliaParameters =
  (parameters: SearchQueryParameters): InvalidQueryParametersForSearch => {
    const invalidParameters: InvalidQueryParametersForSearch = [];

    if (!isHitsPerPageValid(parameters)) {
      invalidParameters.push(QUERY_KEY.HITS_PER_PAGE);
    }
    if (!isPaginationValid(parameters)) {
      invalidParameters.push(QUERY_KEY.PAGE);
    }
    return invalidParameters;
  };

const getNormalisedAlgoliaParameters =
  (queryParameters: SearchQueryParameters, invalidParameters: InvalidQueryParametersForSearch):
  SearchQueryParameters => {
    const normalisedParameters = { ...queryParameters };

    invalidParameters.forEach(parameter => {
      normalisedParameters[parameter] = DEFAULT_QUERY_VALUES[parameter] ?? '';
    });

    return normalisedParameters;
  };

export const checkForValidAlgoliaParameters = (
  parameters: SearchQueryParameters,
  callback: (updatedParameters: SearchQueryParameters) => void
): void => {
  const invalidParameters = getInvalidAlgoliaParameters(parameters);

  if (invalidParameters.length) {
    callback(getNormalisedAlgoliaParameters(parameters, invalidParameters));
  }
};
