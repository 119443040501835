const EXPONDO_LOGO_DARK_PATH = '/logos/expondo-logo-dark.svg';
const EXPONDO_LOGO_LIGHT_MOBILE_PATH = '/logos/expondo-logo-light-mobile.svg';
const EXPONDO_LOGO_LIGHT_DESKTOP_PATH = '/logos/expondo-logo-light-desktop.svg';
const EXPONDO_LOGO_DARK_SIMPLE_PATH = '/logos/expondo-logo-dark-simple.svg';

export {
  EXPONDO_LOGO_DARK_PATH,
  EXPONDO_LOGO_LIGHT_MOBILE_PATH,
  EXPONDO_LOGO_DARK_SIMPLE_PATH,
  EXPONDO_LOGO_LIGHT_DESKTOP_PATH
};
