import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import {
  useProductExtended,
  useCartExtended
} from '~/composables';
import { extractStandardPrice } from '~/helpers/product/getPriceInfo';

const cartLineItemSlugsCookie = 'cart-line-item-slugs';

export const useCheckoutTagManager = () => {
  const { cart } = useCartExtended();
  const { $cookies } = useVSFContext();

  const cartLineItemSlugs = (
    cart.value?.lineItems?.map(item => item.productSlug) ||
    $cookies.get(cartLineItemSlugsCookie) ||
    []
  )?.filter((slug): slug is string => !!slug);

  $cookies.set(cartLineItemSlugsCookie, cartLineItemSlugs);
  const extendedProductsList = cartLineItemSlugs?.map(productSlug => useProductExtended(productSlug));
  const searchProducts = extendedProductsList?.map(item => item.search);

  const products = computed(() => {
    return extendedProductsList?.map(extendedProduct => {
      const { masterProduct, masterProductLocalisedAttributes } = extendedProduct;
      const originalPrice = extractStandardPrice(masterProductLocalisedAttributes.value);
      return {
        ...masterProduct.value,
        originalPrice
      };
    });
  });

  const loadProducts = async () => {
    const searchPromises = cartLineItemSlugs?.map((productSlug, i) => {
      const searchParams = {
        slug: productSlug
      };
      return searchProducts[i](searchParams);
    });
    await Promise.allSettled(searchPromises);
  };

  return {
    products,
    loadProducts
  };
};
