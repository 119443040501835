import { computed } from '@nuxtjs/composition-api';
import { PAYMENT_STATUS } from '~/constants/paymentStatus';
import { useMultibancoPayment, useUserOrder } from '~/composables';

export default function () {
  const { multibancoData } = useMultibancoPayment();
  const { isOrderPaymentTypeBank } = useUserOrder();

  const paymentStatus = computed(() => {
    return (isOrderPaymentTypeBank.value || (multibancoData?.value?.length && multibancoData.value[0]?.value))
      ? PAYMENT_STATUS.IN_PROGRESS
      : PAYMENT_STATUS.PAID;
  });

  return {
    paymentStatus,
    isOrderPaymentTypeBank
  };
}
