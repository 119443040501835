import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { GrahamIntegration } from '~/types/integrations/graham/GrahamIntegration';
import addReview from '~/plugins/integrations/graham/request/addReview';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';

export default (
  $axios: NuxtAxiosInstance
) => {
  const axios = $axios.create(
    getAxiosConfig((process?.server ? $axios.defaults.baseURL : '/') + 'review')
  );

  setAxiosInterceptors({ axios, shouldRejectPromise: true });

  const $graham = {
    services: {
      axios
    }
  } as GrahamIntegration;
  $graham.addReview = addReview($graham);
  return $graham;
};
