enum languageCodes {
  sk = 'sk', // Slovak
  en = 'en', // English
  de = 'de', // German
  pt = 'pt', // Portuguese
  it = 'it', // Italian
  fr = 'fr', // French
  hu = 'hu', // Hungarian
  cs = 'cs', // Czech
  nb = 'nb', // Norwegian Bokmål
  nl = 'nl', // Dutch
  da = 'da', // Danish
  sv = 'sv', // Swedish
  es = 'es', // Spanish
  fi = 'fi', // Finnish
  pl = 'pl', // Polish
  ro = 'ro', // Romanian
  lt = 'lt', // Lithuanian
  sl = 'sl', // Slovenian
  bg = 'bg', // Bulgarian
  el = 'el', // Greek
  hr = 'hr' // Croatian
}

export default languageCodes;
