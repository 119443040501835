
import { PageViewUserType, PageViewPageType } from '~/types/integrations/tagManager/TagManagerIntegration';
import { EVENT_PAGE_VIEW } from '~/constants/googleTagManager';

export class PageView {
  event = EVENT_PAGE_VIEW;
  page: PageViewPageType;
  user: PageViewUserType;
  constructor({ page, user }: {
    page: PageViewPageType,
    user: PageViewUserType
  }) {
    this.page = page;
    this.user = user;
  }
}
