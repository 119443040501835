<template>
  <li
    class="h-1 w-11 rounded-sm cursor-pointer transition-colors"
    @click="handleItemClick"
  />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SimpleCarouselDotNavigationItem',
  setup(_props, { emit }) {
    const handleItemClick = () => {
      emit('click');
    };

    return {
      handleItemClick
    };
  }
});
</script>
