module.exports = {
  STRIP_START_AND_END_QUOTES: /(^"|"$)/g,
  PRODUCT_SLUG_REGEX: /\/?[a-z\-\d]*-(\d{8})/,
  DIGITS_ONLY: /\D/g,
  DIGITS_AND_LETTERS: /[^\w]/g,
  DIGITS_LETTERS_AND_SPECIAL_CHARS: /\s/g,
  ENDING_FORWARD_SLASHES: /\/+$/,
  TYPESENSE_SORTING_TYPE_SPLIT: /(?=[A-Z])/,
  VOLUME_LESS_OR_EQUAL_THAN: /quantity >= (?<volume>\d+)/
};
