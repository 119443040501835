import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type { CartSidebarState } from '~/types/vuex/CartSidebar';

const state = () => ({
  isCartSidebarOpen: false
});

const mutations = {
  toggleCartSidebar(state: CartSidebarState) {
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  },
  closeCartSidebar(state: CartSidebarState) {
    state.isCartSidebarOpen = false;
  }
};

export default extendModule({
  state, mutations
});
