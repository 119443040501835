import { isPreProduction } from '../../env/checkEnvironmentType';
import { DEV_PREFIX, WWW_PREFIX } from '../../../constants/http';

const preprodIndicator = `${DEV_PREFIX}.`;
const prodIndicator = `${WWW_PREFIX}.`;

export const adjustHostnameForPreProduction = (hostname) => {
  return isPreProduction() ? hostname.replace(preprodIndicator, prodIndicator) : hostname;
};

export const hardcodeHostnameAsPreproduction = (hostname) => {
  return hostname.replace(prodIndicator, preprodIndicator);
};
