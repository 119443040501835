import type { Address, CartUpdateAction } from '@vsf-enterprise/commercetools-types';
import { useVSFContext } from '@vue-storefront/core';
import {
  useCheckoutShipping,
  useCheckoutBilling,
  useCartExtended,
  useVatValidation,
  useShippingValidation, useClientEmail
} from '~/composables';
import {
  setVatNumber,
  VatNumber
} from '~/helpers/cart/customFields';
import { setBillingAddressAction, setCustomerEmail, setShippingAddressAction } from '~/helpers/cart/cartActions';
import { ValidationProvider } from '~/types/vee-validate';

const useCheckoutAddresses = () => {
  const {
    billingDetails,
    vatNumber,
    handleBillingBeforeSubmit,
    handleBillingAfterSubmit,
    displayNotification: displayBillingNotification
  } = useCheckoutBilling();
  const {
    shippingDetails,
    handleShippingBeforeSubmit,
    handleShippingAfterSubmit,
    displayNotification: displayShippingNotification
  } = useCheckoutShipping();
  const { updateCustomCart } = useCartExtended();
  const { result: vatResult } = useVatValidation();
  const { clientEmail } = useClientEmail();
  const { i18n } = useVSFContext();
  const { error: validateShippingError } = useShippingValidation();
  const { error: vatValidationError } = useVatValidation();

  async function setAddressesDetails(newShippingDetails: Partial<Address>, newBillingDetails: Partial<Address>,
    email: string, vatNumber?: VatNumber | null) {
    const actions: CartUpdateAction[] = [
      setShippingAddressAction(newShippingDetails),
      setCustomerEmail(email),
      setBillingAddressAction(newBillingDetails)
    ];
    if (vatNumber) {
      actions.push(setVatNumber('Billing', vatNumber, vatResult.value));
    }
    await updateCustomCart(actions);
  }
  const checkForErrors = ({
    isShippingValid,
    shippingFields,
    isBillingValid,
    billingFields
  }: {
    isShippingValid: boolean;
    shippingFields: Record<string, ValidationProvider> | undefined;
    isBillingValid: boolean;
    billingFields: Record<string, ValidationProvider> | undefined;
  }): void => {
    if (!isBillingValid) {
      displayBillingNotification(false, billingFields);
      throw new Error('Billing Address Error');
    } else if (vatValidationError.value) {
      displayBillingNotification(true);
      throw new Error('Billing vat validation error');
    } else if (!isShippingValid) {
      displayShippingNotification(false, shippingFields);
      throw new Error('Shipping Address Error');
    } else if (validateShippingError.value) {
      displayShippingNotification(true);
      throw new Error('Shipping Address Error');
    }
  };

  const handleAddressesSubmit = async (shippingValidateWithInfo: Function, billingValidateWithInfo: Function) => {
    const { isValid: isShippingValid, fields: shippingFields } = await shippingValidateWithInfo();
    const { isValid: isBillingValid, fields: billingFields } = await billingValidateWithInfo();
    try {
      await handleBillingBeforeSubmit(isBillingValid, billingFields);
      await handleShippingBeforeSubmit(isShippingValid, shippingFields);
      checkForErrors({ isShippingValid, shippingFields, isBillingValid, billingFields });
      await setAddressesDetails(shippingDetails.value, billingDetails.value, clientEmail.value, vatNumber.value);
      await handleShippingAfterSubmit();
      await handleBillingAfterSubmit();
    } catch (err) {}
  };

  const getSaveAddressCheckboxLabel = (addressDetails: Partial<Address>) => {
    return !addressDetails.id
      ? i18n.t('Save address')
      : i18n.t('Save address changes');
  };

  return {
    handleAddressesSubmit,
    setAddressesDetails,
    getSaveAddressCheckboxLabel,
    checkForErrors
  };
};

export default useCheckoutAddresses;
