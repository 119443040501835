import { BloomreachIntegration } from '~/types/integrations/bloomreach/BloomreachIntegration.js';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { getRecommendationFactory } from '~/plugins/integrations/bloomreach/helpers/getRecommendationFactory';
import { BLOOMREACH_TYPE_NAME } from '~/types/localization/localizationHelperTypes';

export default (instance: BloomreachIntegration) => {
  const typeName = BLOOMREACH_TYPE_NAME.LAST_SEEN;
  const getRecommendation = getRecommendationFactory({ instance, typeName });

  return async (): Promise<BloomreachProduct[] | null> => {
    return await getRecommendation({});
  };
};
