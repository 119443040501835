const DEVICE_FINGERPRINT_COOKIE = 'device-fingerprint';
const shippingAddressTypeCookie = 'shippingAddressType';
const GOOGLE_ANALYTIC_COOKIE = '_ga';
const ALGOLIA_COOKIE = '_ALGOLIA';
const VUESTOREFRONT_COOKIE = {
  COMMERCETOOLS_TOKEN: 'vsf-commercetools-token',
  COUNTRY: 'vsf-country',
  LOCALE: 'vsf-locale'
};
const COOKIE_SEPARATOR = '; ';

module.exports = {
  DEVICE_FINGERPRINT_COOKIE,
  shippingAddressTypeCookie,
  GOOGLE_ANALYTIC_COOKIE,
  ALGOLIA_COOKIE,
  VUESTOREFRONT_COOKIE,
  COOKIE_SEPARATOR
};
