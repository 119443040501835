import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { ViewParentProductsTags } from '~/plugins/integrations/tagManager/helpers/ViewParentProductsTags';
import { prepareParentProductsTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { pushCleanTags } from '~/plugins/integrations/tagManager/helpers/pushCleanTags/pushCleanTags';

export const triggerViewParentProductsTagsWrapper = ($gtm: Gtm) => {
  return (products: ProductVariant[], accessoryProductSku: string) => {
    const viewParentProductsTags = new ViewParentProductsTags();
    viewParentProductsTags.assignSourceAccessoryId(accessoryProductSku);
    viewParentProductsTags.assigParentProductsValues(
      prepareParentProductsTags(products)
    );
    pushCleanTags($gtm, viewParentProductsTags);
  };
};
