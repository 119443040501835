import { computed } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import CountryLocalization from '~/types/localization/countryLocalization';
import { localizationDefinitions } from '~/i18n';
import { getPrefixedSlug } from '~/helpers/routes/prefixes';
import { isLocal } from '~/helpers/env/checkEnvironmentType';
import { UNSECURE_WEBSITE_PROTOCOL_PREFIX, SECURE_WEBSITE_PROTOCOLE_PREFIX } from '~/constants/http';
import { CountryCodeValue } from '~/types/localization/localizationHelperTypes';

export const useI18n = () => {
  const { i18n } = useVSFContext();

  const countryLocalization = computed<CountryLocalization>(
    () => localizationDefinitions.getByCountryCode(
      i18n.localeProperties.country as CountryCodeValue) as CountryLocalization);

  /**
   * @returns locale in 5 characters standard, like `en-GB`
   * The property `code` is a mandatory i18n field and contains the language and country code.
  */
  const languageAndCountry: ComputedRef<string> = computed(() => i18n.localeProperties.code);

  /**
   * @returns language code in 2 characters standard, like `en`
  */
  const language = computed(
    () => countryLocalization.value.getLanguageFromExistingLanguageAndCountry(languageAndCountry.value));

  const countryCode = computed(() => countryLocalization.value.countryCode);
  const currencyCode = computed(() => countryLocalization.value.currencyCode);
  const isDefaultLanguageAndCountry = computed(
    () => countryLocalization.value.isDefaultLanguageAndCountry(languageAndCountry.value));
  const countryLabel = computed(() => countryLocalization.value.label);
  const domain = computed(() => `${isLocal()
    ? UNSECURE_WEBSITE_PROTOCOL_PREFIX
    : SECURE_WEBSITE_PROTOCOLE_PREFIX}${i18n.localeProperties.domain}`
  );
  const buildLanguagePrefixedUrlBasedOnActiveLanguage = (urlSlug: string) => {
    if (isDefaultLanguageAndCountry.value) {
      return urlSlug;
    }
    return getPrefixedSlug(urlSlug, language.value);
  };

  return {
    currencyCode,
    languageAndCountry,
    countryCode,
    countryLocalization,
    countryLabel,
    isDefaultLanguageAndCountry,
    language,
    buildLanguagePrefixedUrlBasedOnActiveLanguage,
    domain
  };
};
