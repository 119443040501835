import type { Category } from '@vsf-enterprise/commercetools-types';
import { CATEGORY_TREE } from '~/constants/categoryTree';

export const isRootCategory = (category: Category): boolean => {
  const categoryTreesKeys = Object.values(CATEGORY_TREE).map(tree => tree.key);
  if (categoryTreesKeys.filter(treeKey => treeKey === category.key).length) {
    return true;
  }

  return false;
};
