const STANDARD_PRODUCT = 'standard_product';
const EXTRA_GUARANTEE = 'extra_guarantee';
const ITEM_ACCESSORY = 'accessory';
const ITEM_PRODUCT = 'product';

module.exports = {
  STANDARD_PRODUCT,
  EXTRA_GUARANTEE,
  ITEM_ACCESSORY,
  ITEM_PRODUCT
};
