import { Gtm } from '~/types/@nuxtjs/gtm';
import { ClickAccessoryTags } from '~/plugins/integrations/tagManager/helpers/ClickAccessoryTags';

export const triggerClickAccessoryTagsWrapper = ($gtm: Gtm) => {
  return (productSku: string, parentProductSku: string) => {
    const clickAccessoryTags = new ClickAccessoryTags();
    clickAccessoryTags.assignAccessoryValues(productSku, parentProductSku);
    $gtm.push(clickAccessoryTags);
  };
};
