<template>
  <div
    class="simple-carousel-item flex"
    @click="handleItemClick"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SimpleCarouselItem',
  setup(_props, { emit }) {
    const handleItemClick = () => {
      emit('click');
    };

    return {
      handleItemClick
    };
  }
});
</script>
