const middleware = {}

middleware['check-search-params'] = require('../middleware/check-search-params.js')
middleware['check-search-params'] = middleware['check-search-params'].default || middleware['check-search-params']

middleware['checkout-billing'] = require('../middleware/checkout-billing.js')
middleware['checkout-billing'] = middleware['checkout-billing'].default || middleware['checkout-billing']

middleware['first-page-redirection'] = require('../middleware/first-page-redirection.js')
middleware['first-page-redirection'] = middleware['first-page-redirection'].default || middleware['first-page-redirection']

middleware['force-lowercase'] = require('../middleware/force-lowercase.js')
middleware['force-lowercase'] = middleware['force-lowercase'].default || middleware['force-lowercase']

middleware['get-client-ip'] = require('../middleware/get-client-ip.js')
middleware['get-client-ip'] = middleware['get-client-ip'].default || middleware['get-client-ip']

middleware['home-redirection'] = require('../middleware/home-redirection.js')
middleware['home-redirection'] = middleware['home-redirection'].default || middleware['home-redirection']

middleware['is-authenticated'] = require('../middleware/is-authenticated.js')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-ex-consent-request'] = require('../middleware/is-ex-consent-request.js')
middleware['is-ex-consent-request'] = middleware['is-ex-consent-request'].default || middleware['is-ex-consent-request']

middleware['is-old-url-with-prefix'] = require('../middleware/is-old-url-with-prefix.js')
middleware['is-old-url-with-prefix'] = middleware['is-old-url-with-prefix'].default || middleware['is-old-url-with-prefix']

middleware['localize-url'] = require('../middleware/localize-url.js')
middleware['localize-url'] = middleware['localize-url'].default || middleware['localize-url']

middleware['non-authenticated'] = require('../middleware/non-authenticated.js')
middleware['non-authenticated'] = middleware['non-authenticated'].default || middleware['non-authenticated']

middleware['prefix-checker'] = require('../middleware/prefix-checker.js')
middleware['prefix-checker'] = middleware['prefix-checker'].default || middleware['prefix-checker']

middleware['slash-redirection'] = require('../middleware/slash-redirection.js')
middleware['slash-redirection'] = middleware['slash-redirection'].default || middleware['slash-redirection']

export default middleware
