import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { getTypesenseAdapter } from './typesense';
import { AVAILABLE_FACETS } from '~/constants/algolia';
import { localizationDefinitions } from '~/i18n';
import { checkForValidAlgoliaParameters } from '~/helpers/algolia/algoliaParameters';
export function getAlgoliaClient(
  languageAndCountry: string | null, mockEmptySearchResults:boolean = false) {
  let options = {};

  if (process?.server && languageAndCountry) {
    const algoliaRefererDomain = localizationDefinitions.getByLanguageAndCountry(languageAndCountry)?.getDomain();
    options = { headers: { referer: algoliaRefererDomain } };
  }

  const searchClient = getTypesenseAdapter(options, languageAndCountry).searchClient;

  if (mockEmptySearchResults) {
    return getSearchClientSkippingEmptyQueryRequest(searchClient);
  }

  return searchClient;
}

function getSearchClientSkippingEmptyQueryRequest(searchClient: SearchClient) {
  return {
    ...searchClient,
    /**
     * This is the solution indicated by official docs to avoid the initial empty query
     * https://www.algolia.com/doc/guides/building-search-ui/
     * going-further/conditional-requests/vue/#detecting-empty-search-requests
     **/
    search(requests: Array<{ indexName: string, params: { query: string } }>) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: ''
          }))
        });
      }

      return searchClient.search(requests);
    }

  };
}

export function getAlgoliaClientWithAllFacets(languageAndCountry: string | null): SearchClient {
  const algoliaClient = getAlgoliaClient(languageAndCountry);
  const search = algoliaClient.search;
  // Replacing algoliaClient search function with custom one - it will always receive all facets data for current query
  // @ts-ignore
  algoliaClient.search = function (queries) {
    checkForValidAlgoliaParameters(queries[0].params, (updatedQueries) => {
      queries[0].params = updatedQueries;
    });
    // @ts-ignore
    queries[0].params.facets = AVAILABLE_FACETS;
    return search(queries);
  };

  return algoliaClient;
}
