import { sharedRef } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';
import VueScrollTo from 'vue-scrollto';
import type { Ref } from '@nuxtjs/composition-api';
import { computed } from '@nuxtjs/composition-api';
import { AddressType } from '~/types/AddressType';
import { ValidationProvider } from '~/types/vee-validate';
import { CheckoutClientType } from '~/types/checkout/formData/checkoutClientType';

interface ResetBusinessInfoParams {
  setVatNumber: (param: string) => Promise<void>;
  clientType: CheckoutClientType
}

interface UseCheckoutAddressParams {
  addressType: AddressType,
  scrollToTopOfForm: string,
  addressDetails: Ref<Partial<Address>>
}

export default (
  {
    addressType,
    scrollToTopOfForm,
    addressDetails
  }: UseCheckoutAddressParams) => {
  const displayErrorNotification = sharedRef(false, `displayErrorNotification-${addressType}`);
  const displayWarningNotification = sharedRef(false, `displayWarningNotification-${addressType}`);

  const displayNotification = (isWarning = false, fields?: Record<string, ValidationProvider> | undefined) => {
    displayErrorNotification.value = !isWarning;
    displayWarningNotification.value = isWarning;
    const firstInvalidId = getFirstInvalidField(fields);
    VueScrollTo.scrollTo(firstInvalidId || scrollToTopOfForm, { offset: -50 });
  };

  const getFirstInvalidField = (fields: Record<string, ValidationProvider> | undefined): string | undefined => {
    const fieldValues = Object.values(fields || {});
    const firstInvalidField = fieldValues.find(field => field.failed);
    return firstInvalidField ? `#${firstInvalidField.name}` : undefined;
  };

  const resetNotifications = () => {
    displayErrorNotification.value = false;
    displayWarningNotification.value = false;
  };
  const displayAnyNotification = computed(() =>
    displayErrorNotification.value ||
    displayWarningNotification.value
  );
  const scrollToField = sharedRef<String>('', `scrollToField-${addressType}`);
  const setScrollToField = (field: string) => {
    scrollToField.value = field;
  };

  const removeUnnecessaryAddressProperties = () => {
    delete addressDetails.value.__typename;
    delete addressDetails.value.custom;
  };

  const resetBusinessInfoForPrivateCustomer = async ({
    setVatNumber,
    clientType
  }: ResetBusinessInfoParams) => {
    if (clientType === CheckoutClientType.PRIVATE) {
      await setVatNumber('');
      addressDetails.value.company = null;
      addressDetails.value.department = null;
    }
  };

  return {
    displayErrorNotification,
    displayWarningNotification,
    displayAnyNotification,
    displayNotification,
    resetNotifications,
    scrollToField,
    setScrollToField,
    getFirstInvalidField,
    resetBusinessInfoForPrivateCustomer,
    removeUnnecessaryAddressProperties
  };
};
