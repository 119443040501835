const { Logger } = require('@vue-storefront/core');
const { ROUTES } = require('../../constants/routes');
const { PRODUCT_SLUG_REGEX, ENDING_FORWARD_SLASHES } = require('../../constants/regex');
const { ROUTES_INFORMATION, ABSOLUTE_ROUTE_START } = require('../../constants/routes');
const { PAGE_DELIVERY_KEYS } = require('../../constants/cms');
const { isAtLeastOneFunctionTruthy } = require('../dataStructure/arrayOperations');
const publishedCmsPages = require('../../static/cms-schemas/published-pages.json');
const { isPrefix, getPrefix, hasPrefix } = require('./prefixes');

/**
 * @param {keyof ROUTES_INFORMATION} routeIdentifier
 * @returns string
 */
function getSlugForRoute (routeIdentifier) {
  return getPageLinkForSlug(ROUTES_INFORMATION[routeIdentifier].SLUG);
}
/**
 * @param {keyof ROUTES_INFORMATION} routeIdentifier
 * @returns string
 */
function getNameForRoute (routeIdentifier) {
  return ROUTES_INFORMATION[routeIdentifier].NAME;
}

/**
 * @param {{ slug: string, hasEndingSlash: boolean }} parameters
 * @returns string
 */
function slugToAbsolutePath ({ slug, hasEndingSlash }) {
  return `/${slug}${hasEndingSlash ? '/' : ''}`;
}

/**
 * @param {string} path
 * @returns {boolean}
 */
function isHomePageSlug (path) {
  const pathElements = path.replace(ENDING_FORWARD_SLASHES, '').split('/');
  const slug = pathElements[pathElements.length - 1];
  return slug === ROUTES_INFORMATION[ROUTES.HOME].SLUG || slug === '' || isPrefix(slug);
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isProductPageSlug (slug) {
  if (!slug) {
    return false;
  }
  const match = slug.match(PRODUCT_SLUG_REGEX);
  const isSlugWithEndSlash = slug.slice(-1) === '/';
  const url = isSlugWithEndSlash ? slug.slice(0, -1) : slug;
  return match?.[0] === url;
}

/**
 * @param {string} slug
 * @returns {number}
 */
function slugCheckStartIndex (slug) {
  const slugIndexAfterPrefix = 3;
  return hasPrefix(slug) ? slugIndexAfterPrefix : 0;
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isSearchPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.SEARCH].SLUG, slugCheckStartIndex(slug));
}
/**
 * @param {string} slug
 * @returns {boolean}
 */
function isAddressesPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.CHECKOUT_ADDRESSES].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isLoginPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.LOGIN].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isCheckoutPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.CHECKOUT].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isMyAccountPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.MY_ACCOUNT].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isResetPasswordPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.RESET_PASSWORD].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isCreateAccountPageSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.CREATE_ACCOUNT].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {Maybe<string>} slug
 * @param {string} languageAndCountry
 * @returns {boolean}
 */
function isValidCmsPage (slug, languageAndCountry) {
  const checkedSlug = slug || PAGE_DELIVERY_KEYS.HOME.SLUG;
  const checkedFileName = `${checkedSlug}-${languageAndCountry}`;
  const typedPublishedPages = publishedCmsPages;
  return !!typedPublishedPages[checkedFileName];
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isUnindexedPageSlug (slug) {
  const unindexedPageChecks = [
    isLoginPageSlug,
    isCheckoutPageSlug,
    isMyAccountPageSlug,
    isResetPasswordPageSlug,
    isCreateAccountPageSlug,
    isSearchPageSlug
  ];
  return isAtLeastOneFunctionTruthy(unindexedPageChecks, slug);
}

/**
 * @param {string} str
 * @returns {string}
 */
function removeStartingAndEndingSlash(str) {
  if (str.startsWith('/')) {
    str = str.substring(1);
  }
  if (str.endsWith('/')) {
    str = str.substring(0, str.length - 1);
  }
  return str;
}

/**
 * @param {string} slug
 * @returns {string}
 */
function getPageLinkForSlug (slug) {
  if (!slug) {
    Logger.info('undefined slug detected');
    return '';
  }
  const slugKeywords = removeStartingAndEndingSlash(slug);
  const prefix = getPrefix(slug);
  let testedSlug = slug;
  if (prefix) {
    testedSlug = slug.replace(`/${prefix}`, '');
  }
  if (isHomePageSlug(testedSlug)) {
    return slugToAbsolutePath({ slug: prefix || '', hasEndingSlash: prefix });
  }
  const shouldEndWithSlash = !isProductPageSlug(testedSlug) && !isUnindexedPageSlug(testedSlug);
  return slugToAbsolutePath({
    slug: slugKeywords,
    hasEndingSlash: shouldEndWithSlash
  });
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isHiddenRouteSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.HIDDEN].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isBuildRouteSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.BUILD].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isApiRouteSlug (slug) {
  return slug.startsWith(ROUTES_INFORMATION[ROUTES.API].SLUG, slugCheckStartIndex(slug));
}

/**
 * @param {string} slug
 * @returns {boolean}
 */
function isFontSlug (slug) {
  return slug.includes(getSlugForRoute(ROUTES.FONT));
}

/**
 * @param {string} slug
 * @param { import('vue-router/types').RouteConfig } routeMap
 * @returns {boolean}
 */
function isHardcodedRoute (slug, routeMap) {
  const hardcodedRoutes = routeMap.filter(route => !route.path.startsWith(ABSOLUTE_ROUTE_START));
  return hardcodedRoutes.some(route => {
    return slug.match(route.regex);
  });
}

/**
 * @param {string} slug
 * @param { import('vue-router/types').RouteConfig } routeMap
 * @returns {boolean}
 */
function isConstantRoute (slug, routeMap) {
  const constantSlugChecks = [
    isHardcodedRoute,
    isFontSlug,
    isHiddenRouteSlug,
    isBuildRouteSlug,
    isApiRouteSlug
  ];
  return isAtLeastOneFunctionTruthy(constantSlugChecks, slug, routeMap);
}

module.exports = {
  isHomePageSlug,
  isProductPageSlug,
  isSearchPageSlug,
  isValidCmsPage,
  getPageLinkForSlug,
  getSlugForRoute,
  getNameForRoute,
  slugToAbsolutePath,
  isAddressesPageSlug,
  isConstantRoute
};
