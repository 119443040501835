import isString from '~/helpers/dataStructure/isString';
import { AWARE_PRODUCT_SKU_BEGINNING_CHARS, BWARE_PRODUCT_SKU_BEGINNING_CHARS } from '~/constants/products';
export const isAWareProduct = (sku: Maybe<string> = ''): boolean => {
  if (!isString(sku)) {
    return false;
  }
  return sku.startsWith(AWARE_PRODUCT_SKU_BEGINNING_CHARS);
};

export const isBWareProduct = (sku: Maybe<string> = ''): boolean => {
  if (!isString(sku)) {
    return false;
  }
  return sku.startsWith(BWARE_PRODUCT_SKU_BEGINNING_CHARS);
};
