































































































































import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfPrice, SfButton } from '@storefront-ui/vue';
import { cartGetters, useUser } from '@vsf-enterprise/commercetools';
import { onSSR, useVSFContext, Logger } from '@vue-storefront/core';
import { CT_COOKIE_NAME } from '@vsf-enterprise/commercetools-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { HitResultItem } from 'vue-instantsearch';
import SearchBar from '~/components/molecules/SearchBar.vue';
import NavMainBar from '~/components/molecules/Navigation/NavMainBar/NavMainBar.vue';
import TopMenuLanguageSelector from '~/components/molecules/TopMenuLanguageSelector.vue';
import PhoneNumber from '~/components/atoms/PhoneNumber.vue';
import { createUserCookie } from '~/api/auth';
import {
  useUiState,
  useNavigationState,
  useUserExtended,
  useRouteExtended,
  useIntegrations,
  useCartExtended,
  useCookies,
  useI18n,
  useAlgolia,
  useAlgoliaGtmEvents,
  useTrustPilotWidget,
  useIsPage,
  useCartSidebars,
  useCartSidebarStore,
  useIsMounted
} from '~/composables';
import CartIcon from '~/components/atoms/CartIcon.vue';
import { ROUTES } from '~/constants/routes';
import MyAccountDropdown from '~/components/molecules/MyAccountDropdown.vue';
import startDatadogUser from '~/helpers/logging/startDatadogUser';
import { SCHEMA_ITEM_TYPE } from '~/constants/seo';
import { i18nToLanguageAndCountry } from '~/helpers/locales/i18nCurrentDetails';
import { EXPONDO_LOGO_LIGHT_MOBILE_PATH, EXPONDO_LOGO_LIGHT_DESKTOP_PATH } from '~/constants/expondoLogoPath';
import { useMyOrders } from '~/composables/useMyOrders';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';
import TrustpilotMicroCombo from '~/components/atoms/TrustPilot/TrustpilotMicroCombo.vue';
import { PHONE_NUMBER_KEYS } from '~/constants/phoneNumber';
import { TRUSTPILOT_THEMES } from '~/constants/trustPilot';

export default defineComponent({
  components: {
    MyAccountDropdown,
    CartIcon,
    SearchBar,
    SfPrice,
    SfButton,
    NavMainBar,
    TrustpilotMicroCombo,
    TopMenuLanguageSelector,
    PhoneNumber
  },
  props: {
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    },
    componentCaching: {
      type: String,
      default: ''
    },
    displayTrustpilot: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { languageAndCountry, countryLocalization } = useI18n();
    const { toggleCartSidebarWithDelay } = useCartSidebars();
    const { closeCartSidebar } = useCartSidebarStore();
    const { mainSearchIndex } = useAlgolia();
    const { $auth, $cookies, app: { router } } = useVSFContext();
    const { $cia } = useIntegrations();
    const { getAdjustedSlug, currentRoute } = useRouteExtended();
    const { isThankYouPage } = useIsPage();
    const { posthogDistinctId } = useCookies();
    const { toggleWishlistSidebar } = useUiState();
    const { toggleNavigation, isNavigationOpen } = useNavigationState();
    const { isAuthenticated } = useUser();
    const { user } = useUserExtended();
    const { callViewHitsEvent, callClickHitEvent } = useAlgoliaGtmEvents();
    const { showTrustPilotInHeader } = useTrustPilotWidget();
    const { isMounted } = useIsMounted();

    const { cart } = useCartExtended();
    const searchPageUrl = getAdjustedSlug(ROUTES.SEARCH);
    const isDropdownOpen = ref(false);
    const isSearchBarOpen = ref(false);
    const { setUrlWithToken } = useMyOrders();
    const cartTotalPrice = computed(() => getTotalGrossPrice(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const isMultiLanguage = computed(() => countryLocalization.value?.isMultiLanguage);
    const accountIcon = computed(() => isAuthenticated.value ? 'profile_fill' : 'profile');
    const toggleSearchBar = () => {
      isSearchBarOpen.value = !isSearchBarOpen.value;
    };

    const handleAccountClick = () => {
      hideNavigation();
      if (isAuthenticated.value) {
        isDropdownOpen.value = !isDropdownOpen.value;
        return;
      }
      router.push(getAdjustedSlug(ROUTES.LOGIN));
    };

    watch(() => currentRoute.value?.path, () => {
      closeCartSidebar();
    });

    const hideNavigation = () => {
      if (isNavigationOpen.value) {
        toggleNavigation();
      }
    };

    const handleCartIconClick = () => {
      if (isMounted.value) {
        hideNavigation();
        toggleCartSidebarWithDelay();
      }
    };

    const handleLogoClick = () => {
      hideNavigation();
      router.push(getAdjustedSlug(ROUTES.HOME));
    };

    const onProductClicked = ({ product, searchTerm }: {product: HitResultItem, searchTerm: string}) => {
      callClickHitEvent(product, searchTerm);
    };

    onMounted(() => {
      $cia.util.initTracker();

      if (!isAuthenticated.value && $auth?.loggedIn) {
        /** TODO: FIX IT SOMEHOW BY MORE SOPHISTICATED SOLUTION
         * search in auth composable
         * clean state, try force methods logout, login
         */
        window.location.href = window.location.pathname;
      }
      startDatadogUser();
    });

    onSSR(async () => {
      try {
        $cia.mutations.setPosthogDistinctId(posthogDistinctId);
      } catch (error) {
        Logger.error(`cia|setPosthogDistinctId error: ${error}`);
      }

      if (!isAuthenticated.value && $auth.loggedIn) {
        const token = $auth.strategy.token.get();
        const cookie = $cookies.get(CT_COOKIE_NAME);
        const provider = $auth.$state.strategy;
        const responseCookie = await createUserCookie(token, cookie, provider);
        $cookies.set(CT_COOKIE_NAME, responseCookie);
      }
    });

    watch(user, () => {
      setUrlWithToken(user.value);
    });

    const closeDropdown = () => {
      isDropdownOpen.value = false;
    };

    return {
      accountIcon,
      totalItems,
      cartTotalPrice,
      handleAccountClick,
      toggleWishlistSidebar,
      searchPageUrl,
      isDropdownOpen,
      closeDropdown,
      toggleNavigation,
      isNavigationOpen,
      homePath: getAdjustedSlug(ROUTES.HOME),
      EXPONDO_LOGO_LIGHT_MOBILE_PATH,
      EXPONDO_LOGO_LIGHT_DESKTOP_PATH,
      TRUSTPILOT_THEMES,
      isSearchBarOpen,
      toggleSearchBar,
      handleCartIconClick,
      languageAndCountry,
      mainSearchIndex,
      callViewHitsEvent,
      callClickHitEvent,
      showTrustPilotInHeader,
      hideNavigation,
      handleLogoClick,
      isMultiLanguage,
      isThankYouPage,
      onProductClicked,
      customerCarePhoneNumber: PHONE_NUMBER_KEYS?.CUSTOMER_CARE
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: i18nToLanguageAndCountry(this.$i18n),
        itemscope: 'itemscope',
        itemtype: SCHEMA_ITEM_TYPE.WEB_PAGE
      }
    };
  }
});

