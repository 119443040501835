import { EVENT_CONVERSION } from '~/constants/googleTagManager';

export class ConversionTags {
  event = EVENT_CONVERSION;
  userToken?: string;
  index: string;
  objectIDs: string[];
  searchTerm: string;
  constructor({ userToken, index, objectIDs, searchTerm }: {
    userToken?: string,
    index: string,
    objectIDs: string[],
    searchTerm: string,
  }) {
    this.userToken = userToken;
    this.index = index;
    this.objectIDs = objectIDs;
    this.searchTerm = searchTerm;
  }
}
