import { Logger } from '@vue-storefront/core';

export default ({ $vsf, route, redirect }) => {
  $vsf.$ct.api.isLoggedIn().then((isLoggedIn) => {
    if (!isLoggedIn) {
      throw new Error(`"${route.fullPath}" route is only available to logged-in customers`);
    }
  }).catch((error) => {
    Logger.warn(error.toString());
    return redirect('/');
  });
};
