import { IntegrationCollection } from '~/types/integrations/IntegrationCollection';

interface UseIntegrationsState {
  integrations: IntegrationCollection
}

// not using reactive as for unknown reason it removes ssrRef objects from all integrations
// integrations here are just references to plugins
const state: UseIntegrationsState = {
  // registerIntegrations() will be called before using integrations
  integrations: {} as IntegrationCollection
};

// created to have possibility to use integration methods within other composables where nuxt context is unreachable
export const useIntegrations = () => {
  const registerIntegrations = (integrations: IntegrationCollection) => {
    state.integrations = integrations;
  };

  return {
    $tagManager: state.integrations.$tagManager,
    $cia: state.integrations.$cia,
    $vat: state.integrations.$vat,
    $graham: state.integrations.$graham,
    $myOrders: state.integrations.$myOrders,
    $bloomreach: state.integrations.$bloomreach,
    registerIntegrations
  };
};
