export const VSF_REDIS_CACHE_INVALIDATE_KEY = 'remove_all_keys_from_redis';
export const VSF_REDIS_CACHE_INVALIDATE_URL = '/cache-invalidate';
export const COMPONENT_CACHE_INVALIDATE_KEY = 'dfLLa12qaPi3Ja08eTIJh30Z05OcYS';
export const COMPONENT_CACHE_INVALIDATE_URL = '/component-cache/invalidate-tag';

export const APPLICATION_ENVIRONMENT = {
  PRODUCTION: 'production',
  STAGE: 'stage',
  DEV: 'dev',
  LOCAL: 'local'
} as const;

export const PAGE_VIEV_ENVIRONMENT = {
  PROD: 'prod',
  DEV: 'dev'
} as const;
