import { BREADCRUMBS_CLICK, BREADCRUMBS_CLICK_DETAIL } from '~/constants/googleTagManager';

/* eslint-disable camelcase */
export class ClickBreadcrumbs {
  event = BREADCRUMBS_CLICK;
  event_info: {
    detail: string,
    category_name: string,
    category_path: string,
    category_level: number,
    source: string
  };

  constructor({ categoryName, categoryPath, categoryLevel, source }: {
    categoryName: string;
    categoryPath: string;
    categoryLevel: number;
    source: string;
  }) {
    this.event_info = {
      detail: BREADCRUMBS_CLICK_DETAIL,
      category_name: categoryName,
      category_path: categoryPath,
      category_level: categoryLevel,
      source
    };
  }
}
