






import { defineComponent } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import RenderContent from '~/cms/RenderContent.vue';
import { useInternalCms } from '~/composables';
import { COMPONENTS } from '~/constants/cms';

export default defineComponent({
  name: 'DefaultFooter',
  components: {
    RenderContent
  },
  setup() {
    const { loadComponent, internalCmsContent, canShowContent } = useInternalCms(COMPONENTS.APP_FOOTER.SLUG);

    onSSR(async () => {
      await loadComponent();
    });

    return {
      internalCmsContent,
      canShowContent
    };
  }
});
