import { computed } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';
import type { HitResultItem } from 'vue-instantsearch';
import { useFacet, useIntegrations, useI18n, useCategoryTree } from '~/composables';
import { DEFAULT_QUERY_VALUES, QUERY_KEY } from '~/constants/algolia';
import { CATEGORY_TREE } from '~/constants/categoryTree';
import { CATEGORY_CUSTOM_FIELDS, FACET_SORTS } from '~/constants/commerceTools';

export const useCategories = () => {
  const { $cia } = useIntegrations();
  const { search, result, error: facetError, loading: facetLoading } = useFacet();
  const { categoryTree, load: loadCategoryTree } = useCategoryTree();
  const { languageAndCountry } = useI18n();
  const triggerViewCategoryEvent = (products: HitResultItem[], categorySlug: string) => {
    const productSkus = products.map((item) => item.objectID);
    try {
      $cia.event.viewCategory(categorySlug, productSkus);
    } catch (error) {
      Logger.error(`cia|viewCategory error: ${error}`);
    }
  };

  const isNewCategoryTree = computed(() => categoryTree.value.key !== CATEGORY_TREE.DEFAULT.key);

  const searchWithDefaultParameters = async (categorySlug: string) => {
    await loadCategoryTree(categorySlug, languageAndCountry.value);

    await search({
      page: 1,
      sort: FACET_SORTS.LATEST,
      filters: {},
      itemsPerPage: DEFAULT_QUERY_VALUES[QUERY_KEY.HITS_PER_PAGE],
      term: '',
      categorySlug,
      languageAndCountry: languageAndCountry.value,
      fetchSiblings: !isNewCategoryTree.value
    });
  };

  const categories = computed(() => {
    return result.value?.data?.categories ?? [];
  });

  const currentCategory = computed(() => {
    return result.value.data?.currentCategory;
  });

  const activeCategories = computed(() => {
    return result.value.data?.activeCategories;
  });

  return {
    triggerViewCategoryEvent,
    searchWithDefaultParameters,
    categories,
    activeCategories,
    currentCategory,
    isNewCategoryTree,
    error: {
      facet: facetError
    },
    loading: {
      facet: facetLoading
    },
    getters: {
      currentCategorySlugAllLocales: computed(() => result.value?.data?.currentCategory?.slugAllLocales),
      currentCategoryMetaTitle: computed(() => result.value?.data?.currentCategory?.metaTitle),
      currentCategoryMetaDescription: computed(() => result?.value?.data?.currentCategory?.metaDescription),
      currentCategoryName: computed(() => result?.value?.data?.currentCategory?.name),
      currentCategorySEODescription: computed(() =>
        result.value?.data?.currentCategory?.custom?.customFieldsRaw?.find(
          field => field.name === CATEGORY_CUSTOM_FIELDS.CATEGORY_CONTENT_HTML
        )?.value[languageAndCountry.value]
      ),
      currentCategoryBlogPosts: computed(() => {
        const categoryPosts = result.value?.data?.currentCategory?.custom?.customFieldsRaw?.find(
          field => field.name === CATEGORY_CUSTOM_FIELDS.CATEGORY_POSTS
        )?.value[languageAndCountry.value];
        return JSON.parse(categoryPosts || '[]');
      })
    }
  };
};
