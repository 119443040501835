import VueI18n from 'vue-i18n';
import type { IVueI18n } from 'vue-i18n';
import { domainNewPrefix } from '~/constants/domainNewPrefix';
import { adjustHostnameForPreProduction } from '~/helpers/env/preprod';
import { i18nToCurrentDomain } from '~/helpers/locales/i18nCurrentDetails';

export const generateMetaTitleWithCTFormat = (title: string | VueI18n.TranslateResult, i18n: IVueI18n) => {
  const currentDomain = i18nToCurrentDomain(i18n);
  if (!currentDomain) {
    return `${title.toString()}`;
  }
  const domainSplitted = currentDomain.split(domainNewPrefix);
  // domain after removing the new. in front
  const domainAfterRemovingPrefix = domainSplitted[domainSplitted.length - 1];
  const normalisedDomain = adjustHostnameForPreProduction(domainAfterRemovingPrefix);
  return `${title.toString()} | ${normalisedDomain}`;
};
