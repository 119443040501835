export function createOnSaleLink () {
  return '/search?%5BrefinementList%5D%5Btags%5D%5B0%5D=on-sale';
}
const createCategoryLink = (categorySlug: string, tag: string) =>
  `/${categorySlug}?${tag ? `%5BrefinementList%5D%5Btags%5D%5B0%5D=${tag}` : ''}`;
export function createCategoryOnSaleLink (categorySlug: string) {
  return createCategoryLink(categorySlug, 'on-sale');
}
export function createCategoryBestsellersLink (categorySlug: string) {
  return createCategoryLink(categorySlug, 'bestseller');
}
