import {
  SEARCH_RESULTS_EVENT_NAME,
  SEARCH_RESULTS_EVENT_TYPE,
  SEARCH_RESULTS_PRIMARY_CATEGORY
} from '~/constants/googleTagManager';

import {
  SearchResultCategoryType,
  SearchResultsAttributesType
} from '~/types/integrations/tagManager/TagManagerIntegration';

export class SearchResultsTags {
  event = SEARCH_RESULTS_EVENT_NAME;
  category: SearchResultCategoryType
  attributes: SearchResultsAttributesType

  constructor(searchTerm: string = '') {
    this.category = {
      event_type: SEARCH_RESULTS_EVENT_TYPE,
      primary_category: SEARCH_RESULTS_PRIMARY_CATEGORY
    };

    this.attributes = {
      search_term: searchTerm
    };
  }
}
