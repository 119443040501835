import { VSF_LOCALE_COOKIE } from '@vue-storefront/core';
import { getPrefixedSlug } from '~/helpers/routes/prefixes';
import localizationDefinitions from '~/i18n/localizationDefinitions';

export default function ({ route, next, $cookies }) {
  if (process?.server) return;

  let languagePrefix = '';
  const languageAndCountry = $cookies.get(VSF_LOCALE_COOKIE);
  const countryLocalization = localizationDefinitions.getByLanguageAndCountry(languageAndCountry);

  if (!countryLocalization) {
    languagePrefix = countryLocalization.getLanguageFromExistingLanguageAndCountry(languageAndCountry);
  }

  const prefixedSlug = getPrefixedSlug(route.fullPath, languagePrefix);

  if (prefixedSlug !== route.fullPath) {
    next(prefixedSlug);
  }
}
