import { Gtm } from '~/types/@nuxtjs/gtm';
import { ClickHitTags } from '~/plugins/integrations/tagManager/helpers/ClickHitTags';
import type { TriggerClickHitEventParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerClickHitTagsWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClickHitEventParameters) => {
    const { userToken, index, objectIDs, searchTerm, positions } = parameters;
    const clickHitTags = new ClickHitTags({ userToken, index, objectIDs, searchTerm, positions });

    $gtm.push(clickHitTags);
  };
};
