import Currency from './currency';
import languageCodes from '~/i18n/enums/languageCodes';
import { countryCodes } from '~/i18n';

export type CountryCodeValue = ValueOf<typeof countryCodes>;
export type LanguageIdentification =
  { language: ValueOf<typeof languageCodes>, countryCode : CountryCodeValue };
export type ParcelPerformByLanguage = { language?: ValueOf<typeof languageCodes>, parcelPerformId: string };

export const BLOOMREACH_TYPE_NAME = {
  SIMILAR_PRODUCTS: 'similarProductsId',
  LAST_SEEN: 'lastSeenId',
  CART_RECOMMENDATION: 'cartRecommendationId'
} as const;
export const BLOOMREACH_EVENT_NAME = {
  [BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS]: 'Similar Products',
  [BLOOMREACH_TYPE_NAME.LAST_SEEN]: 'Last Seen',
  [BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION]: 'Cart Recommendation'
};
export const RECOMMENDATION_DESCRIPTION = {
  [BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS]: 'view similar products section',
  [BLOOMREACH_TYPE_NAME.LAST_SEEN]: 'view last seen products section',
  [BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION]: 'view cart recommendations section'
} as const;
export type BloomreachTypeName = ValueOf<typeof BLOOMREACH_TYPE_NAME>;
export type BloomreachEventName = ValueOf<typeof BLOOMREACH_EVENT_NAME>;
export type BloomreachTypeId = string;
export type BloomreachKeyByLanguage = {
  key: string,
  language?: ValueOf<typeof languageCodes>,
  [BLOOMREACH_TYPE_NAME.LAST_SEEN]?: BloomreachTypeId,
  [BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS]?: BloomreachTypeId,
  [BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION]?: BloomreachTypeId
};
export type VueI18nNumberFormats = { [key: string]: { currency: Currency } };
export type VueI18nFormattedLocales = Array<{
  code: string,
  label: string,
  file: string,
  domain: string,
  country: string,
  algoliaIndexName: Maybe<string>
}>;
