import type { LineItem } from '@vsf-enterprise/commercetools-types';
import { ProductSummary } from '~/types/product/ProductSummary';

const getStandardPrice = (product: ProductSummary | LineItem, languageAndCountry: string) => {
  const standardPrice = product?.variant?.attributesRaw.filter(
    attr => attr.name === 'standardPrice');
  const standardPriceString = standardPrice?.[0]?.value?.[languageAndCountry];
  if (standardPriceString === '' || standardPriceString === undefined) {
    return null;
  }
  return JSON.parse(standardPriceString)?.centAmount / 100;
};

export default getStandardPrice;
