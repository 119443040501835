import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b2d0b27 = () => interopDefault(import('../pages/adyen-payment-error.vue' /* webpackChunkName: "pages/adyen-payment-error" */))
const _c91e92fc = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "pages/Checkout" */))
const _13c168b5 = () => interopDefault(import('../pages/Checkout/Addresses.vue' /* webpackChunkName: "pages/Checkout/Addresses" */))
const _b154578e = () => interopDefault(import('../pages/Checkout/create-account.vue' /* webpackChunkName: "pages/Checkout/create-account" */))
const _2760967c = () => interopDefault(import('../pages/Checkout/Login.vue' /* webpackChunkName: "pages/Checkout/Login" */))
const _6659efd9 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "pages/Checkout/Payment" */))
const _37f264b6 = () => interopDefault(import('../pages/Checkout/reset-password.vue' /* webpackChunkName: "pages/Checkout/reset-password" */))
const _f12e6a22 = () => interopDefault(import('../pages/Checkout/thank-you.vue' /* webpackChunkName: "pages/Checkout/thank-you" */))
const _73cbb978 = () => interopDefault(import('../pages/create-account.vue' /* webpackChunkName: "pages/create-account" */))
const _bfd2514e = () => interopDefault(import('../pages/log-out.vue' /* webpackChunkName: "pages/log-out" */))
const _113afe5d = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _2ba68028 = () => interopDefault(import('../pages/my-account.vue' /* webpackChunkName: "pages/my-account" */))
const _66e11089 = () => interopDefault(import('../pages/my-account/billing-details.vue' /* webpackChunkName: "pages/my-account/billing-details" */))
const _491ed1ee = () => interopDefault(import('../pages/my-account/loyalty-card.vue' /* webpackChunkName: "pages/my-account/loyalty-card" */))
const _45cc1a53 = () => interopDefault(import('../pages/my-account/my-newsletter.vue' /* webpackChunkName: "pages/my-account/my-newsletter" */))
const _945e5102 = () => interopDefault(import('../pages/my-account/my-profile.vue' /* webpackChunkName: "pages/my-account/my-profile" */))
const _05588e1e = () => interopDefault(import('../pages/my-account/my-reviews.vue' /* webpackChunkName: "pages/my-account/my-reviews" */))
const _382ed324 = () => interopDefault(import('../pages/my-account/order-history.vue' /* webpackChunkName: "pages/my-account/order-history" */))
const _2d70a55a = () => interopDefault(import('../pages/my-account/shipping-details.vue' /* webpackChunkName: "pages/my-account/shipping-details" */))
const _18173c08 = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _5696e6e4 = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _74deb07c = () => interopDefault(import('../pages/password-reset/change-password.vue' /* webpackChunkName: "pages/password-reset/change-password" */))
const _3ef3b7c1 = () => interopDefault(import('../pages/password-reset/check-email.vue' /* webpackChunkName: "pages/password-reset/check-email" */))
const _632acbc4 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___de-at",
    children: []
  }, {
    path: "/de/Checkout",
    component: _c91e92fc,
    name: "Checkout___de-at",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___de-at",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___de-at",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___de-at",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___de-at",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___de-at",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___de-at",
      children: []
    }]
  }, {
    path: "/de/create-account",
    component: _73cbb978,
    name: "create-account___de-at",
    children: []
  }, {
    path: "/de/log-out",
    component: _bfd2514e,
    name: "log-out___de-at",
    children: []
  }, {
    path: "/de/Login",
    component: _113afe5d,
    name: "Login___de-at",
    children: []
  }, {
    path: "/de/my-account",
    component: _2ba68028,
    name: "my-account___de-at",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___de-at",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___de-at",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___de-at",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___de-at",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___de-at",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___de-at",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___de-at",
      children: []
    }]
  }, {
    path: "/de/password-reset",
    component: _18173c08,
    name: "password-reset___de-at",
    children: []
  }, {
    path: "/de/Search",
    component: _5696e6e4,
    name: "Search___de-at",
    children: []
  }, {
    path: "/de/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___de-at",
    children: []
  }, {
    path: "/de/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___de-at",
    children: []
  }, {
    path: "/de/:slug?",
    component: _632acbc4,
    name: "slug___de-at",
    children: []
  }, {
    path: "/fr/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___fr-be",
    children: []
  }, {
    path: "/fr/Checkout",
    component: _c91e92fc,
    name: "Checkout___fr-be",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___fr-be",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___fr-be",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___fr-be",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___fr-be",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___fr-be",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___fr-be",
      children: []
    }]
  }, {
    path: "/fr/create-account",
    component: _73cbb978,
    name: "create-account___fr-be",
    children: []
  }, {
    path: "/fr/log-out",
    component: _bfd2514e,
    name: "log-out___fr-be",
    children: []
  }, {
    path: "/fr/Login",
    component: _113afe5d,
    name: "Login___fr-be",
    children: []
  }, {
    path: "/fr/my-account",
    component: _2ba68028,
    name: "my-account___fr-be",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___fr-be",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___fr-be",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___fr-be",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___fr-be",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___fr-be",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___fr-be",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___fr-be",
      children: []
    }]
  }, {
    path: "/fr/password-reset",
    component: _18173c08,
    name: "password-reset___fr-be",
    children: []
  }, {
    path: "/fr/Search",
    component: _5696e6e4,
    name: "Search___fr-be",
    children: []
  }, {
    path: "/fr/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___fr-be",
    children: []
  }, {
    path: "/fr/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___fr-be",
    children: []
  }, {
    path: "/fr/:slug?",
    component: _632acbc4,
    name: "slug___fr-be",
    children: []
  }, {
    path: "/nl/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___l___nl-be",
    children: []
  }, {
    path: "/nl/Checkout",
    component: _c91e92fc,
    name: "Checkout___l___nl-be",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___l___nl-be",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___l___nl-be",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___l___nl-be",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___l___nl-be",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___l___nl-be",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___l___nl-be",
      children: []
    }]
  }, {
    path: "/nl/create-account",
    component: _73cbb978,
    name: "create-account___l___nl-be",
    children: []
  }, {
    path: "/nl/log-out",
    component: _bfd2514e,
    name: "log-out___l___nl-be",
    children: []
  }, {
    path: "/nl/Login",
    component: _113afe5d,
    name: "Login___l___nl-be",
    children: []
  }, {
    path: "/nl/my-account",
    component: _2ba68028,
    name: "my-account___l___nl-be",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___l___nl-be",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___l___nl-be",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___l___nl-be",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___l___nl-be",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___l___nl-be",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___l___nl-be",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___l___nl-be",
      children: []
    }]
  }, {
    path: "/nl/password-reset",
    component: _18173c08,
    name: "password-reset___l___nl-be",
    children: []
  }, {
    path: "/nl/Search",
    component: _5696e6e4,
    name: "Search___l___nl-be",
    children: []
  }, {
    path: "/nl/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___l___nl-be",
    children: []
  }, {
    path: "/nl/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___l___nl-be",
    children: []
  }, {
    path: "/nl/:slug?",
    component: _632acbc4,
    name: "slug___l___nl-be",
    children: []
  }, {
    path: "/bg/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___bg-bg",
    children: []
  }, {
    path: "/bg/Checkout",
    component: _c91e92fc,
    name: "Checkout___bg-bg",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___bg-bg",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___bg-bg",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___bg-bg",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___bg-bg",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___bg-bg",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___bg-bg",
      children: []
    }]
  }, {
    path: "/bg/create-account",
    component: _73cbb978,
    name: "create-account___bg-bg",
    children: []
  }, {
    path: "/bg/log-out",
    component: _bfd2514e,
    name: "log-out___bg-bg",
    children: []
  }, {
    path: "/bg/Login",
    component: _113afe5d,
    name: "Login___bg-bg",
    children: []
  }, {
    path: "/bg/my-account",
    component: _2ba68028,
    name: "my-account___bg-bg",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___bg-bg",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___bg-bg",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___bg-bg",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___bg-bg",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___bg-bg",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___bg-bg",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___bg-bg",
      children: []
    }]
  }, {
    path: "/bg/password-reset",
    component: _18173c08,
    name: "password-reset___bg-bg",
    children: []
  }, {
    path: "/bg/Search",
    component: _5696e6e4,
    name: "Search___bg-bg",
    children: []
  }, {
    path: "/bg/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___bg-bg",
    children: []
  }, {
    path: "/bg/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___bg-bg",
    children: []
  }, {
    path: "/bg/:slug?",
    component: _632acbc4,
    name: "slug___bg-bg",
    children: []
  }, {
    path: "/de/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___de-ch",
    children: []
  }, {
    path: "/de/Checkout",
    component: _c91e92fc,
    name: "Checkout___de-ch",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___de-ch",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___de-ch",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___de-ch",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___de-ch",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___de-ch",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___de-ch",
      children: []
    }]
  }, {
    path: "/de/create-account",
    component: _73cbb978,
    name: "create-account___de-ch",
    children: []
  }, {
    path: "/de/log-out",
    component: _bfd2514e,
    name: "log-out___de-ch",
    children: []
  }, {
    path: "/de/Login",
    component: _113afe5d,
    name: "Login___de-ch",
    children: []
  }, {
    path: "/de/my-account",
    component: _2ba68028,
    name: "my-account___de-ch",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___de-ch",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___de-ch",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___de-ch",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___de-ch",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___de-ch",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___de-ch",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___de-ch",
      children: []
    }]
  }, {
    path: "/de/password-reset",
    component: _18173c08,
    name: "password-reset___de-ch",
    children: []
  }, {
    path: "/de/Search",
    component: _5696e6e4,
    name: "Search___de-ch",
    children: []
  }, {
    path: "/de/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___de-ch",
    children: []
  }, {
    path: "/de/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___de-ch",
    children: []
  }, {
    path: "/de/:slug?",
    component: _632acbc4,
    name: "slug___de-ch",
    children: []
  }, {
    path: "/fr/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___l___fr-ch",
    children: []
  }, {
    path: "/fr/Checkout",
    component: _c91e92fc,
    name: "Checkout___l___fr-ch",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___l___fr-ch",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___l___fr-ch",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___l___fr-ch",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___l___fr-ch",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___l___fr-ch",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___l___fr-ch",
      children: []
    }]
  }, {
    path: "/fr/create-account",
    component: _73cbb978,
    name: "create-account___l___fr-ch",
    children: []
  }, {
    path: "/fr/log-out",
    component: _bfd2514e,
    name: "log-out___l___fr-ch",
    children: []
  }, {
    path: "/fr/Login",
    component: _113afe5d,
    name: "Login___l___fr-ch",
    children: []
  }, {
    path: "/fr/my-account",
    component: _2ba68028,
    name: "my-account___l___fr-ch",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___l___fr-ch",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___l___fr-ch",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___l___fr-ch",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___l___fr-ch",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___l___fr-ch",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___l___fr-ch",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___l___fr-ch",
      children: []
    }]
  }, {
    path: "/fr/password-reset",
    component: _18173c08,
    name: "password-reset___l___fr-ch",
    children: []
  }, {
    path: "/fr/Search",
    component: _5696e6e4,
    name: "Search___l___fr-ch",
    children: []
  }, {
    path: "/fr/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___l___fr-ch",
    children: []
  }, {
    path: "/fr/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___l___fr-ch",
    children: []
  }, {
    path: "/fr/:slug?",
    component: _632acbc4,
    name: "slug___l___fr-ch",
    children: []
  }, {
    path: "/it/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___l___it-ch",
    children: []
  }, {
    path: "/it/Checkout",
    component: _c91e92fc,
    name: "Checkout___l___it-ch",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___l___it-ch",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___l___it-ch",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___l___it-ch",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___l___it-ch",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___l___it-ch",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___l___it-ch",
      children: []
    }]
  }, {
    path: "/it/create-account",
    component: _73cbb978,
    name: "create-account___l___it-ch",
    children: []
  }, {
    path: "/it/log-out",
    component: _bfd2514e,
    name: "log-out___l___it-ch",
    children: []
  }, {
    path: "/it/Login",
    component: _113afe5d,
    name: "Login___l___it-ch",
    children: []
  }, {
    path: "/it/my-account",
    component: _2ba68028,
    name: "my-account___l___it-ch",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___l___it-ch",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___l___it-ch",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___l___it-ch",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___l___it-ch",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___l___it-ch",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___l___it-ch",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___l___it-ch",
      children: []
    }]
  }, {
    path: "/it/password-reset",
    component: _18173c08,
    name: "password-reset___l___it-ch",
    children: []
  }, {
    path: "/it/Search",
    component: _5696e6e4,
    name: "Search___l___it-ch",
    children: []
  }, {
    path: "/it/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___l___it-ch",
    children: []
  }, {
    path: "/it/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___l___it-ch",
    children: []
  }, {
    path: "/it/:slug?",
    component: _632acbc4,
    name: "slug___l___it-ch",
    children: []
  }, {
    path: "/en/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___en-gb",
    children: []
  }, {
    path: "/en/Checkout",
    component: _c91e92fc,
    name: "Checkout___en-gb",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___en-gb",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___en-gb",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___en-gb",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___en-gb",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___en-gb",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___en-gb",
      children: []
    }]
  }, {
    path: "/en/create-account",
    component: _73cbb978,
    name: "create-account___en-gb",
    children: []
  }, {
    path: "/en/log-out",
    component: _bfd2514e,
    name: "log-out___en-gb",
    children: []
  }, {
    path: "/en/Login",
    component: _113afe5d,
    name: "Login___en-gb",
    children: []
  }, {
    path: "/en/my-account",
    component: _2ba68028,
    name: "my-account___en-gb",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___en-gb",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___en-gb",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___en-gb",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___en-gb",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___en-gb",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___en-gb",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___en-gb",
      children: []
    }]
  }, {
    path: "/en/password-reset",
    component: _18173c08,
    name: "password-reset___en-gb",
    children: []
  }, {
    path: "/en/Search",
    component: _5696e6e4,
    name: "Search___en-gb",
    children: []
  }, {
    path: "/en/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___en-gb",
    children: []
  }, {
    path: "/en/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___en-gb",
    children: []
  }, {
    path: "/en/:slug?",
    component: _632acbc4,
    name: "slug___en-gb",
    children: []
  }, {
    path: "/cs/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___cs-cz",
    children: []
  }, {
    path: "/cs/Checkout",
    component: _c91e92fc,
    name: "Checkout___cs-cz",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___cs-cz",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___cs-cz",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___cs-cz",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___cs-cz",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___cs-cz",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___cs-cz",
      children: []
    }]
  }, {
    path: "/cs/create-account",
    component: _73cbb978,
    name: "create-account___cs-cz",
    children: []
  }, {
    path: "/cs/log-out",
    component: _bfd2514e,
    name: "log-out___cs-cz",
    children: []
  }, {
    path: "/cs/Login",
    component: _113afe5d,
    name: "Login___cs-cz",
    children: []
  }, {
    path: "/cs/my-account",
    component: _2ba68028,
    name: "my-account___cs-cz",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___cs-cz",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___cs-cz",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___cs-cz",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___cs-cz",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___cs-cz",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___cs-cz",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___cs-cz",
      children: []
    }]
  }, {
    path: "/cs/password-reset",
    component: _18173c08,
    name: "password-reset___cs-cz",
    children: []
  }, {
    path: "/cs/Search",
    component: _5696e6e4,
    name: "Search___cs-cz",
    children: []
  }, {
    path: "/cs/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___cs-cz",
    children: []
  }, {
    path: "/cs/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___cs-cz",
    children: []
  }, {
    path: "/cs/:slug?",
    component: _632acbc4,
    name: "slug___cs-cz",
    children: []
  }, {
    path: "/de/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___de-de",
    children: []
  }, {
    path: "/de/Checkout",
    component: _c91e92fc,
    name: "Checkout___de-de",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___de-de",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___de-de",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___de-de",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___de-de",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___de-de",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___de-de",
      children: []
    }]
  }, {
    path: "/de/create-account",
    component: _73cbb978,
    name: "create-account___de-de",
    children: []
  }, {
    path: "/de/log-out",
    component: _bfd2514e,
    name: "log-out___de-de",
    children: []
  }, {
    path: "/de/Login",
    component: _113afe5d,
    name: "Login___de-de",
    children: []
  }, {
    path: "/de/my-account",
    component: _2ba68028,
    name: "my-account___de-de",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___de-de",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___de-de",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___de-de",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___de-de",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___de-de",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___de-de",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___de-de",
      children: []
    }]
  }, {
    path: "/de/password-reset",
    component: _18173c08,
    name: "password-reset___de-de",
    children: []
  }, {
    path: "/de/Search",
    component: _5696e6e4,
    name: "Search___de-de",
    children: []
  }, {
    path: "/de/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___de-de",
    children: []
  }, {
    path: "/de/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___de-de",
    children: []
  }, {
    path: "/de/:slug?",
    component: _632acbc4,
    name: "slug___de-de",
    children: []
  }, {
    path: "/da/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___da-dk",
    children: []
  }, {
    path: "/da/Checkout",
    component: _c91e92fc,
    name: "Checkout___da-dk",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___da-dk",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___da-dk",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___da-dk",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___da-dk",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___da-dk",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___da-dk",
      children: []
    }]
  }, {
    path: "/da/create-account",
    component: _73cbb978,
    name: "create-account___da-dk",
    children: []
  }, {
    path: "/da/log-out",
    component: _bfd2514e,
    name: "log-out___da-dk",
    children: []
  }, {
    path: "/da/Login",
    component: _113afe5d,
    name: "Login___da-dk",
    children: []
  }, {
    path: "/da/my-account",
    component: _2ba68028,
    name: "my-account___da-dk",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___da-dk",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___da-dk",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___da-dk",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___da-dk",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___da-dk",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___da-dk",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___da-dk",
      children: []
    }]
  }, {
    path: "/da/password-reset",
    component: _18173c08,
    name: "password-reset___da-dk",
    children: []
  }, {
    path: "/da/Search",
    component: _5696e6e4,
    name: "Search___da-dk",
    children: []
  }, {
    path: "/da/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___da-dk",
    children: []
  }, {
    path: "/da/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___da-dk",
    children: []
  }, {
    path: "/da/:slug?",
    component: _632acbc4,
    name: "slug___da-dk",
    children: []
  }, {
    path: "/es/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___es-es",
    children: []
  }, {
    path: "/es/Checkout",
    component: _c91e92fc,
    name: "Checkout___es-es",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___es-es",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___es-es",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___es-es",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___es-es",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___es-es",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___es-es",
      children: []
    }]
  }, {
    path: "/es/create-account",
    component: _73cbb978,
    name: "create-account___es-es",
    children: []
  }, {
    path: "/es/log-out",
    component: _bfd2514e,
    name: "log-out___es-es",
    children: []
  }, {
    path: "/es/Login",
    component: _113afe5d,
    name: "Login___es-es",
    children: []
  }, {
    path: "/es/my-account",
    component: _2ba68028,
    name: "my-account___es-es",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___es-es",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___es-es",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___es-es",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___es-es",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___es-es",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___es-es",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___es-es",
      children: []
    }]
  }, {
    path: "/es/password-reset",
    component: _18173c08,
    name: "password-reset___es-es",
    children: []
  }, {
    path: "/es/Search",
    component: _5696e6e4,
    name: "Search___es-es",
    children: []
  }, {
    path: "/es/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___es-es",
    children: []
  }, {
    path: "/es/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___es-es",
    children: []
  }, {
    path: "/es/:slug?",
    component: _632acbc4,
    name: "slug___es-es",
    children: []
  }, {
    path: "/fi/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___fi-fi",
    children: []
  }, {
    path: "/fi/Checkout",
    component: _c91e92fc,
    name: "Checkout___fi-fi",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___fi-fi",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___fi-fi",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___fi-fi",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___fi-fi",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___fi-fi",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___fi-fi",
      children: []
    }]
  }, {
    path: "/fi/create-account",
    component: _73cbb978,
    name: "create-account___fi-fi",
    children: []
  }, {
    path: "/fi/log-out",
    component: _bfd2514e,
    name: "log-out___fi-fi",
    children: []
  }, {
    path: "/fi/Login",
    component: _113afe5d,
    name: "Login___fi-fi",
    children: []
  }, {
    path: "/fi/my-account",
    component: _2ba68028,
    name: "my-account___fi-fi",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___fi-fi",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___fi-fi",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___fi-fi",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___fi-fi",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___fi-fi",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___fi-fi",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___fi-fi",
      children: []
    }]
  }, {
    path: "/fi/password-reset",
    component: _18173c08,
    name: "password-reset___fi-fi",
    children: []
  }, {
    path: "/fi/Search",
    component: _5696e6e4,
    name: "Search___fi-fi",
    children: []
  }, {
    path: "/fi/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___fi-fi",
    children: []
  }, {
    path: "/fi/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___fi-fi",
    children: []
  }, {
    path: "/fi/:slug?",
    component: _632acbc4,
    name: "slug___fi-fi",
    children: []
  }, {
    path: "/fr/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___fr-fr",
    children: []
  }, {
    path: "/fr/Checkout",
    component: _c91e92fc,
    name: "Checkout___fr-fr",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___fr-fr",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___fr-fr",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___fr-fr",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___fr-fr",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___fr-fr",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___fr-fr",
      children: []
    }]
  }, {
    path: "/fr/create-account",
    component: _73cbb978,
    name: "create-account___fr-fr",
    children: []
  }, {
    path: "/fr/log-out",
    component: _bfd2514e,
    name: "log-out___fr-fr",
    children: []
  }, {
    path: "/fr/Login",
    component: _113afe5d,
    name: "Login___fr-fr",
    children: []
  }, {
    path: "/fr/my-account",
    component: _2ba68028,
    name: "my-account___fr-fr",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___fr-fr",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___fr-fr",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___fr-fr",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___fr-fr",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___fr-fr",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___fr-fr",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___fr-fr",
      children: []
    }]
  }, {
    path: "/fr/password-reset",
    component: _18173c08,
    name: "password-reset___fr-fr",
    children: []
  }, {
    path: "/fr/Search",
    component: _5696e6e4,
    name: "Search___fr-fr",
    children: []
  }, {
    path: "/fr/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___fr-fr",
    children: []
  }, {
    path: "/fr/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___fr-fr",
    children: []
  }, {
    path: "/fr/:slug?",
    component: _632acbc4,
    name: "slug___fr-fr",
    children: []
  }, {
    path: "/el/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___el-gr",
    children: []
  }, {
    path: "/el/Checkout",
    component: _c91e92fc,
    name: "Checkout___el-gr",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___el-gr",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___el-gr",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___el-gr",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___el-gr",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___el-gr",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___el-gr",
      children: []
    }]
  }, {
    path: "/el/create-account",
    component: _73cbb978,
    name: "create-account___el-gr",
    children: []
  }, {
    path: "/el/log-out",
    component: _bfd2514e,
    name: "log-out___el-gr",
    children: []
  }, {
    path: "/el/Login",
    component: _113afe5d,
    name: "Login___el-gr",
    children: []
  }, {
    path: "/el/my-account",
    component: _2ba68028,
    name: "my-account___el-gr",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___el-gr",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___el-gr",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___el-gr",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___el-gr",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___el-gr",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___el-gr",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___el-gr",
      children: []
    }]
  }, {
    path: "/el/password-reset",
    component: _18173c08,
    name: "password-reset___el-gr",
    children: []
  }, {
    path: "/el/Search",
    component: _5696e6e4,
    name: "Search___el-gr",
    children: []
  }, {
    path: "/el/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___el-gr",
    children: []
  }, {
    path: "/el/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___el-gr",
    children: []
  }, {
    path: "/el/:slug?",
    component: _632acbc4,
    name: "slug___el-gr",
    children: []
  }, {
    path: "/hr/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___hr-hr",
    children: []
  }, {
    path: "/hr/Checkout",
    component: _c91e92fc,
    name: "Checkout___hr-hr",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___hr-hr",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___hr-hr",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___hr-hr",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___hr-hr",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___hr-hr",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___hr-hr",
      children: []
    }]
  }, {
    path: "/hr/create-account",
    component: _73cbb978,
    name: "create-account___hr-hr",
    children: []
  }, {
    path: "/hr/log-out",
    component: _bfd2514e,
    name: "log-out___hr-hr",
    children: []
  }, {
    path: "/hr/Login",
    component: _113afe5d,
    name: "Login___hr-hr",
    children: []
  }, {
    path: "/hr/my-account",
    component: _2ba68028,
    name: "my-account___hr-hr",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___hr-hr",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___hr-hr",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___hr-hr",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___hr-hr",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___hr-hr",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___hr-hr",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___hr-hr",
      children: []
    }]
  }, {
    path: "/hr/password-reset",
    component: _18173c08,
    name: "password-reset___hr-hr",
    children: []
  }, {
    path: "/hr/Search",
    component: _5696e6e4,
    name: "Search___hr-hr",
    children: []
  }, {
    path: "/hr/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___hr-hr",
    children: []
  }, {
    path: "/hr/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___hr-hr",
    children: []
  }, {
    path: "/hr/:slug?",
    component: _632acbc4,
    name: "slug___hr-hr",
    children: []
  }, {
    path: "/hu/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___hu-hu",
    children: []
  }, {
    path: "/hu/Checkout",
    component: _c91e92fc,
    name: "Checkout___hu-hu",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___hu-hu",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___hu-hu",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___hu-hu",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___hu-hu",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___hu-hu",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___hu-hu",
      children: []
    }]
  }, {
    path: "/hu/create-account",
    component: _73cbb978,
    name: "create-account___hu-hu",
    children: []
  }, {
    path: "/hu/log-out",
    component: _bfd2514e,
    name: "log-out___hu-hu",
    children: []
  }, {
    path: "/hu/Login",
    component: _113afe5d,
    name: "Login___hu-hu",
    children: []
  }, {
    path: "/hu/my-account",
    component: _2ba68028,
    name: "my-account___hu-hu",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___hu-hu",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___hu-hu",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___hu-hu",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___hu-hu",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___hu-hu",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___hu-hu",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___hu-hu",
      children: []
    }]
  }, {
    path: "/hu/password-reset",
    component: _18173c08,
    name: "password-reset___hu-hu",
    children: []
  }, {
    path: "/hu/Search",
    component: _5696e6e4,
    name: "Search___hu-hu",
    children: []
  }, {
    path: "/hu/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___hu-hu",
    children: []
  }, {
    path: "/hu/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___hu-hu",
    children: []
  }, {
    path: "/hu/:slug?",
    component: _632acbc4,
    name: "slug___hu-hu",
    children: []
  }, {
    path: "/en/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___en-ie",
    children: []
  }, {
    path: "/en/Checkout",
    component: _c91e92fc,
    name: "Checkout___en-ie",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___en-ie",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___en-ie",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___en-ie",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___en-ie",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___en-ie",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___en-ie",
      children: []
    }]
  }, {
    path: "/en/create-account",
    component: _73cbb978,
    name: "create-account___en-ie",
    children: []
  }, {
    path: "/en/log-out",
    component: _bfd2514e,
    name: "log-out___en-ie",
    children: []
  }, {
    path: "/en/Login",
    component: _113afe5d,
    name: "Login___en-ie",
    children: []
  }, {
    path: "/en/my-account",
    component: _2ba68028,
    name: "my-account___en-ie",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___en-ie",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___en-ie",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___en-ie",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___en-ie",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___en-ie",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___en-ie",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___en-ie",
      children: []
    }]
  }, {
    path: "/en/password-reset",
    component: _18173c08,
    name: "password-reset___en-ie",
    children: []
  }, {
    path: "/en/Search",
    component: _5696e6e4,
    name: "Search___en-ie",
    children: []
  }, {
    path: "/en/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___en-ie",
    children: []
  }, {
    path: "/en/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___en-ie",
    children: []
  }, {
    path: "/en/:slug?",
    component: _632acbc4,
    name: "slug___en-ie",
    children: []
  }, {
    path: "/it/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___it-it",
    children: []
  }, {
    path: "/it/Checkout",
    component: _c91e92fc,
    name: "Checkout___it-it",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___it-it",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___it-it",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___it-it",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___it-it",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___it-it",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___it-it",
      children: []
    }]
  }, {
    path: "/it/create-account",
    component: _73cbb978,
    name: "create-account___it-it",
    children: []
  }, {
    path: "/it/log-out",
    component: _bfd2514e,
    name: "log-out___it-it",
    children: []
  }, {
    path: "/it/Login",
    component: _113afe5d,
    name: "Login___it-it",
    children: []
  }, {
    path: "/it/my-account",
    component: _2ba68028,
    name: "my-account___it-it",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___it-it",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___it-it",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___it-it",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___it-it",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___it-it",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___it-it",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___it-it",
      children: []
    }]
  }, {
    path: "/it/password-reset",
    component: _18173c08,
    name: "password-reset___it-it",
    children: []
  }, {
    path: "/it/Search",
    component: _5696e6e4,
    name: "Search___it-it",
    children: []
  }, {
    path: "/it/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___it-it",
    children: []
  }, {
    path: "/it/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___it-it",
    children: []
  }, {
    path: "/it/:slug?",
    component: _632acbc4,
    name: "slug___it-it",
    children: []
  }, {
    path: "/lt/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___lt-lt",
    children: []
  }, {
    path: "/lt/Checkout",
    component: _c91e92fc,
    name: "Checkout___lt-lt",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___lt-lt",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___lt-lt",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___lt-lt",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___lt-lt",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___lt-lt",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___lt-lt",
      children: []
    }]
  }, {
    path: "/lt/create-account",
    component: _73cbb978,
    name: "create-account___lt-lt",
    children: []
  }, {
    path: "/lt/log-out",
    component: _bfd2514e,
    name: "log-out___lt-lt",
    children: []
  }, {
    path: "/lt/Login",
    component: _113afe5d,
    name: "Login___lt-lt",
    children: []
  }, {
    path: "/lt/my-account",
    component: _2ba68028,
    name: "my-account___lt-lt",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___lt-lt",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___lt-lt",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___lt-lt",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___lt-lt",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___lt-lt",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___lt-lt",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___lt-lt",
      children: []
    }]
  }, {
    path: "/lt/password-reset",
    component: _18173c08,
    name: "password-reset___lt-lt",
    children: []
  }, {
    path: "/lt/Search",
    component: _5696e6e4,
    name: "Search___lt-lt",
    children: []
  }, {
    path: "/lt/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___lt-lt",
    children: []
  }, {
    path: "/lt/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___lt-lt",
    children: []
  }, {
    path: "/lt/:slug?",
    component: _632acbc4,
    name: "slug___lt-lt",
    children: []
  }, {
    path: "/nl/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___nl-nl",
    children: []
  }, {
    path: "/nl/Checkout",
    component: _c91e92fc,
    name: "Checkout___nl-nl",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___nl-nl",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___nl-nl",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___nl-nl",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___nl-nl",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___nl-nl",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___nl-nl",
      children: []
    }]
  }, {
    path: "/nl/create-account",
    component: _73cbb978,
    name: "create-account___nl-nl",
    children: []
  }, {
    path: "/nl/log-out",
    component: _bfd2514e,
    name: "log-out___nl-nl",
    children: []
  }, {
    path: "/nl/Login",
    component: _113afe5d,
    name: "Login___nl-nl",
    children: []
  }, {
    path: "/nl/my-account",
    component: _2ba68028,
    name: "my-account___nl-nl",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___nl-nl",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___nl-nl",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___nl-nl",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___nl-nl",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___nl-nl",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___nl-nl",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___nl-nl",
      children: []
    }]
  }, {
    path: "/nl/password-reset",
    component: _18173c08,
    name: "password-reset___nl-nl",
    children: []
  }, {
    path: "/nl/Search",
    component: _5696e6e4,
    name: "Search___nl-nl",
    children: []
  }, {
    path: "/nl/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___nl-nl",
    children: []
  }, {
    path: "/nl/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___nl-nl",
    children: []
  }, {
    path: "/nl/:slug?",
    component: _632acbc4,
    name: "slug___nl-nl",
    children: []
  }, {
    path: "/nb/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___nb-no",
    children: []
  }, {
    path: "/nb/Checkout",
    component: _c91e92fc,
    name: "Checkout___nb-no",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___nb-no",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___nb-no",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___nb-no",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___nb-no",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___nb-no",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___nb-no",
      children: []
    }]
  }, {
    path: "/nb/create-account",
    component: _73cbb978,
    name: "create-account___nb-no",
    children: []
  }, {
    path: "/nb/log-out",
    component: _bfd2514e,
    name: "log-out___nb-no",
    children: []
  }, {
    path: "/nb/Login",
    component: _113afe5d,
    name: "Login___nb-no",
    children: []
  }, {
    path: "/nb/my-account",
    component: _2ba68028,
    name: "my-account___nb-no",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___nb-no",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___nb-no",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___nb-no",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___nb-no",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___nb-no",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___nb-no",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___nb-no",
      children: []
    }]
  }, {
    path: "/nb/password-reset",
    component: _18173c08,
    name: "password-reset___nb-no",
    children: []
  }, {
    path: "/nb/Search",
    component: _5696e6e4,
    name: "Search___nb-no",
    children: []
  }, {
    path: "/nb/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___nb-no",
    children: []
  }, {
    path: "/nb/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___nb-no",
    children: []
  }, {
    path: "/nb/:slug?",
    component: _632acbc4,
    name: "slug___nb-no",
    children: []
  }, {
    path: "/pl/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___pl-pl",
    children: []
  }, {
    path: "/pl/Checkout",
    component: _c91e92fc,
    name: "Checkout___pl-pl",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___pl-pl",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___pl-pl",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___pl-pl",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___pl-pl",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___pl-pl",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___pl-pl",
      children: []
    }]
  }, {
    path: "/pl/create-account",
    component: _73cbb978,
    name: "create-account___pl-pl",
    children: []
  }, {
    path: "/pl/log-out",
    component: _bfd2514e,
    name: "log-out___pl-pl",
    children: []
  }, {
    path: "/pl/Login",
    component: _113afe5d,
    name: "Login___pl-pl",
    children: []
  }, {
    path: "/pl/my-account",
    component: _2ba68028,
    name: "my-account___pl-pl",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___pl-pl",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___pl-pl",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___pl-pl",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___pl-pl",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___pl-pl",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___pl-pl",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___pl-pl",
      children: []
    }]
  }, {
    path: "/pl/password-reset",
    component: _18173c08,
    name: "password-reset___pl-pl",
    children: []
  }, {
    path: "/pl/Search",
    component: _5696e6e4,
    name: "Search___pl-pl",
    children: []
  }, {
    path: "/pl/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___pl-pl",
    children: []
  }, {
    path: "/pl/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___pl-pl",
    children: []
  }, {
    path: "/pl/:slug?",
    component: _632acbc4,
    name: "slug___pl-pl",
    children: []
  }, {
    path: "/pt/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___pt-pt",
    children: []
  }, {
    path: "/pt/Checkout",
    component: _c91e92fc,
    name: "Checkout___pt-pt",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___pt-pt",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___pt-pt",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___pt-pt",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___pt-pt",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___pt-pt",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___pt-pt",
      children: []
    }]
  }, {
    path: "/pt/create-account",
    component: _73cbb978,
    name: "create-account___pt-pt",
    children: []
  }, {
    path: "/pt/log-out",
    component: _bfd2514e,
    name: "log-out___pt-pt",
    children: []
  }, {
    path: "/pt/Login",
    component: _113afe5d,
    name: "Login___pt-pt",
    children: []
  }, {
    path: "/pt/my-account",
    component: _2ba68028,
    name: "my-account___pt-pt",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___pt-pt",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___pt-pt",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___pt-pt",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___pt-pt",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___pt-pt",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___pt-pt",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___pt-pt",
      children: []
    }]
  }, {
    path: "/pt/password-reset",
    component: _18173c08,
    name: "password-reset___pt-pt",
    children: []
  }, {
    path: "/pt/Search",
    component: _5696e6e4,
    name: "Search___pt-pt",
    children: []
  }, {
    path: "/pt/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___pt-pt",
    children: []
  }, {
    path: "/pt/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___pt-pt",
    children: []
  }, {
    path: "/pt/:slug?",
    component: _632acbc4,
    name: "slug___pt-pt",
    children: []
  }, {
    path: "/ro/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___ro-ro",
    children: []
  }, {
    path: "/ro/Checkout",
    component: _c91e92fc,
    name: "Checkout___ro-ro",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___ro-ro",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___ro-ro",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___ro-ro",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___ro-ro",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___ro-ro",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___ro-ro",
      children: []
    }]
  }, {
    path: "/ro/create-account",
    component: _73cbb978,
    name: "create-account___ro-ro",
    children: []
  }, {
    path: "/ro/log-out",
    component: _bfd2514e,
    name: "log-out___ro-ro",
    children: []
  }, {
    path: "/ro/Login",
    component: _113afe5d,
    name: "Login___ro-ro",
    children: []
  }, {
    path: "/ro/my-account",
    component: _2ba68028,
    name: "my-account___ro-ro",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___ro-ro",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___ro-ro",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___ro-ro",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___ro-ro",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___ro-ro",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___ro-ro",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___ro-ro",
      children: []
    }]
  }, {
    path: "/ro/password-reset",
    component: _18173c08,
    name: "password-reset___ro-ro",
    children: []
  }, {
    path: "/ro/Search",
    component: _5696e6e4,
    name: "Search___ro-ro",
    children: []
  }, {
    path: "/ro/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___ro-ro",
    children: []
  }, {
    path: "/ro/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___ro-ro",
    children: []
  }, {
    path: "/ro/:slug?",
    component: _632acbc4,
    name: "slug___ro-ro",
    children: []
  }, {
    path: "/sv/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___sv-se",
    children: []
  }, {
    path: "/sv/Checkout",
    component: _c91e92fc,
    name: "Checkout___sv-se",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___sv-se",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___sv-se",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___sv-se",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___sv-se",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___sv-se",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___sv-se",
      children: []
    }]
  }, {
    path: "/sv/create-account",
    component: _73cbb978,
    name: "create-account___sv-se",
    children: []
  }, {
    path: "/sv/log-out",
    component: _bfd2514e,
    name: "log-out___sv-se",
    children: []
  }, {
    path: "/sv/Login",
    component: _113afe5d,
    name: "Login___sv-se",
    children: []
  }, {
    path: "/sv/my-account",
    component: _2ba68028,
    name: "my-account___sv-se",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___sv-se",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___sv-se",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___sv-se",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___sv-se",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___sv-se",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___sv-se",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___sv-se",
      children: []
    }]
  }, {
    path: "/sv/password-reset",
    component: _18173c08,
    name: "password-reset___sv-se",
    children: []
  }, {
    path: "/sv/Search",
    component: _5696e6e4,
    name: "Search___sv-se",
    children: []
  }, {
    path: "/sv/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___sv-se",
    children: []
  }, {
    path: "/sv/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___sv-se",
    children: []
  }, {
    path: "/sv/:slug?",
    component: _632acbc4,
    name: "slug___sv-se",
    children: []
  }, {
    path: "/sk/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___sk-sk",
    children: []
  }, {
    path: "/sk/Checkout",
    component: _c91e92fc,
    name: "Checkout___sk-sk",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___sk-sk",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___sk-sk",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___sk-sk",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___sk-sk",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___sk-sk",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___sk-sk",
      children: []
    }]
  }, {
    path: "/sk/create-account",
    component: _73cbb978,
    name: "create-account___sk-sk",
    children: []
  }, {
    path: "/sk/log-out",
    component: _bfd2514e,
    name: "log-out___sk-sk",
    children: []
  }, {
    path: "/sk/Login",
    component: _113afe5d,
    name: "Login___sk-sk",
    children: []
  }, {
    path: "/sk/my-account",
    component: _2ba68028,
    name: "my-account___sk-sk",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___sk-sk",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___sk-sk",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___sk-sk",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___sk-sk",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___sk-sk",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___sk-sk",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___sk-sk",
      children: []
    }]
  }, {
    path: "/sk/password-reset",
    component: _18173c08,
    name: "password-reset___sk-sk",
    children: []
  }, {
    path: "/sk/Search",
    component: _5696e6e4,
    name: "Search___sk-sk",
    children: []
  }, {
    path: "/sk/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___sk-sk",
    children: []
  }, {
    path: "/sk/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___sk-sk",
    children: []
  }, {
    path: "/sk/:slug?",
    component: _632acbc4,
    name: "slug___sk-sk",
    children: []
  }, {
    path: "/sl/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error___sl-si",
    children: []
  }, {
    path: "/sl/Checkout",
    component: _c91e92fc,
    name: "Checkout___sl-si",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses___sl-si",
      children: []
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account___sl-si",
      children: []
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login___sl-si",
      children: []
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment___sl-si",
      children: []
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password___sl-si",
      children: []
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you___sl-si",
      children: []
    }]
  }, {
    path: "/sl/create-account",
    component: _73cbb978,
    name: "create-account___sl-si",
    children: []
  }, {
    path: "/sl/log-out",
    component: _bfd2514e,
    name: "log-out___sl-si",
    children: []
  }, {
    path: "/sl/Login",
    component: _113afe5d,
    name: "Login___sl-si",
    children: []
  }, {
    path: "/sl/my-account",
    component: _2ba68028,
    name: "my-account___sl-si",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details___sl-si",
      children: []
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card___sl-si",
      children: []
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter___sl-si",
      children: []
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile___sl-si",
      children: []
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews___sl-si",
      children: []
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history___sl-si",
      children: []
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details___sl-si",
      children: []
    }]
  }, {
    path: "/sl/password-reset",
    component: _18173c08,
    name: "password-reset___sl-si",
    children: []
  }, {
    path: "/sl/Search",
    component: _5696e6e4,
    name: "Search___sl-si",
    children: []
  }, {
    path: "/sl/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password___sl-si",
    children: []
  }, {
    path: "/sl/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email___sl-si",
    children: []
  }, {
    path: "/sl/:slug?",
    component: _632acbc4,
    name: "slug___sl-si",
    children: []
  }, {
    path: "/adyen-payment-error",
    component: _1b2d0b27,
    name: "adyen-payment-error"
  }, {
    path: "/Checkout",
    component: _c91e92fc,
    name: "Checkout",
    children: [{
      path: "Addresses",
      component: _13c168b5,
      name: "Checkout-Addresses"
    }, {
      path: "create-account",
      component: _b154578e,
      name: "Checkout-create-account"
    }, {
      path: "Login",
      component: _2760967c,
      name: "Checkout-Login"
    }, {
      path: "Payment",
      component: _6659efd9,
      name: "Checkout-Payment"
    }, {
      path: "reset-password",
      component: _37f264b6,
      name: "Checkout-reset-password"
    }, {
      path: "thank-you",
      component: _f12e6a22,
      name: "Checkout-thank-you"
    }]
  }, {
    path: "/create-account",
    component: _73cbb978,
    name: "create-account"
  }, {
    path: "/log-out",
    component: _bfd2514e,
    name: "log-out"
  }, {
    path: "/Login",
    component: _113afe5d,
    name: "Login"
  }, {
    path: "/my-account",
    component: _2ba68028,
    name: "my-account",
    children: [{
      path: "billing-details",
      component: _66e11089,
      name: "my-account-billing-details"
    }, {
      path: "loyalty-card",
      component: _491ed1ee,
      name: "my-account-loyalty-card"
    }, {
      path: "my-newsletter",
      component: _45cc1a53,
      name: "my-account-my-newsletter"
    }, {
      path: "my-profile",
      component: _945e5102,
      name: "my-account-my-profile"
    }, {
      path: "my-reviews",
      component: _05588e1e,
      name: "my-account-my-reviews"
    }, {
      path: "order-history",
      component: _382ed324,
      name: "my-account-order-history"
    }, {
      path: "shipping-details",
      component: _2d70a55a,
      name: "my-account-shipping-details"
    }]
  }, {
    path: "/password-reset",
    component: _18173c08,
    name: "password-reset"
  }, {
    path: "/Search",
    component: _5696e6e4,
    name: "Search"
  }, {
    path: "/password-reset/change-password",
    component: _74deb07c,
    name: "password-reset-change-password"
  }, {
    path: "/password-reset/check-email",
    component: _3ef3b7c1,
    name: "password-reset-check-email"
  }, {
    path: "/:slug?",
    component: _632acbc4,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
