import { configure, extend } from 'vee-validate';
import { confirmed, email, max, min, oneOf, required } from 'vee-validate/dist/rules';
import { POSTAL_CODE_FORMAT, STREET_NUMBER_VALIDATION_DISABLED } from '~/config/shared/countryConfig';
import { i18nToCurrentCountryCode } from '~/helpers/locales/i18nCurrentDetails';

export default function ({ app: { i18n } }) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = i18n.t(`validation.fieldNames.${field}`);
      return i18n.t(`validation.${values._rule_}`, values);
    }
  });

  extend('email', {
    ...email
  });

  extend('required', {
    ...required
  });

  extend('min', {
    ...min
  });

  extend('max', {
    ...max
  });

  extend('phoneNumber', (value) => {
    // TODO: INSP-344- add appropriate validation based on region
    return /^\+?(\d ?){8,}$/.test(value);
  });

  extend('confirmed', {
    ...confirmed
  });

  extend('oneOf', {
    ...oneOf
  });

  extend('password', {
    validate: value => !!(String(value).match(/[A-Za-z]/gi) &&
      String(value).match(/[0-9]/gi)),
    message: i18n.t('Password must include one letter and one number')
  });

  extend('rating', {
    ...required
  });

  extend('postalCode', (value) => {
    const postalCodeRegex = POSTAL_CODE_FORMAT[i18nToCurrentCountryCode(i18n)];

    return postalCodeRegex ? postalCodeRegex.test(value) : true;
  });

  extend('streetNumber', (value) => {
    const omitValidation = STREET_NUMBER_VALIDATION_DISABLED[i18nToCurrentCountryCode(i18n)];

    return omitValidation ? true : /\d/.test(value);
  });
}
