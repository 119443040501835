import removeIndexNameFromQuery from './removeIndexNameFromQuery';
import QueryParams from '~/types/generic/queryParams';

export default (
  queryObj: QueryParams, algoliaIndexName: string): QueryParams => {
  queryObj = removeOldIndexNameWrapper(queryObj, algoliaIndexName);
  queryObj = addIndexNameToSortBy(queryObj, algoliaIndexName);

  if (!queryObj[algoliaIndexName]) {
    return {
      [algoliaIndexName]: {
        ...queryObj
      }
    };
  }

  return queryObj;
};

const addIndexNameToSortBy = (queryObj: QueryParams, algoliaIndexName: string): QueryParams => {
  const sortByNotContainsIndexName = queryObj.sortBy && !queryObj.sortBy?.toString().includes(algoliaIndexName);

  if (sortByNotContainsIndexName) {
    queryObj.sortBy = `${algoliaIndexName}-${queryObj.sortBy}`;
  }

  return queryObj;
};

const removeOldIndexNameWrapper = (queryObj: QueryParams, algoliaIndexName: string): QueryParams => {
  const queryKeys = Object.keys(queryObj);
  const firstQueryKeyAsWrapper = queryKeys.length ? queryKeys[0] : null;
  const queryObjectIsWrappedByAShorterAlgoliaIndexName =
  firstQueryKeyAsWrapper && algoliaIndexName !== firstQueryKeyAsWrapper &&
    algoliaIndexName.toLowerCase().includes(firstQueryKeyAsWrapper.toLowerCase());

  /**
   * Example: the correct algolia index name is `prod.pt-PT`, but it is
   * wrapped by `prod.PT`. In that case the old/wrong index name wrapper is
   * removed.
   */
  if (firstQueryKeyAsWrapper && queryObjectIsWrappedByAShorterAlgoliaIndexName) {
    queryObj = removeIndexNameFromQuery(queryObj, firstQueryKeyAsWrapper);
  }

  return queryObj;
};
