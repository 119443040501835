
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import {
  useIsPage,
  useIsOnCategory
} from '~/composables';

type getAddToCartButtonLocationParams = {
  isMainProduct?: boolean;
  isWarrantySection?: boolean
};

type useRouteOfAddToCartReturn = {
  getAddToCartButtonLocation: (params: getAddToCartButtonLocationParams) => string;
};

export const useRouteOfAddToCart = (): useRouteOfAddToCartReturn => {
  const { isOnProductPage, isOnHomePage, isOnSearchPage } = useIsPage();
  const { isOnCategory } = useIsOnCategory();

  const getAddToCartButtonLocation = ({
    isMainProduct,
    isWarrantySection
  }: getAddToCartButtonLocationParams): string => {
    switch (true) {
      case isMainProduct:
        return EVENT_SOURCE.PDP;
      case isWarrantySection:
        return EVENT_SOURCE.PDP_WARRANTY_SECTION;
      case isOnSearchPage.value:
        return EVENT_SOURCE.SEARCH_LISTING;
      case isOnHomePage.value:
        return EVENT_SOURCE.HOMEPAGE_TOP_SELLERS;
      case isOnProductPage.value:
        return EVENT_SOURCE.PDP_SIMILAR_PRODUCTS;
      case isOnCategory():
        return EVENT_SOURCE.CATEGORY_LISTING;
      default:
        return '';
    }
  };

  return {
    getAddToCartButtonLocation
  };
};
