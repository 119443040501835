import { useVSFContext } from '@vue-storefront/core';
import { computed, Ref } from '@nuxtjs/composition-api';
import { UseMyAccountPage } from '~/types/myaccount/UseMyAccountPage';

export const useExternalPage: UseMyAccountPage = (route: string|undefined|Ref<string>, rawLabel: string|undefined) => {
  const { i18n } = useVSFContext();
  const isInternal = false;
  const label = i18n.t(rawLabel);

  const goToThatPage = (): void => {
    window.open(typeof route === 'object' ? route.value : route, '__blank');
  };

  const key = computed(() => {
    return rawLabel ? rawLabel.replace(' ', '-') : 'default-key';
  });
  return {
    label,
    goToThatPage,
    isInternal,
    key
  };
};
