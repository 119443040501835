import { computed, reactive } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { NotificationStatus } from '~/types/components/Notification/NotificationStatus';

interface UiNotification {
  id: string;
  message: VueI18n.TranslateResult;
  subtitle?: VueI18n.TranslateResult;
  action?: { text: string; onClick: (...args: any) => void };
  type: NotificationStatus;
  icon?: string;
  dismissible?: boolean;
  duration?: number;
  date?: string;
  endingLinkText?: VueI18n.TranslateResult;
  endingLinkSlug?: string;
  dismiss?: () => void;
}

interface Notifications {
  notifications: Array<UiNotification>;
}

const state = reactive<Notifications>({
  notifications: []
});

const useUiNotification = () => {
  const send = (notification: UiNotification) => {
    const { id } = notification;

    const notificationDuration = notification.duration || 3000;
    const date = notification.date || new Date().toTimeString().split(' ')[0];

    const dismiss = () => {
      state.notifications = state.notifications.filter(notification => notification.id !== id);
    };

    const newNotification = {
      ...notification,
      id,
      date,
      dismiss
    };
    const checkIfNotificationExist = state.notifications.find(el => el.id === id);
    if (!checkIfNotificationExist) {
      state.notifications.push(newNotification);
    }

    if (notification.dismissible) {
      setTimeout(dismiss, notificationDuration);
    }
  };

  const remove = (id: string) => {
    state.notifications = state.notifications.filter(notification => notification.id !== id);
  };

  return {
    send,
    remove,
    notifications: computed(() => state.notifications)
  };
};

export default useUiNotification;
