import {
  useIntegrations,
  useProductForTagManager
} from '~/composables';
import {
  ADD_TO_CART_EVENT_BUY_NOW
} from '~/constants/googleTagManager';

type useAddToCartTagManagerParams = {
  slug: string;
  addToCartText: string;
};

type triggerTagsParams = {
  quantity: number;
  triggeredIn: string;
  shouldOpenCart?: boolean;
};

type useAddToCartTagManagerReturn = {
  triggerTags: (params: triggerTagsParams) => void;
};

export const useAddToCartTagManager = ({
  slug,
  addToCartText
}: useAddToCartTagManagerParams): useAddToCartTagManagerReturn => {
  const { $tagManager } = useIntegrations();
  const { masterProduct: product, search: searchProduct } = useProductForTagManager();
  const triggerTags = ({
    quantity, triggeredIn, shouldOpenCart
  }: triggerTagsParams) => {
    const searchParams = { slug };
    searchProduct(searchParams).then(() => {
      if (product.value) {
        $tagManager.events.triggerAddToCartTags({
          product: product.value,
          quantity,
          textDisplayed: shouldOpenCart ? ADD_TO_CART_EVENT_BUY_NOW : addToCartText,
          triggeredIn,
          shouldOpenCart
        });
      }
    });
  };

  return {
    triggerTags
  };
};
