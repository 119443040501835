import type { OrderQueryResult, Order, RawCustomField } from '@vsf-enterprise/commercetools-types';
import { useUserOrder as useVsfUserOrder } from '@vsf-enterprise/commercetools';
import type { OrderSearchParams } from '@vsf-enterprise/commercetools';
import type { Ref, ComputedRef } from '@nuxtjs/composition-api';
import { computed, ref } from '@nuxtjs/composition-api';
import type { ComposableFunctionArgs } from '@vue-storefront/core';
import orderToPaymentType from '~/helpers/Checkout/orderToPaymentType';
import { MAKE_PAYMENT_RESPONSE } from '~/constants/paymentCustomFields';
import CustomPayment from '~/constants/customPayment';

export const useUserOrder = () => {
  const {
    loading,
    search: oldSearch,
    error,
    orders
  } = useVsfUserOrder();

  const isLoaded = ref(false);

  const order: ComputedRef<Order> = computed(() => orders.value?.results?.[0]);

  const orderNumber: ComputedRef<Order['orderNumber']> = computed(() => order.value?.orderNumber);

  const orderPayment: ComputedRef<RawCustomField | null> = computed(() =>
    order.value?.paymentInfo?.payments[0]?.custom?.customFieldsRaw
      ?.find(el => el.name === MAKE_PAYMENT_RESPONSE) ?? null
  );

  const paymentType = computed(() => orderToPaymentType(order.value));

  const isOrderPaymentTypeBank = computed(() =>
    paymentType.value === CustomPayment.BANK_TRANSFER
  );

  const isOrderPaymentTypeAdyen = computed(() =>
    paymentType.value === CustomPayment.ADYEN);

  const search: typeof oldSearch = async (params: ComposableFunctionArgs<OrderSearchParams>) => {
    await oldSearch(params);
    isLoaded.value = true;
  };

  return {
    orders: orders as Ref<OrderQueryResult>,
    order,
    orderNumber,
    orderPayment,
    search,
    loading,
    isLoaded: computed(() => (isLoaded.value || !!order.value)),
    error,
    isOrderPaymentTypeBank,
    isOrderPaymentTypeAdyen,
    paymentType
  };
};
