import { ref, onMounted, computed } from '@nuxtjs/composition-api';
import { useI18n } from '~/composables/useI18n';
import { loadParcelPerform } from '~/helpers/loadParcelPerform';
import languageCodes from '~/i18n/enums/languageCodes';
/*
 * Custom composable for parcel perform external script
 */
export const useParcelPerform = () => {
  const { countryLocalization, language } = useI18n();
  const isLoading = ref(true);
  const parcelPerformId = computed(() =>
    countryLocalization.value.getParcelPerformId(language.value as ValueOf<typeof languageCodes>));

  const enableParcelPerform = async () => {
    setIsLoading(true);
    await loadParcelPerform(parcelPerformId.value as string);
    setIsLoading(false);
  };

  onMounted(async () => {
    await enableParcelPerform();
  });

  const setIsLoading = (value: boolean) => {
    isLoading.value = value;
  };

  return {
    isLoading,
    enableParcelPerform,
    country: countryLocalization.value.countryCode
  };
};

export default useParcelPerform;
