/**
 * @param {Number | undefined} centAmount
 * @param {Number} defaultCentAmount
 * @returns {Number}
 */
const centAmountToNormalPrice = (centAmount, defaultCentAmount = 0) =>
  centAmount
    ? Number((centAmount / 100).toFixed(2))
    : defaultCentAmount;

/**
 * @typedef {import('@vsf-enterprise/commercetools-types').Cart} Cart
 * @typedef {import('@vsf-enterprise/commercetools-types').Order} Order
 * @typedef {import('@vsf-enterprise/commercetools-types').CustomLineItem} CustomLineItem
 * @typedef {import('@vsf-enterprise/commercetools-types').LineItem} LineItem
 * @param {Cart | Order | CustomLineItem | LineItem} cartOrOrder
 * @returns {Number}
 */
const getTotalGrossPrice = (cartOrOrder) => {
  return centAmountToNormalPrice(cartOrOrder?.taxedPrice?.totalGross?.centAmount);
};

const getTotalCentAmount = (totalGrossPrice) => {
  return Number((totalGrossPrice * 100).toFixed());
};

module.exports = {
  centAmountToNormalPrice,
  getTotalGrossPrice,
  getTotalCentAmount
};
