import { CAMPAIGN_INDICATOR } from '~/constants/commerceTools';
import { ProductTag } from '~/types/product/attribute/ProductTag';
import { Campaign } from '~/types/store/campaign';

export const buildCampaignLabel = (campaignName: string): string => {
  return `${CAMPAIGN_INDICATOR}${campaignName}`;
};

export const buildHumanReadableCampaignName = (inputLabel: string): string => {
  return inputLabel
    .replace(new RegExp(`^${CAMPAIGN_INDICATOR}`, 'i'), '')
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const extractActiveCampaigns = (campaigns: Campaign[]): Campaign[] => {
  const currentTime = new Date();
  return campaigns.filter(campaign => {
    const startDate = new Date(campaign.startDate);
    const endDate = new Date(campaign.endDate);
    return startDate <= currentTime && endDate > currentTime;
  });
};

const sortCampaignsByEndDate = (campaigns: Campaign[]) => {
  campaigns.sort((a, b) => {
    const endDateA = new Date(a.endDate);
    const endDateB = new Date(b.endDate);
    return endDateA.getTime() - endDateB.getTime();
  });
};

const findClosestActiveCampaign = (activeCampaigns: Campaign[]): Maybe<Campaign> => {
  if (activeCampaigns.length > 1) {
    sortCampaignsByEndDate(activeCampaigns);
  }
  return activeCampaigns.length > 0 ? activeCampaigns[0] : null;
};

export const findEarliestActiveCampaignEndDate = (
  productTags: ProductTag[], activeCampaigns: Campaign[]
): Maybe<string> => {
  const activeProductCampaigns = activeCampaigns.filter(campaign =>
    productTags.includes(buildCampaignLabel(campaign.campaignName))
  );
  if (activeProductCampaigns.length) {
    return findClosestActiveCampaign(activeProductCampaigns)?.endDate;
  }
};
