import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { RemoveFromCartTagDetailsParams } from '~/types/integrations/tagManager/TagManagerIntegration';
import { useIntegrations } from '~/composables';

export const useRemoveFromCartTagManager = () => {
  const { $tagManager } = useIntegrations();

  const triggerTags = (product: ProductVariant, details: RemoveFromCartTagDetailsParams) => {
    $tagManager.events.triggerRemoveFromCartTags(
      product,
      {
        quantity: details.quantity,
        textDisplayed: details.textDisplayed,
        triggeredIn: details.triggeredIn,
        index: details.index
      }
    );
  };

  return { triggerTags };
};
