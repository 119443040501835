import { Gtm } from '~/types/@nuxtjs/gtm';
import { AddToCartTags } from '~/plugins/integrations/tagManager/helpers/AddToCartTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { TriggerAddToCartTagsParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerAddToCartTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return ({ product, quantity, textDisplayed, triggeredIn, shouldOpenCart }: TriggerAddToCartTagsParameters) => {
    const productTags = prepareProductTags(product, { languageAndCountry, quantity, currency });
    const addToCartTags = new AddToCartTags({ productTags, textDisplayed, triggeredIn, shouldOpenCart });
    $gtm.push({ ecommerce: null });
    $gtm.push(addToCartTags);
  };
};
