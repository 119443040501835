import type { Address } from '@vsf-enterprise/commercetools-types';

const createMiddleSection = (field: Maybe<string>) => {
  return field ? ` ${field}` : '';
};
export const getStreetAddress = (address: Maybe<Partial<Address>>): string => {
  const streetName = getStreetName(address) || '';
  const additionalAddressInfo = createMiddleSection(address?.additionalAddressInfo);

  return `${streetName}${additionalAddressInfo}`;
};
export const getStreetName = (address: Maybe<Partial<Address>>): string | undefined => {
  const streetName = address?.streetName || '';
  const streetNumber = createMiddleSection(address?.streetNumber);

  return streetName ? `${streetName}${streetNumber}` : undefined;
};
