import { EVENT_CLICK_HIT } from '~/constants/googleTagManager';

export class ClickHitTags {
  event = EVENT_CLICK_HIT;
  userToken?: string;
  index: string;
  objectIDs: string[];
  searchTerm: string;
  positions: number[];
  constructor({ userToken, index, objectIDs, searchTerm, positions }: {
    userToken?: string,
    index: string,
    objectIDs: string[],
    searchTerm: string,
    positions: number[]
  }) {
    this.userToken = userToken;
    this.index = index;
    this.objectIDs = objectIDs;
    this.searchTerm = searchTerm;
    this.positions = positions;
  }
}
