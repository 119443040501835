import { computed } from '@nuxtjs/composition-api';
import type{ ComputedRef, Ref } from '@nuxtjs/composition-api';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { productGetters } from '@vsf-enterprise/commercetools';
import { extractStandardPrice, getProductPriceAfterDiscounts } from '~/helpers/product/getPriceInfo';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';

type GetFinalPriceParameters = Ref<number> | ComputedRef<number | undefined>;
type PriceInformationParameters = ComputedRef<number | null>;

export interface PriceInformation {
  productPrice: PriceInformationParameters,
  discountedPrice: ComputedRef<Maybe<number>>,
  priceAfterDiscounts: PriceInformationParameters,
  finalPrice: PriceInformationParameters,
  priceBeforeDiscounts: PriceInformationParameters,
}

export default function () {
  const getFinalPrice = (
    discountedPrice: GetFinalPriceParameters,
    price: GetFinalPriceParameters
  ) => computed(() => discountedPrice.value || price.value || null);

  const getFormatedPrice = (product: ProductVariant) =>
    productGetters.getFormattedPrice(getProductPriceAfterDiscounts(product));

  const getPriceInformation = (
    product: Ref<ProductVariant>,
    localisedAttributes: Ref<ProductProcessedAttributes>
  ): PriceInformation => {
    const priceBeforeDiscounts = computed(() => {
      return extractStandardPrice(localisedAttributes.value);
    });

    const priceAfterDiscounts = computed(() => getProductPriceAfterDiscounts(product.value));
    const productPrice = computed(() => priceBeforeDiscounts.value || priceAfterDiscounts.value);
    const discountedPrice = computed(() => priceBeforeDiscounts.value ? priceAfterDiscounts.value : undefined);
    const finalPrice = getFinalPrice(discountedPrice, productPrice);

    return {
      priceBeforeDiscounts,
      priceAfterDiscounts,
      productPrice,
      discountedPrice,
      finalPrice
    };
  };

  return {
    getPriceInformation,
    getFinalPrice,
    getFormatedPrice
  };
}
