import { useProduct } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { useI18n, usePrefix } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import type { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { CUSTOM_QUERIES } from '~/constants/customQueries';

export default function (cacheId: string) {
  const {
    products: oldProducts,
    search: oldSearch
  } = useProduct<ProductVariantGql>(`alternative-${cacheId}`);
  const { languagePrefix } = usePrefix();
  const { languageAndCountry, countryCode } = useI18n();

  const products = computed<ProductVariantGql[]>(() =>
    oldProducts.value?.data?.products?.results || []
  );

  const alternativeProduct = computed(() => {
    const publishedProductsInStock = extendProducts(
      products.value, languageAndCountry.value, countryCode.value, { isPublished: true, inStock: true });
    return publishedProductsInStock?.[0] || null;
  });

  const search = async (sku: string) => {
    const productSearchParams = getProductSearchParams(
      { skus: [sku] },
      { languageAndCountry: languageAndCountry.value, languagePrefix }
    );
    await oldSearch({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.productAlternative
    });
  };

  return {
    alternativeProduct,
    search
  };
}
