export const SLUG_COD_FEE: string = 'cod-fee';
export const COD_FEE_INFO_AMOUNT_NAME: string = 'cod_fee_amount';
export const COD_FEE_INFO_ENABLED: string = 'cod_fee_enabled';
export const COD_FEE_INFO_TITLE: string = 'cod_fee_title';
export const BANK_PAYMENT_BIC: string = 'bank_transfer_payment_data_bic_or_swift';
export const BANK_PAYMENT_ACCOUNT_OWNER = 'bank_transfer_payment_data_account_owner';
export const BANK_PAYMENT_INSTITUTE = 'bank_transfer_payment_data_bank_institute';
export const BANK_PAYMENT_IBAN = 'bank_transfer_payment_data_iban';
export const REGISTERED_CUSTOMER_TAB = 'returningUserTab';

export const NOT_SELECTED_ADDRESS = '';

export const ADYEN_PAYMENT_METHOD_MULTIBANCO = 'multibanco';

export const ONLINEBANKING_PL_NAME = 'Przelewy online / BLIK';

export const CART_PROMISE_QUEUE_KEY = 'cartPromiseQueue';

export const MY_ORDERS_URL = 'https://myorders.expondo.com/';
export const DAYS_UNTIL_ORDER_CANCELLATION = '10';
