import { AxiosResponse } from 'axios';
import { MyOrdersIntegration } from '~/types/integrations/myOrders/MyOrdersIntegration';

export default (instance: MyOrdersIntegration) => {
  const { axios } = instance.services;

  return (email: string): Promise<AxiosResponse> => {
    return axios.get('getUrlWithToken', {
      params: {
        email
      }
    });
  };
};
