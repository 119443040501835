import { ROUTES } from '~/constants/routes';
import { getSlugForRoute } from '~/helpers/routes/getPageInformation';

const canEnterPayment = cart => cart.shippingInfo && cart.shippingAddress;

export default ({
  app,
  $vsf
}) => {
  $vsf.$ct.api.getMe().then(({ data }) => {
    if (!data || !data.me.activeCart) return;
    const { activeCart } = data.me;
    if (!canEnterPayment(activeCart)) {
      app.context.redirect(getSlugForRoute(ROUTES.CHECKOUT_ADDRESSES));
    }
  });
};
