const STATUS_OK = 200;
const STATUS_CREATED = 201;
const STATUS_BAD_REQUEST = 400;
const STATUS_RESOURCE_NOT_FOUND = 404;
const STATUS_ERROR = 500;
const SECURE_WEBSITE_PROTOCOLE_PREFIX = 'https://';
const UNSECURE_WEBSITE_PROTOCOL_PREFIX = 'http://';
const WWW_PREFIX = 'www';
const STAGE_PREFIX = 'stage';
const DEV_PREFIX = 'dev';
const MIDDLEWARE_PORT = 8181;

module.exports = {
  STATUS_OK,
  STATUS_CREATED,
  STATUS_BAD_REQUEST,
  STATUS_ERROR,
  STATUS_RESOURCE_NOT_FOUND,
  SECURE_WEBSITE_PROTOCOLE_PREFIX,
  UNSECURE_WEBSITE_PROTOCOL_PREFIX,
  WWW_PREFIX,
  STAGE_PREFIX,
  DEV_PREFIX,
  MIDDLEWARE_PORT
};
