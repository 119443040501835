
import { Gtm } from '~/types/@nuxtjs/gtm';
import { ClickParentProductTags } from '~/plugins/integrations/tagManager/helpers/ClickParentProductTags';

export const triggerClickParentProductTagsWrapper = ($gtm: Gtm) => {
  return (parentSku: string, accessorySku: string) => {
    const clickParentProductTags = new ClickParentProductTags();
    clickParentProductTags.assignParentProductValues(parentSku, accessorySku);
    $gtm.push(clickParentProductTags);
  };
};
