import type { Route, MiddlewareRedirect } from 'vue-router';
import { QUERY_PARAMETERS_STARTER, QUERY_PARAMETER_EQUALS, QUERY_PARAMETER_JOINER } from '~/constants/routes';
import { SearchQueryParameters } from '~/types/search/SearchQueryParameters';

type SearchParameterRedirectionReturnFunction = (queryParameters: SearchQueryParameters) => void;

export default (
  route: Route,
  redirect: MiddlewareRedirect
): SearchParameterRedirectionReturnFunction =>
  (queryParameters: SearchQueryParameters) => {
    const queryString = Object.keys(queryParameters)
      .map(key => `${key}${QUERY_PARAMETER_EQUALS}${queryParameters[key]}`)
      .join(QUERY_PARAMETER_JOINER);
    redirect(`${route.path}${QUERY_PARAMETERS_STARTER}${queryString}`);
  };
