














import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SimpleCarouselArrow } from '~/types/components/SimpleCarousel/SimpleCarouselArrows';
export default defineComponent({
  name: 'SimpleCarouselButton',
  props: {
    arrowType: {
      type: String as PropType<SimpleCarouselArrow>,
      required: true
    }
  },
  setup(props, { emit }) {
    const desktopArrowClass = {
      container: 'w-9 h-9 rounded border-2 border-EXPONDO-grey-20 flex items-center justify-center ' +
          'bg-EXPONDO-white text-EXPONDO-primary-80 text-2xl font-light cursor-pointer',
      arrow: `icon-chevron-${props.arrowType === SimpleCarouselArrow.prev ? 'left' : 'right'}`
    };

    const arrowClick = (arrowType:SimpleCarouselArrow) => {
      emit('arrowClicked', arrowType);
    };

    return {
      desktopArrowClass,
      arrowClick
    };
  }
});
