import { getPageLinkForSlug } from '~/helpers/routes/getPageInformation';
import { DEFAULT_REDIRECTION_CODE } from '~/constants/seo';

export default ({ app: { context: { route } }, redirect }) => {
  const slug = route.path;
  const redirectPath = getPageLinkForSlug(slug);
  if (slug !== redirectPath) {
    redirect(DEFAULT_REDIRECTION_CODE, redirectPath, route.query);
  }
};
