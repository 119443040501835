import { EVENT_PRODUCT_DETAIL } from '~/constants/googleTagManager';
import { TagProduct } from '~/types/integrations/tagManager/TagProduct';
import { EcommerceViewProduct } from '~/types/integrations/tagManager/TagViewProduct';

export class ViewProductTags {
  event = EVENT_PRODUCT_DETAIL
  ecommerce: EcommerceViewProduct = {
    detail: {
      actionField: {
        list: ''
      },
      products: []
    }
  }

  assignProductValues (productTags: TagProduct) {
    this.ecommerce.detail.products.push(productTags);
  }

  assignActionField (parentCategory: string) {
    this.ecommerce.detail.actionField.list = parentCategory;
  }
}
