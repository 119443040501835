import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import { VolumeDiscount } from '~/types/volumeDiscounts';
import type {
  VolumeDiscountsMutations,
  VolumeDiscountsState,
  VolumeDiscountsGetters
} from '~/types/vuex/VolumeDiscounts';

const state = (): VolumeDiscountsState => ({
  volumeDiscountGroups: {}
});

export function setGroup(state: VolumeDiscountsState, payload: { groupKey: string, value: VolumeDiscount[] }) {
  state.volumeDiscountGroups = {
    ...state.volumeDiscountGroups,
    [payload.groupKey]: payload.value
  };
}

const getters: VolumeDiscountsGetters = {
  isGroupActive: (state: VolumeDiscountsState) => (volumeDiscountGroup: string) => {
    const group = state.volumeDiscountGroups[volumeDiscountGroup];
    return !!group && group.length > 0;
  }
};

const mutations: VolumeDiscountsMutations = {
  setGroup
};

export default extendModule({
  state,
  mutations,
  getters
});
