import { Logger } from '@vue-storefront/core';
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

interface InterceptorsOptions {
  axios: AxiosInstance;
  shouldRejectPromise?: boolean;
}

const successFunction = (response: AxiosResponse) => response;

const errorFunction = (error: AxiosError, shouldRejectPromise: boolean) => {
  Logger.error(error);
  if (shouldRejectPromise) {
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const setAxiosInterceptors = ({ axios, shouldRejectPromise = true }: InterceptorsOptions) => {
  axios.interceptors.response.use(
    successFunction,
    (error) => errorFunction(error, shouldRejectPromise)
  );
};
