import { Gtm } from '~/types/@nuxtjs/gtm';
import { QuantityChangeTags } from '~/plugins/integrations/tagManager/helpers/QuantityChangeTags';
import { TriggerQuantityChangeParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerQuantityChangeTagsWrapper = ($gtm: Gtm) => {
  return (params: TriggerQuantityChangeParameters) => {
    const quantityTags = new QuantityChangeTags(params);
    $gtm.push(quantityTags);
  };
};
