import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { ViewAccessoryTags } from '~/plugins/integrations/tagManager/helpers/ViewAccessoryTags';
import { prepareViewAccessoryItemsTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { pushCleanTags } from '~/plugins/integrations/tagManager/helpers/pushCleanTags/pushCleanTags';

export const triggerViewAccessoriesTagsWrapper = ($gtm: Gtm) => {
  return (products: ProductVariant[], parentProductSku: string) => {
    const viewAccessoryTags = new ViewAccessoryTags();
    viewAccessoryTags.assignSourceParentId(parentProductSku);
    viewAccessoryTags.assignAccessoryValues(
      prepareViewAccessoryItemsTags(products)
    );
    pushCleanTags($gtm, viewAccessoryTags);
  };
};
