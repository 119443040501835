import { productGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import type { ProductLocalisedAttributes } from '~/types/product/attribute/ProductLocalisedAttributes';
import getAttributeLocales from '~/helpers/product/getAttributeLocales';
import { getEnergyRating } from '~/helpers/product/energyRating';

const processLocalisedAttributes = (
  localisedAttributes: ProductLocalisedAttributes,
  product: ProductVariantExtended,
  languageAndCountry: string): ProductProcessedAttributes => {
  return {
    ...localisedAttributes,
    energyRating: getEnergyRating({ product, localisedAttributes, languageAndCountry })
  };
};

export const extractLocalisedAttributes = (
  product: ProductVariantExtended,
  attributes: string[],
  languageAndCountry: string
): ProductProcessedAttributes => {
  return processLocalisedAttributes(
    getAttributeLocales(
      productGetters.getAttributes(product, attributes),
      languageAndCountry
    ),
    product,
    languageAndCountry
  );
};
