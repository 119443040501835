import { ShippingMethodDeliveryInformation } from '~/types/checkout/ShippingMethod';

const SUNDAY_DAY_INDEX = 0;
const SATURDAY_DAY_INDEX = 6;

const getDeliveryDate = (
  languageAndCountry: string,
  shippingData?: ShippingMethodDeliveryInformation,
  shortFormat: boolean = false
): string | undefined => {
  const customFieldsRaw = shippingData?.custom?.customFieldsRaw;

  let deliveryTime = customFieldsRaw?.[0]?.value?.[languageAndCountry] || undefined;

  if (!deliveryTime) {
    return;
  }

  deliveryTime = deliveryTime?.replace(/ /g, '');

  const regexPattern = /\b(\d+)-(\d+)\b/;

  const matching = deliveryTime.match(regexPattern);

  if (!matching || !matching[2]) {
    return;
  }

  const longestDeliveryTime = parseInt(matching[2], 10);

  return calculateDeliveryDate(longestDeliveryTime, languageAndCountry, shortFormat);
};

const calculateDeliveryDate = (days: number, languageAndCountry: string, shortFormat: boolean = false) => {
  const startDate = new Date(Date.now());
  let count = 0;

  while (count < days) {
    startDate.setDate(startDate.getDate() + 1);
    if (startDate.getDay() !== SUNDAY_DAY_INDEX && startDate.getDay() !== SATURDAY_DAY_INDEX) {
      count++;
    }
  }

  const options: Intl.DateTimeFormatOptions = shortFormat
    ? { month: 'numeric', day: 'numeric' }
    : { weekday: 'long', month: 'long', day: 'numeric' };

  const formattedDeliveryDate = startDate.toLocaleDateString(languageAndCountry, options);

  return formattedDeliveryDate;
};

export default getDeliveryDate;
