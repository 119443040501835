import { Logger } from '@vue-storefront/core';
import { useIntegrations } from '~/composables';

const useCia = () => {
  const { $cia } = useIntegrations();

  const registerCheckoutEvent = (checkoutStep: number) => {
    try {
      $cia.event.checkout(checkoutStep);
    } catch (error) {
      Logger.error(`cia|checkout error: ${error}`);
    }
  };

  return {
    registerCheckoutEvent
  };
};

export default useCia;
