import { Gtm } from '~/types/@nuxtjs/gtm';
import { ConversionTags } from '~/plugins/integrations/tagManager/helpers/ConversionTags';
import type { TriggerConversionEventParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerConversionTagsWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerConversionEventParameters) => {
    const { userToken, index, objectIDs, searchTerm } = parameters;
    const viewHitsTags = new ConversionTags({ userToken, index, objectIDs, searchTerm });

    $gtm.push(viewHitsTags);
  };
};
