import { useVSFContext } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ComplementaryProductsState,
  ComplementaryProductsMutations
} from '~/types/vuex/ComplementaryProducts';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { useI18n, usePrefix } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { COMPLEMENTARY_PRODUCTS_LIMIT } from '~/constants/commerceTools.js';

export default () => {
  const { $ct } = useVSFContext();
  const store = defineModule<
  typeof MODULE_NAMES.COMPLEMENTARY_PRODUCTS_MODULE_NAME,
  ComplementaryProductsMutations,
  ComplementaryProductsState
>(MODULE_NAMES.COMPLEMENTARY_PRODUCTS_MODULE_NAME);

  const { languageAndCountry, countryCode } = useI18n();
  const { languagePrefix } = usePrefix();

  const complementaryBaseProduct = computed(() => store.state.complementaryBaseProduct);
  const complementaryProducts = computed(() => store.state.complementaryProducts);

  const search = async (sku: string) => {
    const productSearchParams = getProductSearchParams(
      { limit: COMPLEMENTARY_PRODUCTS_LIMIT },
      {
        languageAndCountry: languageAndCountry.value,
        languagePrefix,
        isOnStock: true
      }
    );
    const res = await $ct.api.getComplementaryProductsBySku({ skus: [sku] }, productSearchParams);
    const productsResults = res?.products?.data?.products?.results;
    const baseProductResults = res?.baseProduct?.data?.products?.results;
    const getExtendedProducts = (productsResults: ProductVariantGql[]) => {
      return extendProducts(productsResults, languageAndCountry.value, countryCode.value, { isPublished: true });
    };

    const products = getExtendedProducts(productsResults);
    const baseProduct = getExtendedProducts(baseProductResults)?.[0];

    store.mutations.setComplementaryProducts(products);
    store.mutations.setComplementaryBaseProduct(baseProduct);
  };

  return {
    search,
    complementaryBaseProduct,
    complementaryProducts
  };
};
