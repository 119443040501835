import http from 'http';
import https from 'https';
import { AxiosRequestConfig } from 'axios';
import { MIDDLEWARE_TIMEOUT } from '../../constants/bloomreach';

export const getAxiosConfig = (baseURL: string | undefined): AxiosRequestConfig => {
  return {
    baseURL,
    timeout: MIDDLEWARE_TIMEOUT,
    httpAgent: new http.Agent({ timeout: MIDDLEWARE_TIMEOUT, noDelay: true }),
    httpsAgent: new https.Agent({ timeout: MIDDLEWARE_TIMEOUT, noDelay: true })
  };
};
