import { useAdyen } from '@vsf-enterprise/adyen-commercetools';
import { onBeforeUnmount, watch } from '@nuxtjs/composition-api';
import useAdyenError from '~/composables/stores/useAdyenError';
import { usePromiseQueue } from '~/composables/modules/usePromiseQueue';
import { CART_PROMISE_QUEUE_KEY } from '~/constants/checkout';

export const useAdyenExtended = () => {
  const useAdyenInstance = useAdyen();
  const {
    error,
    errorPayAndOrder,
    setError
  } = useAdyenError();

  const payAndOrder = usePromiseQueue(useAdyenInstance.payAndOrder, CART_PROMISE_QUEUE_KEY).execute;

  watch(useAdyenInstance.error, () => {
    setError({
      ...useAdyenInstance.error.value
    });
  }, {
    deep: true,
    immediate: true
  });

  onBeforeUnmount(() => {
    setError(null);
  });

  return {
    ...useAdyenInstance,
    payAndOrder,
    error,
    errorPayAndOrder
  };
};
