const HREFLANG_RELATION = 'alternate';
const CANONICAL_RELATION = 'canonical';
const DEFAULT_REDIRECTION_CODE = 308;
const CUSTOM_HEADER_LABEL = 'x-request-originator';
const BREADCRUMBS_HOMEPAGE_PROP_NAME = 'expondo';
const WHITELIST_URL_PARAMETERS_FOR_INDEXING = ['page'];
const FIRST_PAGE_URL_PARAMETER = 'page=1';
const SCHEMA_ITEM_TYPE = {
  BREADCRUMB_LIST_ITEM: 'https://schema.org/ListItem',
  BREADCRUMB_LIST: 'https://schema.org/BreadcrumbList',
  OFFER_IN_STOCK: 'https://schema.org/InStock',
  OFFER_OUT_OF_STOCK: 'https://schema.org/OutOfStock',
  OFFER_PRE_ORDER: 'https://schema.org/PreOrder',
  OFFER: 'https://schema.org/Offer',
  PRODUCT: 'https://schema.org/Product',
  SITE_NAVIGATION_ELEMENT: 'https://schema.org/SiteNavigationElement',
  WEB_PAGE: 'https://schema.org/WebPage',
  FAQ_PAGE: 'https://schema.org/FAQPage',
  QUESTION: 'https://schema.org/Question',
  ANSWER: 'https://schema.org/Answer',
  REVIEW: 'http://schema.org/Review',
  AGGREGATE_RATING: 'http://schema.org/AggregateRating',
  RATING: 'http://schema.org/Rating',
  PERSON: 'http://schema.org/Person'
};
const SCHEMA_ITEM_PROP = {
  ITEM_LIST_ELEMENT: 'itemListElement',
  ITEM: 'item',
  NAME: 'name',
  TEXT: 'text',
  POSITION: 'position',
  URL: 'url',
  AVAILABILITY: 'availability',
  OFFERS: 'offers',
  PRODUCTID: 'productID',
  SKU: 'sku',
  PRICE: 'price',
  PRICE_CURRENCY: 'priceCurrency',
  IMAGE: 'image',
  BRAND: 'brand',
  WEIGHT: 'weight',
  HEIGHT: 'height',
  WIDTH: 'width',
  DEPTH: 'depth',
  RELEASE_DATE: 'releaseDate',
  COPYRIGHT_HOLDER: 'copyrightHolder',
  COPYRIGHT_YEAR: 'copyrightYear',
  IS_FAMILY_FRIENDLY: 'isFamilyFriendly',
  ACCEPTED_ANSWER: 'acceptedAnswer',
  MAIN_ENTITY: 'mainEntity',
  REVIEW: 'review',
  AUTHOR: 'author',
  REVIEW_BODY: 'reviewBody',
  DATE_PUBLISHED: 'datePublished',
  AGGREGATE_RATING: 'aggregateRating',
  RATING_VALUE: 'ratingValue',
  WORST_RATING: 'worstRating',
  BEST_RATING: 'bestRating',
  REVIEWS_COUNT: 'ratingCount',
  REVIEW_RATING: 'reviewRating'
};
const ROBOT_META_INSTRUCTION = {
  INDEX_FOLLOW: 'content="index, follow"',
  NOINDEX_NOFOLLOW: 'content="noindex, nofollow"'
};

module.exports = {
  HREFLANG_RELATION,
  CANONICAL_RELATION,
  DEFAULT_REDIRECTION_CODE,
  CUSTOM_HEADER_LABEL,
  BREADCRUMBS_HOMEPAGE_PROP_NAME,
  WHITELIST_URL_PARAMETERS_FOR_INDEXING,
  SCHEMA_ITEM_TYPE,
  SCHEMA_ITEM_PROP,
  ROBOT_META_INSTRUCTION,
  FIRST_PAGE_URL_PARAMETER
};
