import { CATEGORY_FILTER_INDICATOR, CATEGORY_FILTER_SEPARATOR } from '~/constants/algolia';
import { STRIP_START_AND_END_QUOTES } from '~/constants/regex';

export const getCategoryNameFromFilter = (algoliaCategoryFilter: string): string => {
  if (!algoliaCategoryFilter || !algoliaCategoryFilter.includes(CATEGORY_FILTER_SEPARATOR)) {
    return '';
  }

  const categoryListString = algoliaCategoryFilter.split(CATEGORY_FILTER_SEPARATOR)[1];
  const categoryList = categoryListString?.split(CATEGORY_FILTER_INDICATOR);
  const lastCategory = categoryList?.[categoryList.length - 1];
  return lastCategory?.replace(STRIP_START_AND_END_QUOTES, '') || '';
};
