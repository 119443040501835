import { RemarketingTagParams } from '~/types/integrations/tagManager/RemarketingTagParams';

/* eslint-disable camelcase */
export class RemarketingTags {
  event: string = 'remarketingTriggered';
  google_tag_params: RemarketingTagParams = {};

  assignRemarketingValues (tagsForRemarketing: RemarketingTagParams) {
    this.google_tag_params = tagsForRemarketing;
  }
}
