import type { ProductsSearchParams } from '@vsf-enterprise/commercetools';
import { AttributeType } from '@vsf-enterprise/commercetools-api';
import type { ProductWhereSearch } from '@vsf-enterprise/commercetools-api';
import type { ProductsSearchFilter, ProductsSearchParamsExtended } from '~/types/product/ProductSearch';
import {
  RELATED_PRODUCT_LIMIT
} from '~/constants/commerceTools';
import { StringBoolean } from '~/types/StringBoolean';
import { excludeUnsellableProduct } from '~/helpers/commercetools/graphql/excludeUnsellableProduct';
import type { ProductsSearchCustomParams } from '~/types/product/ProductsSearchCustomParams';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export const getProductSearchParams = (
  searchParams: ProductsSearchParams | ProductWhereSearch,
  customParams: ProductsSearchCustomParams
): ProductsSearchParamsExtended => {
  const storeFilter: ProductsSearchFilter = {
    type: AttributeType.LOCALIZED_STRING,
    name: PRODUCT_ATTRIBUTES.ELIGIBLE_FOR_SELLING,
    value: StringBoolean.true
  };
  let customFiltersForSearch = excludeUnsellableProduct(customParams.languageAndCountry);
  if (searchParams?.customFilters) {
    customFiltersForSearch += searchParams.customFilters;
  }

  const limit = 'limit' in searchParams ? searchParams.limit : RELATED_PRODUCT_LIMIT;

  const searchParamsWithFilter: ProductsSearchParamsExtended = {
    ...searchParams,
    filters: [storeFilter],
    limit,
    isOnStock: customParams.isOnStock,
    customFilters: customFiltersForSearch
  };
  if (customParams.languagePrefix) {
    searchParamsWithFilter.languagePrefix = customParams.languagePrefix;
  }

  return searchParamsWithFilter;
};
