import { AxiosResponse } from 'axios';
import { ViewItemRequest } from '~/types/integrations/cia/event/view-item/ViewItemRequest';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { email, locale, userId, posthogDistinctId } = instance.state;

  // send view_item event to Bloomreach microservice - designed to run it on both ssr and csr
  return (sku: string, posthogIdentifier: string): Promise<AxiosResponse> => {
    if (!sku) {
      return;
    }

    const payload: ViewItemRequest = {
      locale: locale.value,
      sku,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      posthog_identifier: posthogIdentifier ?? posthogDistinctId.value
    };

    if (email.value) {
      payload.email = email.value;
    }

    if (userId.value) {
      payload.user_id = userId.value;
    }

    return axios.post('view-item', payload);
  };
};
