import type { Category } from '@vsf-enterprise/commercetools-types';
import { sortCategories } from '~/helpers/category/sortCategories';

const findChildIndexBySlug = (parent: Category, slug: string) => {
  return parent.children?.findIndex((child) => child.slug === slug);
};

const replaceChildWithCategory = (parent: Category, category: Category): void => {
  if (!parent.children) {
    return;
  }

  const childIndex = findChildIndexBySlug(parent, category.slug || '');
  if (childIndex !== undefined && childIndex !== -1) {
    parent.children[childIndex] = category;
  }
};

export const buildTreeFromArray = (categoriesArray: Category[]): Category[] => {
  for (let i = 1; i < categoriesArray.length; i++) {
    const parent = categoriesArray[i - 1];
    const current = categoriesArray[i];

    if (!parent || !current) {
      continue;
    }

    if (!parent.children) {
      parent.children = [current];
      continue;
    }

    replaceChildWithCategory(parent, current);
    sortCategories(parent.children);
  }

  const rootCategory = categoriesArray[0];
  if (!rootCategory) {
    return [];
  }

  return [rootCategory];
};
