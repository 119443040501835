import type { Ref } from '@nuxtjs/composition-api';
import { useRecommendationEvent } from '~/composables';
import { RECOMMENDATION_ACTIONS } from '~/constants/recommendationRequest';
import { BloomreachTypeName } from '~/types/localization/localizationHelperTypes';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { useIntersectionObserverEvent } from '~/composables/modules/useIntersectionObserverEvent';

export const useRecommendationIntersection = ({
  suggestedProducts,
  recommendationType
}: {
  suggestedProducts?: Ref<BloomreachProduct[]>,
  recommendationType?: BloomreachTypeName
}) => {
  const {
    triggerCiaRecommendationEvent,
    triggerGtmRecommendationEvent
  } = useRecommendationEvent({ recommendationType });

  const { onIntersectOnce } = useIntersectionObserverEvent({
    onIntersectCallback: () => {
      triggerCiaRecommendationEvent(RECOMMENDATION_ACTIONS.SHOW);
      if (suggestedProducts?.value?.length) {
        triggerGtmRecommendationEvent({ suggestedProducts: suggestedProducts.value });
      }
    }
  });

  return {
    onIntersectOnce
  };
};
