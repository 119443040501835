





















import { defineComponent } from '@nuxtjs/composition-api';
import { extractComponents } from '~/helpers/cms/extractComponents';
import { ExtractedComponent } from '~/types/cms/CmsComponentData';
import ComponentName from '~/cms/ComponentName/ComponentName.vue';

export default defineComponent({
  name: 'RenderContent',
  components: {
    ComponentName,
    ImageBanner: () => import(/* webpackChunkName: "ImageBanner" */
      '~/cms/verticalLander/ImageBanner.vue'),
    HomeProducts: () => import(/* webpackChunkName: "HomeProducts" */
      '~/cms/verticalLander/HomeProducts.vue'),
    VerticalLanderLinkoutImage: () =>
      import(
        '~/cms/verticalLander/VerticalLanderLinkoutImage/VerticalLanderLinkoutImage.vue'
      ),
    HelpMenu: () => import(/* webpackChunkName: "HelpMenu" */
      '~/cms/help/HelpMenu.vue'),
    NavImageGrid: () => import(/* webpackChunkName: "NavImageGrid" */
      '~/cms/NavImageGridCms.vue'),
    DepartmentBlock: () => import(/* webpackChunkName: "DepartmentBlock" */
      '~/cms/DepartmentBlockCms.vue'),
    MarketingCardContainer: () =>
      import('~/cms/marketingCard/MarketingCardContainer.vue'),
    MarketingCard: () => import(/* webpackChunkName: "MarketingCard" */
      '~/cms/marketingCard/MarketingCard.vue'),
    MarketingCardWide: () => import(/* webpackChunkName: "MarketingCardWide" */
      '~/cms/marketingCard/MarketingCardWide.vue'),
    MarketingLandingPageConfig: () => import(/* webpackChunkName: "MarketingLandingPageConfig" */
      '~/cms/MarketingLandingPageConfig/MarketingLandingPageConfig.vue'),
    CategoryBoxesLinks: () => import(/* webpackChunkName: "CategoryBoxesLinks" */
      '~/cms/CategoryBoxesLinks/CategoryBoxesLinks.vue'),
    AppFooterWrapper: () => import(/* webpackChunkName: "AppFooterWrapper" */
      '~/cms/footer/AppFooterWrapper.vue'),
    FooterLinkColumn: () => import(/* webpackChunkName: "FooterLinkColumn" */
      '~/cms/footer/FooterLinkColumn.vue'),
    NotificationBannerCms: () => import(/* webpackChunkName: "NotificationBannerCms" */
      '~/cms/NotificationBannerCms/index.vue'),
    VideoBanner: () => import(/* webpackChunkName: "VideoBanner" */
      '~/cms/VideoBanner/VideoBanner.vue'),
    PromoBanners: () => import(/* webpackChunkName: "PromoBanners" */
      '~/cms/PromoBanners/PromoBanners.vue'),
    BlogPosts: () => import(/* webpackChunkName: "BlogPosts" */
      '~/cms/BlogPosts/BlogPosts.vue'),
    BlogPostsWithBlogLink: () => import(/* webpackChunkName: "BlogPostsWithBlogLink" */
      '~/cms/BlogPosts/BlogPostsWithBlogLink.vue'),
    CampaignCountdownCms: () => import(/* webpackChunkName: "CampaignCountdownCms" */
      '~/cms/CampaignCountdownCms/CampaignCountdownCms.vue'),
    BannerBox: () => import(/* webpackChunkName: "BannerBox" */
      '~/cms/BannerBox/BannerBox.vue'),
    BannerBoxes: () => import(/* webpackChunkName: "BannerBoxes" */
      '~/cms/BannerBoxes/BannerBoxes.vue'),
    StrapWithLink: () => import(/* webpackChunkName: "StrapWithLink" */
      '~/cms/StrapWithLink.vue'),
    UspSection: () => import(/* webpackChunkName: "UspSection" */
      '~/cms/UspSection/UspSection.vue'),
    FaqSectionCms: () => import(/* webpackChunkName: "FaqSectionCms" */
      '~/cms/FaqSectionCms/FaqSectionCms.vue'),
    NewsletterCms: () => import(/* webpackChunkName: "NewsletterCms" */
      '~/cms/NewsletterCms/NewsletterCms.vue'),
    TrustPilotCarouselCms: () => import(/* webpackChunkName: "TrustPilotCarouselCms" */
      '~/cms/TrustPilotCarouselCms/TrustPilotCarouselCms.vue'),
    PageTitleCms: () => import(/* webpackChunkName: "PageTitleCms" */
      '~/cms/PageTitleCms/PageTitleCms.vue'),
    CampaignBanner: () => import(/* webpackChunkName: "CampaignBanner" */
      '~/cms/CampaignBanner/CampaignBanner.vue'),
    ProductGroupsSlider: () => import(/* webpackChunkName: "ProductGroupsSlider" */
      '~/cms/ProductGroupsSlider/ProductGroupsSlider.vue'),
    PromoListCms: () => import(/* webpackChunkName: "PromoListCms" */
      '~/cms/PromoListCms.vue'),
    HeroBannerCms: () => import(/* webpackChunkName: "HeroBannerCms" */
      '~/cms/HeroBannerCms.vue'),
    ProductListingCms: () => import(/* webpackChunkName: "ProductListingCms" */
      '~/cms/ProductListingCms/ProductListingCms.vue')
  },
  props: {
    content: {
      type: [Array, Object],
      default: () => {
        return [];
      }
    },
    isComponentsOverviewPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    components(): ExtractedComponent[] {
      return typeof this.content !== 'string'
        ? extractComponents(this.content)
        : [];
    }
  }
});
