import { VolumeDiscountsState } from './VolumeDiscounts';
import { ProductExtraGuaranteeState } from '~/types/vuex/ProductExtraGuarantee';
import { NavigationState } from '~/types/vuex/Navigation';
import { ClientIPState } from '~/types/vuex/ClientIP';
import { ClientEmailState } from '~/types/vuex/ClientEmail';
import { AnchorNavState } from '~/types/vuex/AnchorNav';
import { CartTrackingState } from '~/types/vuex/CartTracking';
import { ComplementaryProductsState } from '~/types/vuex/ComplementaryProducts';
import { AdyenErrorState } from '~/types/vuex/AdyenError';
import { CartSidebarState } from '~/types/vuex/CartSidebar';

export const MODULE_NAMES = {
  EXTRA_GUARANTEE_MODULE_NAME: 'productExtraGuarantee',
  NAVIGATION_MODULE_NAME: 'navigation',
  CLIENT_IP_MODULE_NAME: 'clientIP',
  CLIENT_EMAIL_MODULE_NAME: 'clientEmail',
  ADYEN_ERROR: 'adyenError',
  ANCHOR_NAV_MODULE_NAME: 'anchorNav',
  CART_TRACKING_MODULE_NAME: 'cartTracking',
  COMPLEMENTARY_PRODUCTS_MODULE_NAME: 'complementaryProducts',
  VOLUME_DISCOUNTS_MODULE_NAME: 'volumeDiscounts',
  CART_SIDE_BAR_MODULE_NAME: 'cartSidebar'
} as const;

export type RootState = {
  [MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME]: ProductExtraGuaranteeState
  [MODULE_NAMES.NAVIGATION_MODULE_NAME]: NavigationState,
  [MODULE_NAMES.CLIENT_IP_MODULE_NAME]: ClientIPState,
  [MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME]: ClientEmailState,
  [MODULE_NAMES.ADYEN_ERROR]: AdyenErrorState,
  [MODULE_NAMES.ANCHOR_NAV_MODULE_NAME]: AnchorNavState,
  [MODULE_NAMES.CART_TRACKING_MODULE_NAME]: CartTrackingState,
  [MODULE_NAMES.COMPLEMENTARY_PRODUCTS_MODULE_NAME]: ComplementaryProductsState
  [MODULE_NAMES.VOLUME_DISCOUNTS_MODULE_NAME]: VolumeDiscountsState,
  [MODULE_NAMES.CART_SIDE_BAR_MODULE_NAME]: CartSidebarState
};

export interface ExtendedState {
  getterKeys?: string[],
  mutationKeys?: string[],
  actionKeys?: string[]
}
