

























































import {
  defineComponent
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { SimpleCarouselArrow } from '~/types/components/SimpleCarousel/SimpleCarouselArrows';
import {
  useNavigationState
} from '~/composables';
import { CATEGORIES_KEYS_TO_ICONS } from '~/constants/categoriesKeysToIcons';
import NavMainBarOnSale from '~/components/molecules/Navigation/NavMainBar/NavMainBarOnSale/NavMainBarOnSale.vue';
import NavMainBarAllCategories from '~/components/molecules/Navigation/NavMainBar/NavMainBarAllCategories/NavMainBarAllCategories.vue';
import NavMainBarCategory from '~/components/molecules/Navigation/NavMainBar/NavMainBarCategory/NavMainBarCategory.vue';

export default defineComponent({
  name: 'NavMainBar',
  components: {
    SimpleCarousel,
    NavMainBarOnSale,
    NavMainBarAllCategories,
    NavMainBarCategory
  },
  props: {
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    }
  },
  setup() {
    const {
      toggleNavigation,
      isNavigationOpen,
      setFirstLevelCategory,
      currentFirstLevelCategorySlug
    } = useNavigationState();

    const secondHandCategoryKey = 'ma_exde_36';

    const arrowClass = {
      container: `absolute bg-EXPONDO-primary-10 h-11 w-10 z-1 -top-2 lg:-top-1.5
      flex items-center justify-center cursor-pointer`,
      arrow: 'text-EXPONDO-primary text-xl'
    };

    const getIconFromKey = (categoryKey: string) => {
      return CATEGORIES_KEYS_TO_ICONS[categoryKey];
    };

    const itemClick = (slug:string) => {
      if (!isNavigationOpen.value || slug === currentFirstLevelCategorySlug.value) {
        toggleNavigation();
      }
      setFirstLevelCategory(slug);
    };

    return {
      toggleNavigation,
      isNavigationOpen,
      setFirstLevelCategory,
      itemClick,
      SimpleCarouselArrow,
      arrowClass,
      getIconFromKey,
      secondHandCategoryKey
    };
  }
});
