/**
 * @param {string} date
 * @returns Date DD.MM.YYYY or undefined if date is invalid
 */
export const dateToDDMMYYYY = (date: string): string | undefined => {
  try {
    const dateObj = new Date(date);
    if (!(dateObj instanceof Date && !isNaN(dateObj.getTime()))) {
      throw new TypeError('invalid date');
    }
    const padWithLeadingZero = (number: number): string => {
      return ('0' + number).slice(-2);
    };

    const month = padWithLeadingZero(dateObj.getMonth() + 1);
    const day = padWithLeadingZero(dateObj.getDate());
    const year = dateObj.getFullYear();
    const shortDate = `${day}.${month}.${year}`;
    return shortDate;
  } catch (e) {
    return undefined;
  }
};
