import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { registerLogger } from '@vue-storefront/core';
import { StringBoolean } from '~/types/StringBoolean';
import { datadogClientToken } from '~/config';

export default () => {
  if (process.env.isDev === StringBoolean.false) {
    datadogRum.init({
      applicationId: '8283985b-90ce-4af3-8ffa-b0e14d303e7d',
      clientToken: datadogClientToken,
      site: 'datadoghq.eu',
      service: 'vsf-client',
      env: window?.location?.host ?? 'nuxt',

      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0'; sampleRate is % of traffic
      sessionSampleRate: process.env.DISABLE_MONITORING ? 0 : 5,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true
    });

    datadogLogs.init({
      clientToken: datadogClientToken,
      site: 'datadoghq.eu',
      service: 'vsf-browser-logs',
      env: window?.location?.host ?? 'default-env',
      forwardConsoleLogs: ['warn', 'error'],
      forwardErrorsToLogs: true,
      sessionSampleRate: 100
    });

    const ddLogger = {
      debug: (message: string, additionalParams: object) => datadogLogs.logger.debug(message, additionalParams),
      info: (message: string, additionalParams: object) => datadogLogs.logger.info(message, additionalParams),
      warn: (message: string, additionalParams: object) => datadogLogs.logger.warn(message, additionalParams),
      error: (message: string, additionalParams: object) => datadogLogs.logger.error(message, additionalParams)
    };

    const logsVerbosity = 'info';

    registerLogger(ddLogger, logsVerbosity);
  }
};
