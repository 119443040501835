import { reactive, computed } from '@nuxtjs/composition-api';
import {
  useStoreVsfExtended
} from '~/composables';

const state = reactive({
  isWishlistSidebarOpen: false,
  isFilterModalOpen: false,
  isExtraGuaranteeOpen: false,
  isComplementarySidebarOpen: false
});

const useUiState = () => {
  const { isComplementarySidebarEnabled } = useStoreVsfExtended();

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const isFilterModalOpen = computed(() => state.isFilterModalOpen);
  const isExtraGuaranteeOpen = computed(() => state.isExtraGuaranteeOpen);
  const isComplementarySidebarOpen = computed(() =>
    state.isComplementarySidebarOpen && isComplementarySidebarEnabled.value);

  const toggleWishlistSidebar = () => {
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleFilterModal = () => {
    state.isFilterModalOpen = !state.isFilterModalOpen;
  };

  const toggleComplementarySidebar = () => {
    state.isComplementarySidebarOpen = !state.isComplementarySidebarOpen;
  };

  const toggleExtraGuarantee = () => {
    state.isExtraGuaranteeOpen = !state.isExtraGuaranteeOpen;
  };

  return {
    isWishlistSidebarOpen,
    isFilterModalOpen,
    toggleWishlistSidebar,
    toggleFilterModal,
    isExtraGuaranteeOpen,
    toggleExtraGuarantee,
    isComplementarySidebarOpen,
    toggleComplementarySidebar
  };
};

export default useUiState;
