import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  NavigationMutations
} from '~/types/vuex/Navigation';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.CLIENT_IP_MODULE_NAME,
    NavigationMutations,
    {},
    {}
  >(MODULE_NAMES.CLIENT_IP_MODULE_NAME);

  const clientIP = computed(() => store.state.clientIP);

  return {
    clientIP,
    ...store.getters,
    ...store.mutations,
    ...store.actions
  };
}
