








import { defineComponent } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import RenderContent from '~/cms/RenderContent.vue';
import { useInternalCms } from '~/composables';
import { COMPONENTS } from '~/constants/cms';

export default defineComponent({
  name: 'PromoBannerContainer',
  components: {
    RenderContent
  },
  setup() {
    const {
      loadComponent: promoBannersSearch,
      internalCmsContent: promoBannersContent,
      canShowContent: canShowPromoBanners
    } = useInternalCms(COMPONENTS.PROMO_BANNERS.SLUG);

    onSSR(async () => {
      await promoBannersSearch();
    });

    return {
      promoBannersContent,
      canShowPromoBanners
    };
  }
});
