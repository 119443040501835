import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { RemarketingTags } from '~/plugins/integrations/tagManager/helpers/RemarketingTags';
import { prepareRemarketingTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { RemarketingPageType } from '~/types/integrations/tagManager/RemarketingPageType';

export const triggerRemarketingTagsWrapper = (
  $gtm: Gtm
) => {
  return (purpose: RemarketingPageType, product: ProductVariant) => {
    const remarketingTags = new RemarketingTags();
    const tagsForRemarketing = prepareRemarketingTags(
      purpose,
      product
    );
    remarketingTags.assignRemarketingValues(tagsForRemarketing);
    $gtm.push(remarketingTags);
  };
};
