import { useVSFContext } from '@vue-storefront/core';
import { usePrefix } from '../usePrefix';
import { ROUTES } from '~/constants/routes';
import { getSlugForRoute } from '~/helpers/routes/getPageInformation';
import { getPrefix, getPrefixedSlug } from '~/helpers/routes/prefixes';

export const useRouteExtended = () => {
  const { i18n, route: currentRoute } = useVSFContext();
  const { addPrefixToSlugIfNecessary } = usePrefix();

  const replaceRouteWildCard = (route: string, replacement: string) => {
    return route.replace('*', replacement);
  };

  const isCurrentRoute = (slug: Maybe<string>): boolean => {
    const prefix = getPrefix(currentRoute.value.fullPath);

    if (prefix) {
      slug = getPrefixedSlug(slug, prefix);
    }

    return slug === currentRoute.value.path;
  };

  const buildSearchPageUrl = (category: string): string => {
    return replaceRouteWildCard(getAdjustedSlug(ROUTES.SEARCH_CATEGORY), category);
  };

  const getAdjustedSlug = (routePath: string): string => {
    const slug = getSlugForRoute(routePath);
    const slugTranslatedIfNecessary = getTranslatedSlug(slug);
    const slugPrefixedIfNecessary = addPrefixToSlugIfNecessary(slugTranslatedIfNecessary) || slugTranslatedIfNecessary;
    return slugPrefixedIfNecessary;
  };

  const getTranslatedSlug = (slug: string): string => {
    if (i18n?.te(slug)) {
      return i18n?.tc(slug);
    }
    return slug;
  };

  return {
    buildSearchPageUrl,
    isCurrentRoute,
    currentRoute,
    getAdjustedSlug
  };
};
