export default function ({ store, req }) {
  if (!process.server) {
    return;
  }

  const cfConnectingIp = req.headers['cf-connecting-ip'];
  const xForwardedFor = req.headers['x-forwarded-for'];

  const getClientIp = () => {
    // Check for Cloudflare's header first
    if (cfConnectingIp) {
      return cfConnectingIp;
    }
    // Check for standard proxy headers
    if (xForwardedFor) {
      return xForwardedFor.split(',')[0];
    }
    // Fallback to connection remote address
    return req.connection.remoteAddress;
  };
  store.commit('clientIP/setClientIP', getClientIp());
}
