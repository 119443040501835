const { getHostname } = require('../host/getHostname');
const { VUESTOREFRONT_COOKIE, COOKIE_SEPARATOR } = require('../../constants/cookies');
const { QUERY_PARAMETER_EQUALS } = require('../../constants/routes');

function getHost (request) {
  const host = request ? getHostname(request) : window.location.host;
  if (!host) {
    console.info(`Country is undefined, for host: ${host}`);
  }
  return host;
}

function isHostIp (host) {
  return host
    ?.split(':')
    .shift()
    .split('.')
    .filter(octet => parseInt(octet) >= 0 && parseInt(octet) <= 255)?.length === 4;
}

function getProtocol (request) {
  return (
    request ? request.headers['x-forwarded-proto'] || request.protocol || 'https' : window?.location?.protocol
  )
    ?.replace(':', '')
    ?.replace('//', '');
}

function getHttpInfo (req, country) {
  const PROTOCOL = getProtocol(req);

  const HOST = getHost(req, country);

  const BASE_URL = `${PROTOCOL}://${HOST}`;

  const IS_HOST_IP = isHostIp(HOST);

  return {
    PROTOCOL,
    HOST,
    IS_HOST_IP,
    BASE_URL
  };
}

function getLocaleAndCountryFromCookies(request) {
  const cookies = request?.headers?.cookie ?? '';
  const cookieElements = cookies.split(COOKIE_SEPARATOR);
  const vsfLocaleCookie = cookieElements.find(cookie => cookie.startsWith(VUESTOREFRONT_COOKIE.LOCALE));
  const languageAndCountry = vsfLocaleCookie?.split(QUERY_PARAMETER_EQUALS)?.[1];
  return languageAndCountry;
}

module.exports = {
  getHttpInfo,
  getLocaleAndCountryFromCookies
};
