import { ref } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { AddressComponent, AddressValidationResult, Verdict } from '~/types/GoogleValidate';
import { isGoogleAddressValidationEnabled } from '~/helpers/Checkout/googleAddressHelpers';
import { useI18n } from '~/composables';

export default () => {
  const loading = ref(false);
  const error = ref<{} | null>(null);
  const apiKey = process.env.googleAddressValidationKey;
  const apiUrl = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`;
  const formattedAddress = sharedRef('', 'formattedAddress');
  const responseData = ref<Partial<AddressValidationResult>>({});
  const isValidatedOnce = ref(false);
  const { countryCode } = useI18n();

  const addressToValidateBody = (address: Partial<Address>) => {
    const {
      country,
      city,
      streetName,
      postalCode
    } = address;
    return JSON.stringify({
      address: {
        regionCode: country,
        addressLines: [city, `${streetName}`, postalCode]
      }
    });
  };

  const validateAddresses = (responseData: Partial<AddressValidationResult>) => {
    return responseData.address?.addressComponents?.every(
      ({ spellCorrected, replaced, unexpected }: AddressComponent) => {
        return !spellCorrected && !replaced && !unexpected;
      });
  };
  const hasInvalidVerdict = (verdict: Maybe<Partial<Verdict>>) =>
    !verdict?.addressComplete || verdict?.hasReplacedComponents ||
    verdict?.hasUnconfirmedComponents;
  const apiValidateAddress = async (address: Partial<Address>) => {
    const response = await fetch(apiUrl, {
      method: 'POST',
      body: addressToValidateBody(address)
    });
    const data = await response.json();
    responseData.value = data?.result;
  };
  const validateAddress = async (address: Partial<Address>) => {
    error.value = null;
    if (isValidatedOnce.value || !isGoogleAddressValidationEnabled(countryCode.value)) {
      return;
    }
    await apiValidateAddress(address);
    formattedAddress.value = responseData.value?.address?.formattedAddress ?? '';
    const verdict = responseData.value?.verdict;
    if (hasInvalidVerdict(verdict)) {
      error.value = { verdict: 'Error on verdict' };
    } else if (!validateAddresses(responseData.value)) {
      error.value = { address: 'Error on address' };
    }
    isValidatedOnce.value = true;
  };

  return {
    validateAddress,
    formattedAddress,
    loading,
    error
  };
};
