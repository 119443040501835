import type { RawCustomField } from '@vsf-enterprise/commercetools-types';
import {
  BANK_PAYMENT_BIC,
  BANK_PAYMENT_ACCOUNT_OWNER,
  BANK_PAYMENT_INSTITUTE,
  BANK_PAYMENT_IBAN
} from '~/constants/checkout';
import { BankInfo } from '~/types/checkout/BankInfo';

export type RawCustomFields = Maybe<Array<Partial<RawCustomField>>>
// TODO needed on CT v2
// export type RawCustomFields = DeepPartial<Maybe<Array<RawCustomField>>>

export default function (customFieldsRaw: RawCustomFields): Maybe<BankInfo> {
  if (!customFieldsRaw) return;
  const bankBic: string = customFieldsRaw.find(value =>
    value?.name === BANK_PAYMENT_BIC)?.value;

  const bankAccountOwner: string = customFieldsRaw.find(value =>
    value?.name === BANK_PAYMENT_ACCOUNT_OWNER)?.value;

  const bankInstitute: string = customFieldsRaw.find(value =>
    value?.name === BANK_PAYMENT_INSTITUTE)?.value;

  const bankIban: string = customFieldsRaw.find(value =>
    value?.name === BANK_PAYMENT_IBAN)?.value;

  return {
    bankBic,
    bankAccountOwner,
    bankInstitute,
    bankIban
  };
}
