import { AxiosResponse } from 'axios';
import { CustomerUpdateRequest } from '~/types/integrations/cia/event/customer-update/CustomerUpdateRequest';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { locale, posthogDistinctId } = instance.state;

  // send customer_update event to Bloomreach microservice - designed to run it on both ssr and csr
  return (userId: string): Promise<AxiosResponse>|undefined => {
    if (!userId) {
      return;
    }

    const payload: CustomerUpdateRequest = {
      locale: locale.value,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      user_id: userId,
      posthog_identifier: posthogDistinctId.value
    };

    return axios.post('customer-update', payload);
  };
};
