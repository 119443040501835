import { useVSFContext } from '@vue-storefront/core';
import { ApiResponse } from '~/types/composables/SeoRedirection';
import {
  SECURE_WEBSITE_PROTOCOLE_PREFIX,
  STAGE_PREFIX
} from '~/constants/http';
import { DEFAULT_REDIRECTION_CODE } from '~/constants/seo';
import { DOMAIN_IE } from '~/constants/domains';
import config from '~/config';
import { i18nToCurrentDomain } from '~/helpers/locales/i18nCurrentDetails';
import { isLocal } from '~/helpers/env/checkEnvironmentType';

/**
 * Composable for the SEO redirection
 */
export const useSeoRedirection = () => {
  const { route, i18n, redirect, $axios } = useVSFContext();

  const seoRedirection = async () => {
    let redirectHappens = false;
    try {
      if (!process?.server) {
        return;
      }
      const domain = i18nToCurrentDomain(i18n);
      const developmentDomain = `${STAGE_PREFIX}.${DOMAIN_IE}`;
      const currentPath = route.value.path;
      const currentRootDomain = isLocal() ? developmentDomain : domain;
      const sourceUrl = `${SECURE_WEBSITE_PROTOCOLE_PREFIX}${currentRootDomain}${currentPath}`;
      const apiUrl = `${config.seoRedirectionServerMiddlewarePath}?sourceUrl=${sourceUrl}`;
      const res: ApiResponse = await $axios.get(apiUrl);
      const redirectUrl = res.data?.targetURL;
      if (redirectUrl) {
        redirectHappens = true;
        redirect(res.data.redirectionCode || DEFAULT_REDIRECTION_CODE, redirectUrl);
      }
    } catch (_) {
      // expected situation where no redirection exists
    }
    return redirectHappens;
  };

  return {
    seoRedirection
  };
};
