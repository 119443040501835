


























import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useNavigationState } from '~/composables';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import getCategoryIcon from '~/helpers/category/getCategoryIcon';

export default defineComponent({
  name: 'NavMainBarCategory',
  components: {
    ImgView
  },
  props: {
    currentItem: {
      type: Object as PropType<Category>,
      default: () => {
        return {};
      }
    }
  },
  setup() {
    const {
      toggleNavigation,
      isNavigationOpen,
      setFirstLevelCategory,
      currentFirstLevelCategorySlug
    } = useNavigationState();

    const secondHandCategoryKey = 'ma_exde_36';

    const itemClick = (slug:string) => {
      if (!isNavigationOpen.value || slug === currentFirstLevelCategorySlug.value) {
        toggleNavigation();
      }
      setFirstLevelCategory(slug);
    };

    return {
      itemClick,
      getCategoryIcon,
      secondHandCategoryKey
    };
  }
});
