import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { VatIntegration } from '~/types/integrations/vatValidation/VatIntegration';
import validate from '~/plugins/integrations/vatValidation/request/validate';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';

export default (
  $axios: NuxtAxiosInstance
) => {
  const axios = $axios.create(
    getAxiosConfig((process?.server ? $axios.defaults.baseURL : '/') + 'vat')
  );

  setAxiosInterceptors({ axios, shouldRejectPromise: true });

  const $vat = {
    services: {
      axios
    }
  } as VatIntegration;
  $vat.validate = validate($vat);
  return $vat;
};
