import { getPageLinkForSlug } from '../routes/getPageInformation';
import { CanonicalParameters } from '~/helpers/seo/types/CanonicalParameters';
import { SECURE_WEBSITE_PROTOCOLE_PREFIX } from '~/constants/http';
import { CANONICAL_RELATION } from '~/constants/seo';

export default ({
  domain,
  slug,
  prefix,
  pageNumber
}: {
   domain: string,
   slug: string,
   prefix: string | undefined,
   pageNumber?: number }): CanonicalParameters => {
  const adjustedSlug = slug ? getPageLinkForSlug(slug) : '/';
  let href = `${SECURE_WEBSITE_PROTOCOLE_PREFIX}${domain}${adjustedSlug}`;
  if (prefix) {
    href = `${SECURE_WEBSITE_PROTOCOLE_PREFIX}${domain}/${prefix}${adjustedSlug}`;
  }
  if (pageNumber && pageNumber > 1) {
    href = `${href}?page=${pageNumber}`;
  }
  return {
    rel: CANONICAL_RELATION,
    href
  };
};
