

















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useNavigationState } from '~/composables';

export default defineComponent({
  name: 'NavMainBarAllCategories',
  props: {
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    }
  },
  setup() {
    const {
      toggleNavigation,
      isNavigationOpen
    } = useNavigationState();

    return {
      toggleNavigation,
      isNavigationOpen
    };
  }
});
