import type { HitsPerPage, SortingConfiguration, SearchFacet } from 'vue-instantsearch';
import { MAX_POINTS_FOR_RATINGS } from './reviews';

export const CATEGORIES_ATTRIBUTES: string[] = [
  'categories.lvl0',
  'categories.lvl1',
  'categories.lvl2',
  'categories.lvl3',
  'categories.lvl4'
];

export const ALGOLIA_ROOT_LEVEL_QUERY_PARAMETERS = [
  'hierarchicalMenu',
  'query',
  'refinementList',
  'sortBy',
  'range',
  'ratingMenu',
  'page',
  'hitsPerPage'
];

export const ALGOLIA_ATTRIBUTE = {
  TAGS: 'tags',
  AVG_RATING: 'avgRating',
  BRAND: 'brand',
  PRICE: 'price'
} as const;

export const QUERY_KEY = {
  HITS_PER_PAGE: 'hitsPerPage',
  BRAND_FILTER: `refinementList[${ALGOLIA_ATTRIBUTE.BRAND}][0]`,
  RATING_FILTER: `ratingMenu[${ALGOLIA_ATTRIBUTE.AVG_RATING}]`,
  PAGE: 'page'
} as const;

export const TOP_STATIC_FACETS: SearchFacet[] = [
  {
    attribute: ALGOLIA_ATTRIBUTE.TAGS,
    hideTitle: true
  },
  {
    attribute: ALGOLIA_ATTRIBUTE.PRICE
  },
  {
    attribute: ALGOLIA_ATTRIBUTE.AVG_RATING
  },
  {
    attribute: ALGOLIA_ATTRIBUTE.BRAND
  }
];

// TODO uncomment * and remove fixed list after implementing proper view for facets (color picker, price slider, etc.)
// export const AVAILABLE_FACETS = ['*'];
export const AVAILABLE_FACETS: string[] = [ // use fixed list of facets (INSP-270)
  ALGOLIA_ATTRIBUTE.PRICE,
  ALGOLIA_ATTRIBUTE.AVG_RATING,
  ALGOLIA_ATTRIBUTE.BRAND,
  ALGOLIA_ATTRIBUTE.TAGS, // aka labels
  ...CATEGORIES_ATTRIBUTES
];

export const MAX_FACETS: number = 30;

// MAX_STARS is one above the maximum rating, because in Algolia this value is exclusive,
// which means the number of stars will be the provided value, minus one.
export const MAX_STARS: number = MAX_POINTS_FOR_RATINGS + 1;

export const MAX_PAGINATION = 1000000;
export const MIN_PAGINATION = 0; // Algolia internally uses 0 for some requests even though 0th page generates error

export const RATING_TO_IGNORE: string = '2';

export const EXCLUDED_FACETS: string[] = [
  ...CATEGORIES_ATTRIBUTES,
  ...TOP_STATIC_FACETS.map(facetObject => facetObject.attribute)
];

export const DEFAULT_QUERY_VALUES = {
  [QUERY_KEY.HITS_PER_PAGE]: 24,
  [QUERY_KEY.BRAND_FILTER]: '',
  [QUERY_KEY.RATING_FILTER]: undefined,
  [QUERY_KEY.PAGE]: 1
} as const;

export const ITEM_COUNT_FOR_QUICK_SEARCH: number = 5;
export const HITS_PER_PAGE_ITEMS: HitsPerPage[] = [
  {
    value: DEFAULT_QUERY_VALUES[QUERY_KEY.HITS_PER_PAGE],
    label: DEFAULT_QUERY_VALUES[QUERY_KEY.HITS_PER_PAGE].toString(),
    default: true
  },
  { value: 12, label: '12' },
  { value: 36, label: '36' },
  { value: 48, label: '48' }
];

// Structure: { value: 'suffix of search index replica', label: 'translation key' }
export const SORTING_METHODS: SortingConfiguration[] = [
  { value: '', label: 'sortingRelevance' }, // default one
  { value: 'nameAsc', label: 'sortingNameAsc' },
  { value: 'nameDesc', label: 'sortingNameDesc' },
  { value: 'priceAsc', label: 'sortingPriceAsc' },
  { value: 'priceDesc', label: 'sortingPriceDesc' },
  { value: 'newest', label: 'sortingNewest' }
];

export const CATEGORY_PATHS: Record<string, string> = {
  FACTORY_SECONDS: 'Factory Seconds'
};

export const INDEX_ENVIRONMENT_PREFIX = {
  PRODUCTION: 'prod',
  STAGE: 'stage'
};

export const CATEGORY_FILTER_SEPARATOR = ': ';
export const CATEGORY_FILTER_INDICATOR = ' > ';

export const ALGOLIA_PROD_INDEX_START = 'prod.';
export const ALGOLIA_STAGE_INDEX_START = 'stage.';

export const ALGOLIA_GLOBAL_STATE_NAME = {
  ALGOLIA_STATE: 'algoliaState',
  ALGOLIA_STATE_SEARCHBAR: 'algoliaStateSearchBar'
} as const;

export type AlgoliaGlobalStateName = ValueOf<typeof ALGOLIA_GLOBAL_STATE_NAME>;

export const ALGOLIA_LOADING_STATE = 'loading';
// stalled: The search is in progress, but the response is taking longer than expected.
export const ALGOLIA_STALLED_STATE = 'stalled';

export const BOOLEAN_ATTRIBUTE_STATE = {
  INCLUSIVE: 'INCLUSIVE',
  EXCLUSIVE: 'EXCLUSIVE'
} as const;

export const TRANSLATION_LENGTH = {
  SHORT: 'SHORT',
  LONG: 'LONG'
} as const;

export const BOOLEAN_ATTRIBUTE_LABELS = {
  [BOOLEAN_ATTRIBUTE_STATE.INCLUSIVE]: {
    [TRANSLATION_LENGTH.SHORT]: 'Show results with this criteria',
    [TRANSLATION_LENGTH.LONG]: 'Showing results with attribute'
  },
  [BOOLEAN_ATTRIBUTE_STATE.EXCLUSIVE]: {
    [TRANSLATION_LENGTH.SHORT]: 'Show results without this criteria',
    [TRANSLATION_LENGTH.LONG]: 'Showing results without attribute'
  }
};

export const TRANSLATABLE_CURRENT_REFINEMENTS = [
  'new', 'bestseller', 'bware', 'on-sale', 'topRated'
];
