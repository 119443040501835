import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import { PDP_NAV_ANCHORS } from '~/constants/pdpNavAnchors';
import { AnchorNavState } from '~/types/vuex/AnchorNav';

const state = (): AnchorNavState => ({
  activeSection: PDP_NAV_ANCHORS.PRODUCT_DESCRIPTION,
  stickyAnchorNav: null
});
const mutations = {
  setActiveSection(state: AnchorNavState, payload: AnchorNavState['activeSection']) {
    state.activeSection = payload;
  },
  setStickyAnchorNav(state: AnchorNavState, payload: AnchorNavState['stickyAnchorNav']) {
    state.stickyAnchorNav = payload;
  }
};

export default extendModule({
  state,
  mutations
});
