import { countryCodes } from '~/i18n/enums/countryCodes';
import { VAT_FORMAT } from '~/config/shared/countryConfig';

function removePrefix(str: string, prefix: string) {
  return str.toUpperCase().startsWith(prefix) ? str.slice(prefix.length) : str;
}

const formatVatNumberForLocale = (country: countryCodes, vatNumber: string): string => {
  let newVatNumber = removePrefix(vatNumber, getVatPrefix(country));
  const vatConfig = VAT_FORMAT[country];
  if (vatConfig?.additionalVatNumberProcessing) {
    newVatNumber = vatConfig.additionalVatNumberProcessing(newVatNumber);
  }
  return newVatNumber.replace(vatConfig?.rule ?? '', '');
};

export const getVatPrefix = (country: countryCodes) => VAT_FORMAT[country]?.prefix ?? '';

export default formatVatNumberForLocale;
