import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  CartTrackingMutations
} from '~/types/vuex/CartTracking';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { useCartExtended, useCheckoutTagManager, useIntegrations } from '~/composables';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.CART_TRACKING_MODULE_NAME,
    CartTrackingMutations,
    {},
    {}
  >(MODULE_NAMES.CART_TRACKING_MODULE_NAME);

  const { cart } = useCartExtended();
  const { $tagManager } = useIntegrations();
  const { products: productsTagManager, loadProducts } = useCheckoutTagManager();

  const beginCheckoutEventCartVersion = computed(() => store.state.beginCheckoutEventCartVersion);

  const triggerBeginCheckoutGTMEvent = async () => {
    await loadProducts();
    if (cart.value.version !== beginCheckoutEventCartVersion.value) {
      await $tagManager.events.triggerBeginCheckoutTags(cart.value, productsTagManager.value);
      setBeginCheckoutEventCartVersion(cart.value.version);
    }
  };

  const setBeginCheckoutEventCartVersion = (cartVersion: string) => {
    store.mutations.setBeginCheckoutEventCartVersion(cartVersion);
  };

  return {
    setBeginCheckoutEventCartVersion,
    beginCheckoutEventCartVersion,
    triggerBeginCheckoutGTMEvent
  };
}
