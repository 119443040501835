import { useReview } from '@vsf-enterprise/commercetools';
import type { ComputedRef, Ref } from '@nuxtjs/composition-api';
import { ref } from '@nuxtjs/composition-api';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import type { UpdatePaginationPageParams } from '~/types/pagination';
import { excludeDeactivatedReviews } from '~/helpers/commercetools/graphql/excludeDeactivatedReviews';
import { DEFAULT_REVIEWS_SEARCH_PARAMS } from '~/constants/pagination';
import getSortParamsFromString from '~/helpers/getSortParamsFromString';
import { DEFAULT_SORTING_OPTION } from '~/constants/reviewSortingOptions';

const sortParam = ref<string[]>(getSortParamsFromString(DEFAULT_SORTING_OPTION));

export const useReviewExtended = (productId: ComputedRef<string> | Ref<string>) => {
  const {
    reviews,
    search: searchReviews
  } = useReview('productReviews');

  const updateSortParams = (param:string[]) => {
    sortParam.value = param;
  };

  const search = async ({ limit, offset }: UpdatePaginationPageParams = DEFAULT_REVIEWS_SEARCH_PARAMS) => {
    await searchReviews({
      limit,
      offset,
      // @ts-ignore - https://vsf.atlassian.net/servicedesk/customer/portal/5/EXPONDO-106
      sort: sortParam.value,
      customQuery: CUSTOM_QUERIES.REVIEWS,
      where: excludeDeactivatedReviews(productId.value)
    });
  };

  return {
    reviews,
    updateSortParams,
    search
  };
};
