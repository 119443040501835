/**
 * @param { string } environmental
 * @param {import('@nuxt/types').Context | undefined} context
 * @returns { string }
 */
const getEnvironmental = (environmental, context = undefined) => {
  return process.env[environmental] ?? context?.env?.[environmental];
};

module.exports = {
  getEnvironmental
};
