import { Logger } from '@vue-storefront/core';
import { MAX_NUMBER_OF_RETRIES } from '~/constants/numbers';
import { errorToString } from '~/helpers/error/errorToString';

export default async (callback: () => unknown, afterEachError?: () => Promise<void>) => {
  let error = '';
  let data: ReturnType<typeof callback>;
  for (let i = 0; i <= MAX_NUMBER_OF_RETRIES; i++) {
    try {
      error = '';
      data = await callback();
      break;
    } catch (e: unknown) {
      error = errorToString(e);
      Logger.error(`callFunctionWithRetries - error after ${i + 1} retries: ${error}`);
      afterEachError && await afterEachError();
    }
  }
  return { error, data };
};
