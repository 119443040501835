export const CATEGORIES_KEYS_TO_ICONS: { [key: string]: string } = {
  ma_exde_6: 'gastronomy',
  ma_exde_8401: 'hospitality',
  ma_exde_39: 'diy',
  ma_exde_15358: 'cleaning',
  ma_exde_14153: 'agriculture',
  ma_exde_14074: 'office',
  ma_newer_measurement: 'measurement',
  ma_exde_10165: 'recreation',
  ma_exde_10833: 'beauty',
  ma_exde_10890: 'wellness',
  ma_exde_38: 'professional-tools',
  ma_exde_9833: 'home-and-garden'
} as const;

export const DEFAULT_CATEGORY_ICON_NAME = 'category';
