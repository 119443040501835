import posthog from 'posthog-js';
import Vue from 'vue';
import { VSF_COUNTRY_COOKIE } from '@vue-storefront/core';
import { countryToPostHogKey } from '~/helpers/locales/countryToPostHogKey';

export default function({ app: { router }, $cookies }, inject) {
  // Init PostHog
  const posthogKey = countryToPostHogKey($cookies.get(VSF_COUNTRY_COOKIE));
  if (!posthogKey) {
    return;
  }
  posthog.init(countryToPostHogKey($cookies.get(VSF_COUNTRY_COOKIE)), {
    api_host: 'https://app.posthog.com',
    capture_pageview: false,
    debug: false
  });

  inject('posthog', posthog);

  // Make sure that pageviews are captured with each route change
  router.afterEach(to => {
    Vue.nextTick(() => {
      posthog.capture('$pageview', {
        $current_url: to.fullPath
      });
    });
  });
}
