import { AxiosResponse } from 'axios';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { CartUpdateRequest } from '~/types/integrations/cia/event/cart-update/CartUpdateRequest';
import { CartUpdateAction } from '~/types/integrations/cia/event/cart-update/CartUpdateAction';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { email, cartId, locale, userId, posthogDistinctId } = instance.state;

  // send cart-update event to Bloomreach microservice - designed to run it on both ssr and csr
  return ({ action, sku, recommendationId }: { action: CartUpdateAction; sku: string; recommendationId?: string })
    : Promise<AxiosResponse> => {
    if (!sku || !cartId.value) {
      return;
    }
    const payload: CartUpdateRequest = {
      locale: locale.value,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      action,
      cart_id: cartId.value,
      sku,
      posthog_identifier: posthogDistinctId.value,
      recommendation_id: recommendationId
    };

    if (email.value) {
      payload.email = email.value;
    }

    if (userId.value) {
      payload.user_id = userId.value;
    }

    return axios.post('cart-update', payload);
  };
};
