
/**
* @param {object} request
* @returns {string}
*/
function getHostname(request) {
  return request.headers['x-forwarded-host'] ?? request.headers.host;
}

module.exports = {
  getHostname
};
