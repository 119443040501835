import { sharedRef } from '@vue-storefront/core';

export const usePromiseQueue = <T extends (...args: any[]) => Promise<any>>(func: T, cachingKey: string) => {
  const promise = sharedRef<Promise<ReturnType<T>|void>>(Promise.resolve(), cachingKey);
  const execute = (...args: Parameters<T>): Promise<ReturnType<T>> => {
    const currentPromise = promise.value;
    const newPromise = currentPromise.then(() => func(...args)).catch((e) => {
      promise.value = Promise.resolve();
      throw e;
    });
    promise.value = newPromise;
    return newPromise;
  };

  return {
    execute
  };
};
