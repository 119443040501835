"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (_context, inject) => {
    const mock = {
        route: {
            setCacheable() { },
            setUncacheable() { },
            addTags() { },
        },
        data: {
            set() { },
            get() {
                return Promise.resolve();
            },
        },
        groups: {
            add() { },
        },
    };
    inject('cache', mock);
};
