import { computed } from '@nuxtjs/composition-api';
import { ROUTES } from '~/constants/routes';
import { useAccountInternalPage } from '~/composables/useAccountInternalPage';
import { useLogoutPage } from '~/composables/useLogoutPage';
import { useExternalPage } from '~/composables/useExternalPage';
import { useMyOrders } from '~/composables/useMyOrders';
import { UseMyAccountPage } from '~/types/myaccount/UseMyAccountPage';

export const useMyAccount = () => {
  const { urlWithToken } = useMyOrders();
  const myProfile = useAccountInternalPage(ROUTES.MY_PROFILE, 'My profile');
  const myOrders = useExternalPage(urlWithToken, 'My orders');
  const shippingDetails = useAccountInternalPage(ROUTES.SHIPPING_DETAILS, 'Shipping details');
  const billingDetails = useAccountInternalPage(ROUTES.BILLING_DETAILS, 'Billing details');
  const myNewsletter = useAccountInternalPage(ROUTES.MY_NEWSLETTER, 'My newsletter');
  const logoutPage = useLogoutPage();
  const urlList = computed<ReturnType<UseMyAccountPage>[]>(() => {
    return [
      myProfile,
      myOrders,
      shippingDetails,
      billingDetails,
      myNewsletter,
      logoutPage
    ];
  });

  return {
    urlList
  };
};
