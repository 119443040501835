import Vue from 'vue';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive.js';
import LazyHydrate from 'vue-lazy-hydration';
import SimpleLink from '~/components/atoms/SimpleLink/SimpleLink';
import SkeletonLoader from '~/components/atoms/SkeletonLoader/SkeletonLoader';
import ClientDesktopOnly from '~/components/atoms/ClientDesktopOnly/ClientDesktopOnly.vue';
import ClientMobileOnly from '~/components/atoms/ClientMobileOnly/ClientMobileOnly.vue';

// add your new component on stubs of test-setup.js
Vue.component('SimpleLink', SimpleLink);
Vue.component('SkeletonLoader', SkeletonLoader);
Vue.component('LazyHydrate', LazyHydrate);
Vue.component('ClientDesktopOnly', ClientDesktopOnly);
Vue.component('ClientMobileOnly', ClientMobileOnly);

Vue.directive('click-outside', clickOutside);
