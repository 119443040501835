import { EVENT_VIEW_ACCESSORY } from '~/constants/googleTagManager';
import { ViewAccessoryItems, EcommerceViewAccessory } from '~/types/integrations/tagManager/TagViewAccessory';

export class ViewAccessoryTags {
  event = EVENT_VIEW_ACCESSORY
  ecommerce: EcommerceViewAccessory = {
    detail: {
      parent_product_id: '',
      items: {
        accessory_count: 0,
        accessory_ids: []
      }
    }
  }

  assignSourceParentId (parentProductId: string) {
    this.ecommerce.detail.parent_product_id = parentProductId;
  }

  assignAccessoryValues (viewAccessoryItems: ViewAccessoryItems) {
    this.ecommerce.detail.items = viewAccessoryItems;
  }
}
