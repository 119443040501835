













import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useLoadCalls } from '~/composables';
import { ROUTES } from '~/constants/routes';
import { getSlugForRoute } from '~/helpers/routes/getPageInformation';

export default defineComponent({
  name: 'BaseLayout',
  setup(_props, { root: { $route } }) {
    useLoadCalls();
    const layoutClass = computed(() => {
      if ([getSlugForRoute(ROUTES.CHECKOUT_ADDRESSES),
        getSlugForRoute(ROUTES.CHECKOUT_PAYMENT),
        getSlugForRoute(ROUTES.THANK_YOU)
      ].includes($route.fullPath)) {
        return 'container m-auto';
      } else {
        return 'full-width';
      }
    });

    return {
      layoutClass
    };
  }
});
