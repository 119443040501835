import type { LineItem } from '@vsf-enterprise/commercetools-types';
import { cartGetters, orderGetters } from '@vsf-enterprise/commercetools';
import { computed, ref, onMounted } from '@nuxtjs/composition-api';
import { DiscountedLineItemPriceForQuantity } from '@vsf-enterprise/commercetools-types/src/Schema';
import { LineItemPriceMode } from '@vsf-enterprise/commercetools-types';
import {
  useStock,
  useCartExtended,
  useExtraGuarantee,
  useI18n,
  useUserOrder,
  useIsPage,
  useVolumeDiscounts
} from '~/composables';
import VIRTUAL_PRODUCT_SKU from '~/constants/virtualProductSku';
import getStandardPrice from '~/helpers/getStandardPrice';
import type { LineItemExtended } from '~/types/product/LineItemExtended';
import { STANDARD_PRODUCT } from '~/constants/productType';
import { getTotalGrossPrice, centAmountToNormalPrice } from '~/helpers/cart/getTotalPricesModule';
import { getRawAttribute } from '~/helpers/product/getRawAttributeAsArray';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { DEFAULT_VOLUME_DISCOUNT_GROUP } from '~/constants/volumeDiscounts';

export default function () {
  const { cart } = useCartExtended();
  const {
    findProductStockBySku
  } = useStock('cart');

  const {
    order
  } = useUserOrder();

  const { load: loadVolumeDiscount, volumeDiscountGroups } = useVolumeDiscounts();

  const canLoadLineItems = ref(false);

  const setCanLoadLineItems = (value: boolean) => {
    canLoadLineItems.value = value;
  };

  const { languageAndCountry } = useI18n();
  const { isThankYouPage } = useIsPage();

  const {
    extraGuaranteeParentTitle,
    extraGuaranteeParentSku
  } = useExtraGuarantee();

  const virtualProductTitle = (product: LineItem) => {
    const parentSku = extraGuaranteeParentSku(product);
    return extraGuaranteeParentTitle(parentSku) +
      ` - ${cartGetters.getItemName(product) || orderGetters.getItemName(product)}`;
  };

  const getProductTotalPriceBeforeDiscount = (product: LineItem): number => {
    let totalPrice = 0;
    for (const discountedPricePerQuantity of product.discountedPricePerQuantity) {
      if (!discountedPricePerQuantity.discountedPrice) {
        continue;
      }
      totalPrice += (calculateDiscountedAmount(discountedPricePerQuantity) +
          discountedPricePerQuantity.discountedPrice.value.centAmount) *
          discountedPricePerQuantity.quantity;
    }
    return totalPrice === 0 ? getTotalGrossPrice(product) : centAmountToNormalPrice(totalPrice);
  };

  const calculateDiscountedAmount = (discountedPricePerQuantity: DiscountedLineItemPriceForQuantity): number => {
    let discountedAmount = 0;
    for (const includedDiscount of discountedPricePerQuantity.discountedPrice.includedDiscounts) {
      discountedAmount += includedDiscount.discountedAmount?.centAmount;
    }
    return discountedAmount;
  };

  const getPriceBeforeDiscounts = (product: LineItem, quantity: number): number => {
    const standardPrice = getStandardPrice(product, languageAndCountry.value);
    return product.priceMode !== LineItemPriceMode.ExternalPrice && standardPrice
      ? standardPrice
      : getProductTotalPriceBeforeDiscount(product) / quantity;
  };

  const orderProducts = computed<LineItemExtended[]>(() => {
    const orderItems = order.value ? orderGetters.getItems(order.value) : [];
    return orderItems.map((product) => ({
      ...product,
      image: cartGetters.getItemImage(product) || product?.variant?.images[0]?.url,
      title: product.variant?.sku === VIRTUAL_PRODUCT_SKU
        ? virtualProductTitle(product)
        : orderGetters.getItemName(product),
      qty: orderGetters.getItemQty(product),
      priceBeforeDiscounts: getPriceBeforeDiscounts(product, orderGetters.getItemQty(product)),
      unitPrice: getTotalGrossPrice(product) / orderGetters.getItemQty(product)
    }));
  });

  const getDiscountGroup = (product: LineItem) => {
    return getRawAttribute(product.variant, PRODUCT_ATTRIBUTES.VOLUME_DISCOUNT_GROUP) ||
    DEFAULT_VOLUME_DISCOUNT_GROUP;
  };
  const cartProducts = computed<LineItemExtended[]>(() => {
    const cartItems = cart.value ? cartGetters.getItems(cart.value) : [];
    return cartItems.map((product) => {
      const volumeDiscountGroup = getDiscountGroup(product);
      return {
        ...product,
        image: cartGetters.getItemImage(product) || product?.variant?.images[0]?.url,
        title: product.variant?.sku === VIRTUAL_PRODUCT_SKU
          ? virtualProductTitle(product)
          : cartGetters.getItemName(product),
        unitPrice: getTotalGrossPrice(product) / cartGetters.getItemQty(product),
        qty: cartGetters.getItemQty(product),
        priceBeforeDiscounts: getPriceBeforeDiscounts(product, cartGetters.getItemQty(product)),
        stock: product?.variant?.sku ? findProductStockBySku(product.variant?.sku) : 0,
        volumeDiscountsForGroup: volumeDiscountGroups.value[volumeDiscountGroup]
      };
    });
  });

  const lineItems = computed<LineItemExtended[]>(() => {
    if (!canLoadLineItems.value) return [];
    const sortedLineItems: LineItemExtended[] = [];
    const products = (order.value && isThankYouPage.value) ? orderProducts.value : cartProducts.value;
    products.forEach((product) => {
      const virtualProductOfStandardProduct = [...products].find((item) => {
        return product.variant?.sku === extraGuaranteeParentSku(item) &&
        product.productType?.key === STANDARD_PRODUCT;
      }

      );
      const standardWithoutVirtualProduct = [...products].find((item) => {
        const findPossibleParentSku = !![...products].find(it => item?.variant?.sku === extraGuaranteeParentSku(it));
        return (!findPossibleParentSku && item.variant?.sku === product?.variant?.sku &&
          item.productType?.key === STANDARD_PRODUCT);
      });
      if (virtualProductOfStandardProduct) {
        sortedLineItems.push(product, virtualProductOfStandardProduct);
      } else if (standardWithoutVirtualProduct) {
        sortedLineItems.push(product);
      }
    });
    return sortedLineItems;
  });

  const loadVolumeDiscounts = () => {
    lineItems.value.forEach(async (product) => {
      const volumeDiscountGroup = getDiscountGroup(product);
      await loadVolumeDiscount(volumeDiscountGroup);
    });
  };

  const initializeLineItems = () => {
    requestAnimationFrame(() => {
      setCanLoadLineItems(true);
    });
  };

  onMounted(() => {
    initializeLineItems();
  });

  return {
    lineItems,
    setCanLoadLineItems,
    canLoadLineItems,
    virtualProductTitle,
    loadVolumeDiscounts
  };
}
