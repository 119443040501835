import type { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { CountryCodeValue } from '~/types/localization/localizationHelperTypes';

export const getVatPercentage = (
  product: ProductVariantGql, countryCode: CountryCodeValue
): number | undefined => {
  const rates = product?.taxCategory?.rates;
  const rate = rates?.find((rate) => rate.country.toLowerCase() === countryCode)?.amount;

  if (!rate) {
    return;
  }
  return rate * 100;
};
