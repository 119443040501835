import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';

type HasTranslationConfig = {
  checkFallbacks: boolean
}

export const useTranslationCheck = () => {
  const { i18n } = useVSFContext();
  const hasTranslation = (translationKey: string, config: Maybe<HasTranslationConfig>): boolean => {
    if (config?.checkFallbacks) {
      return i18n.te(translationKey);
    } else {
      const currentLocale = i18n.language;
      const fullKey = `${currentLocale}.${translationKey}`;
      return i18n.te(fullKey);
    }
  };

  return {
    hasTranslation,
    locale: computed(() => i18n.language)
  };
};
