import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { productGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

/*
 * Custom composable for product technical details information
 */
export const useProductDetails = (product: ProductVariant) => {
  const { i18n } = useVSFContext();
  const productAttributes = computed(() => productGetters.getAttributes(product,
    [
      PRODUCT_ATTRIBUTES.NETTO_LENGTH,
      PRODUCT_ATTRIBUTES.NETTO_WIDTH,
      PRODUCT_ATTRIBUTES.NETTO_HEIGHT,
      PRODUCT_ATTRIBUTES.NETTO_WEIGHT,
      PRODUCT_ATTRIBUTES.BRUTTO_LENGTH,
      PRODUCT_ATTRIBUTES.BRUTTO_WIDTH,
      PRODUCT_ATTRIBUTES.BRUTTO_HEIGHT,
      PRODUCT_ATTRIBUTES.BRUTTO_WEIGHT
    ]
  ));

  const shippingWeight = computed(() => i18n.t('Weight Value', {
    weight: productAttributes.value?.bruttoWeight
  }));

  return {
    productAttributes,
    shippingWeight
  };
};

export default useProductDetails;
