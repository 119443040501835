import { computed, ref } from '@nuxtjs/composition-api';
import {
  PAGINATION_ACTIONS,
  PAGINATION_PAGES_TO_SHOW
} from '~/constants/pagination';
import type { PaginationActions } from '~/constants/pagination';
import type { UpdatePaginationPageParams } from '~/types/pagination';

const currentPage = ref<number>(1);

export const usePagination = (totalAmount: number, perPage: number) => {
  const totalPages = computed(() => Math.ceil(totalAmount / perPage));

  const halfPagesToShow = computed(() => Math.floor(PAGINATION_PAGES_TO_SHOW / 2));
  const initialStartPages = computed(() => Math.max(1, currentPage.value - halfPagesToShow.value));
  const endPage = computed(() => Math.min(totalPages.value, initialStartPages.value + PAGINATION_PAGES_TO_SHOW - 1));
  const startPage = computed(() => {
    if (endPage.value === totalPages.value) {
      return Math.max(1, totalPages.value - PAGINATION_PAGES_TO_SHOW + 1);
    }
    return initialStartPages.value;
  });

  const getChangePageParams = (action: PaginationActions, page: number = 1): UpdatePaginationPageParams => {
    const paginationActions = {
      [PAGINATION_ACTIONS.PREV]: currentPage.value - 1,
      [PAGINATION_ACTIONS.NEXT]: currentPage.value + 1,
      [PAGINATION_ACTIONS.FIRST]: 1,
      [PAGINATION_ACTIONS.LAST]: totalPages.value,
      [PAGINATION_ACTIONS.PAGE]: page
    };

    currentPage.value = paginationActions[action];
    const offset = (currentPage.value - 1) * perPage;

    return {
      offset,
      limit: perPage
    };
  };

  const getLoadMoreParams = (): UpdatePaginationPageParams => {
    currentPage.value = currentPage.value + 1;

    return {
      offset: 0,
      limit: currentPage.value * perPage
    };
  };

  const resetPaginationPage = () => {
    currentPage.value = 1;
  };

  return {
    currentPage,
    totalPages,
    getChangePageParams,
    getLoadMoreParams,
    resetPaginationPage,
    startPage,
    endPage
  };
};
