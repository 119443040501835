import { SearchQueryParameters } from '~/types/search/SearchQueryParameters';
import { isSearchQueryParameter } from '~/helpers/algolia/isSearchQueryParameter';

export default function (params: SearchQueryParameters): SearchQueryParameters {
  return Object.keys(params)
    .filter(key => isSearchQueryParameter(key))
    .reduce((accumulator: SearchQueryParameters, key) => {
      const value = params[key];
      if (value !== undefined) {
        accumulator[key] = value;
      }
      return accumulator;
    }, {});
}
