/* eslint-disable no-unused-vars */
export const LinkType = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Dark: 'Dark',
  NoUnderline: 'NoUnderline',
  NoStyle: 'NoStyle',
  InheritColor: 'InheritColor'
} as const;

// eslint-disable-next-line no-redeclare
export type LinkType = typeof LinkType[keyof typeof LinkType]
