













import { defineComponent } from '@nuxtjs/composition-api';
import SimpleCarouselDotNavigationItem from '~/components/molecules/SimpleCarousel/SimpleCarouselDotNavigationItem.vue';

export default defineComponent({
  name: 'SimpleCarouselDotNavigation',
  components: {
    SimpleCarouselDotNavigationItem
  },
  props: {
    dots: {
      type: Array,
      required: true
    },
    currentSlide: {
      type: Number,
      default: 0
    }
  },
  setup(_props, { emit }) {
    const dotClick = (index: number) => {
      emit('dotClicked', index);
    };

    return {
      dotClick
    };
  }
});
