import { EVENT_VIEW_HITS } from '~/constants/googleTagManager';

export class ViewHitsTags {
  event = EVENT_VIEW_HITS;
  userToken?: string;
  index: string;
  objectIDs: string[];
  constructor({ userToken, index, objectIDs }: {
    userToken?: string,
    index: string,
    objectIDs: string[]
  }) {
    this.userToken = userToken;
    this.index = index;
    this.objectIDs = objectIDs;
  }
}
