export const DEFAULT_REDIS_PORT = 6379;
export const FULL_DAY_IN_SECONDS = 86400;
export const FULL_MONTH_IN_SECONDS = 2592000;
export const FULL_YEAR_IN_SECONDS = 31536000;
export const TWO_DAYS_IN_SECONDS = 172800;
export const TWENTY_SECONDS_IN_MILLISECONDS = 20000;
export const ERROR_STACK_TRACE_LIMIT = 20;
export const MAX_NUMBER_OF_RETRIES = 2;
export const MAX_AMOUNT_OF_BADGES = 2;
export const MAX_AMOUNT_OF_DESCRIPTION_SECTIONS = 6;

export const SH3_SIZE = 512;
export const REDIS_TIMEOUT = FULL_DAY_IN_SECONDS * 5;
