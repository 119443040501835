


























import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { trustpilotMicroComboWidgetId } from '~/config';
import { useI18n } from '~/composables';
import { TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT } from '~/constants/ui';
import { TRUSTPILOT_LOGOS, TRUSTPILOT_THEMES } from '~/constants/trustPilot';
import ExternalLink from '~/components/atoms/Link/ExternalLink/ExternalLink.vue';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { TRUSTPILOT } from '~/constants/trustUrls';

export default defineComponent({
  name: 'TrustpilotMicroCombo',
  components: {
    ExternalLink,
    ImgView
  },
  props: {
    showWidget: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String as PropType<ValueOf<typeof TRUSTPILOT_THEMES>>,
      default: TRUSTPILOT_THEMES.LIGHT
    }
  },
  setup (props) {
    const { languageAndCountry } = useI18n();

    const logoUrl = computed(() => {
      const logoColor = props.theme === TRUSTPILOT_THEMES.DARK
        ? TRUSTPILOT_LOGOS.WHITE
        : TRUSTPILOT_LOGOS.BLACK;
      return `/logos/trustpilot-logo-${logoColor}.svg`;
    });

    return {
      languageAndCountry,
      trustpilotMicroComboWidgetId,
      TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT,
      TRUSTPILOT,
      logoUrl
    };
  }
});

