export const loadParcelPerform = (id: string) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://tracking.parcelperform.com/pp-widget.js';
  const wrapper = document.createElement('div');
  wrapper.setAttribute('id', 'pp-widget-root');
  wrapper.setAttribute('data-cms-slug', id);
  return new Promise((resolve) => {
    script.onload = () => {
      resolve(true);
    };
    document.querySelector('.pp-container')?.appendChild(wrapper);
    document.body.appendChild(script);
  });
};
