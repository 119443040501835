import { Logger } from '@vue-storefront/core';
import { ROUTES } from '~/constants/routes';
import { getNameForRoute } from '~/helpers/routes/getPageInformation';

// this middleware makes sure that the user is not logged in yet.
export default ({ $vsf, redirect }) => {
  $vsf.$ct.api.isLoggedIn().then((isLoggedIn) => {
    if (isLoggedIn) {
      redirect({ name: getNameForRoute(ROUTES.HOME), replace: true });
    }
  }).catch((error) => {
    Logger.warn('non-authenticated error: ', String(error));
  });
};
