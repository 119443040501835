import { AxiosResponse } from 'axios';
import { GrahamIntegration } from '~/types/integrations/graham/GrahamIntegration';
import { GrahamProductReview } from '~/types/product/attribute/GrahamProductReview';

export default (instance: GrahamIntegration) => {
  const { axios } = instance.services;

  return (productReview: GrahamProductReview): Promise<AxiosResponse> => {
    return axios.post('add-review', productReview);
  };
};
