import CountryLocalization from '../types/localization/countryLocalization';
import Localization from '../helpers/i18n/localization';
import { isProduction } from '../helpers/env/checkEnvironmentType';
import { INDEX_ENVIRONMENT_PREFIX } from '../constants/algolia';

const getAlgoliaIndexName = (languageAndCountry: string, localizationDefinitions: Localization): Maybe<string> => {
  const environmentPrefix = isProduction() ? INDEX_ENVIRONMENT_PREFIX.PRODUCTION : INDEX_ENVIRONMENT_PREFIX.STAGE;
  const countryLocalization = localizationDefinitions.getByLanguageAndCountry(languageAndCountry);

  if (!countryLocalization) {
    return null;
  }

  return `${environmentPrefix}${CountryLocalization.ALGOLIA_INDEX_NAME_SEPARATOR}${languageAndCountry}`;
};

export { getAlgoliaIndexName };
