import { Logger } from '@vue-storefront/core';
import { TEST_PRODUCT_SKU_BEGINNING_CHARS } from '~/constants/products';

export const isTestProduct = (sku: string): boolean => {
  if (!sku) {
    if (process.server) {
      // TODO - https://expondo.atlassian.net/browse/INSP-3871
      Logger.error('isTestProduct is incorrectly used');
    }
    return false;
  }
  return sku.startsWith(TEST_PRODUCT_SKU_BEGINNING_CHARS);
};
