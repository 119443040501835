import { EVENT_VIEW_ITEM_LIST } from '~/constants/googleTagManager';
import { TagProduct } from '~/types/integrations/tagManager/TagProduct';

/* eslint-disable camelcase */
export class ViewItemListTags {
  event = EVENT_VIEW_ITEM_LIST;
  item_list_id: string;
  item_list_name: string;
  ecommerce: { items: TagProduct[] }
  constructor({ id, name, itemsArray }: {
    id: string;
    name: string;
    itemsArray: TagProduct[]
  }) {
    this.item_list_id = id;
    this.item_list_name = name;
    this.ecommerce = { items: itemsArray };
  }
}
