import Vue from 'vue'

Vue.component('trustbox', {
  render (h) {
    return h('div', {
      class: ['trustpilot-widget'],
      attrs: {
        'data-locale': this.locale,
        'data-template-id': this.templateId,
        'data-businessunit-id': this.businessunitId,
        'data-style-height': this.styleHeight,
        'data-style-width': this.styleWidth,
        'data-theme': this.theme,
        'data-tags': this.tags,
        'data-schema-type': this.schemaType,
        'data-stars': this.stars,
        'data-review-languages': this.reviewLanguages
      },
    }, [
      h('a', {
        attrs: {
          href: this.truspilotPageURL,
          target: '_blank',
          rel: 'noopener',
        },
        domProps: { innerHTML: 'Trustpilot' },
      }),
    ])
  },

  props: {
    businessunitId: {
      type: String,
      default: '6180f0006bc505ee409c6af4',
    },

    businessunitName: {
      type: String,
      default: 'expondo.com',
    },

    templateId: {
      type: String,
      required: true,
    },

    locale: {
      type: String,
      default: 'en-US',
    },

    styleHeight: {
      type: String,
      default: 'auto',
    },

    styleWidth: {
      type: String,
      default: '100%',
    },

    theme: {
      type: String,
      default: 'light',
      validate: () => ['dark', 'light'].includes('light'),
    },

    tags: {
      type: String,
      default: '',
    },

    schemaType: {
      type: String,
      default: '',
    },

    stars: {
      type: String,
      default: '',
    },

    reviewLanguages: {
      type: String,
      default: ''
    }
  },

  mounted () {
    if (window.Trustpilot)
      this.$nextTick(() => window.Trustpilot.loadFromElement(this.$el))
  },

  computed: {
    truspilotPageURL () {
      const locale = this.locale.split('-')[0]
      const prefix = (locale !== 'en' ? `${locale}.` : '')

      return `https://${prefix}trustpilot.com/review/${this.businessunitName}`
    },
  },
})
