










import { defineComponent, computed } from '@nuxtjs/composition-api';
import { usePrefix } from '~/composables';

export default defineComponent({
  name: 'SimpleLink',
  props: {
    to: {
      type: String,
      required: true
    }
  },
  setup(props: { to: string }) {
    const { addPrefixToSlugIfNecessary } = usePrefix();
    const goToRoute = computed(() => addPrefixToSlugIfNecessary(props.to));
    return {
      goToRoute
    };
  }
});
