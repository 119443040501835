import { EVENT_CLICK_PARENT_PRODUCT } from '~/constants/googleTagManager';
import { EcommerceClickParentProduct } from '~/types/integrations/tagManager/TagClickParentProduct';

export class ClickParentProductTags {
  event = EVENT_CLICK_PARENT_PRODUCT
  ecommerce: EcommerceClickParentProduct = {
    detail: {
      items: {
        accessory_product_id: '',
        parent_id: ''
      }
    }
  }

  assignParentProductValues (parentProductSku: string, accessoryId: string) {
    this.ecommerce.detail.items = {
      parent_id: parentProductSku,
      accessory_product_id: accessoryId
    };
  }
}
