import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import type { ExcludedRelation } from '~/types/extraGuarantee/ExcludedRelations';

export const isSkuExcluded = (product: Maybe<ProductVariant>, excludedSkus: string[]): boolean => {
  const productSku = product?.sku;
  if (!productSku) {
    return true;
  }
  return !!excludedSkus?.includes(productSku);
};

export const isBrandExcluded = (
  product: Maybe<ProductVariant>,
  excludedBrands: string[],
  languageAndCountry: string
): boolean => {
  if (!product) {
    return true;
  }

  const productBrand = getProductAttribute(product, PRODUCT_ATTRIBUTES.BRAND, languageAndCountry);
  return !!excludedBrands?.includes(productBrand);
};

export const isRelationExcluded = (product: Maybe<ProductVariant>, excludedRelations: ExcludedRelation[]): boolean => {
  return !!excludedRelations?.some(relation => {
    return product?.attributesRaw.some(attr => attr.name === relation.key);
  });
};
