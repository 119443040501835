import { BloomreachIntegration } from '~/types/integrations/bloomreach/BloomreachIntegration.js';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { getRecommendationFactory } from '~/plugins/integrations/bloomreach/helpers/getRecommendationFactory';
import { BLOOMREACH_TYPE_NAME } from '~/types/localization/localizationHelperTypes';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';

export default (instance: BloomreachIntegration) => {
  const typeName = BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION;
  const getRecommendation = getRecommendationFactory({ instance, typeName });

  return async (product: ProductVariantExtended): Promise<BloomreachProduct[] | null> => {
    return await getRecommendation({ product });
  };
};
