import QueryParams from '~/types/generic/queryParams';

export default (queryObj: QueryParams, algoliaIndexName: string): QueryParams => {
  if (queryObj[algoliaIndexName]) {
    const nestedQueryParams = queryObj[algoliaIndexName];
    queryObj = {
      ...(typeof nestedQueryParams === 'object' ? nestedQueryParams : {})
    };
  }

  if (queryObj.sortBy?.toString().includes(algoliaIndexName)) {
    queryObj.sortBy = queryObj.sortBy.toString()
      .replace(`${algoliaIndexName}-`, '');
  }

  return queryObj;
};
