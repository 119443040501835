import Localization from '../helpers/i18n/localization';
import CountryLocalization from '../types/localization/countryLocalization';
import { countryConfigurations } from './country-configurations';

const countryConfigs: CountryLocalization[] = [];
countryConfigurations.forEach(config => {
  countryConfigs.push(new CountryLocalization(config));
});

const localizationDefinitions = new Localization(countryConfigs);

export default localizationDefinitions;
