import { ROUTES, ROUTES_INFORMATION } from '~/constants/routes';
import { DEFAULT_REDIRECTION_CODE } from '~/constants/seo';

export default ({ app: { context: { route } }, redirect }) => {
  const slug = route.path;
  if (slug === `${ROUTES_INFORMATION[ROUTES.HOME_ALTERNATIVE].SLUG}` ||
    slug === `${ROUTES_INFORMATION[ROUTES.HOME_ALTERNATIVE].SLUG}/`) {
    redirect(DEFAULT_REDIRECTION_CODE, ROUTES_INFORMATION[ROUTES.HOME].SLUG, route.query);
  }
};
