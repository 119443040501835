import type { Category } from '@vsf-enterprise/commercetools-types';

export const extractAncestorsFromCategory = (category?: Category): Category[] => {
  if (!category) {
    return [];
  }

  const ancestors = category.ancestors || [];
  category.ancestors = [];
  return ancestors;
};
