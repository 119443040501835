















import { defineComponent } from '@nuxtjs/composition-api';
import { useNavigationState } from '~/composables';
import { createOnSaleLink } from '~/helpers/navigation/createNavLinks';

export default defineComponent({
  name: 'NavMainBarOnSale',
  setup() {
    const {
      toggleNavigation,
      isNavigationOpen
    } = useNavigationState();

    const onSaleLink = createOnSaleLink();

    return {
      toggleNavigation,
      isNavigationOpen,
      onSaleLink
    };
  }
});
