import { getLocalization } from '~/plugins/locale/helper';
import { getPrefix } from '~/helpers/routes/prefixes';

export default (context: any) => {
  const INJECTED_SCRIPT_DEFAULTS = {
    hid: 'consentmanager',
    type: 'text/javascript',
    defer: false
  };

  const countryLocalization = getLocalization(context);
  const languagePrefix = getPrefix(context.route.path);

  if (countryLocalization) {
    const languageAndCountry = countryLocalization.getLanguageAndCountry(languagePrefix);
    const scriptUrl = countryLocalization.getConsentManagerScriptUrl(languageAndCountry);
    if (scriptUrl) {
      context.app.head.script.push({
        ...INJECTED_SCRIPT_DEFAULTS,
        src: scriptUrl
      });
    }
  }
};
