import { computed } from '@nuxtjs/composition-api';
import { useI18n } from '../useI18n';
import { PRICE_DISCLAIMER_COUNTRIES } from '~/constants/priceDisclaimerCountries';

export default function () {
  const { countryCode } = useI18n();
  const isPriceDisclaimerEnabled = computed(() => PRICE_DISCLAIMER_COUNTRIES.includes(countryCode.value));

  return { isPriceDisclaimerEnabled };
}
