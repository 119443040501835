import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { computed } from '@nuxtjs/composition-api';
import { useI18n, useProductExtraGuarantee } from '~/composables';
import type { ExcludedRelation } from '~/types/extraGuarantee/ExcludedRelations';
import { isSkuExcluded, isBrandExcluded, isRelationExcluded } from '~/helpers/extraGuarantee/exclusionRules';
import { getRawAttributeAsArray } from '~/helpers/product/getRawAttributeAsArray';
import { EXTRA_GUARANTEE_ATTRIBUTES } from '~/constants/extraGuarantee';
import { isAWareProduct } from '~/helpers/product/productCondition';

export const useExtraGuaranteeExcluded = () => {
  const { languageAndCountry } = useI18n();
  const {
    productExtraGuarantee: extraGuaranteeProduct
  } = useProductExtraGuarantee();

  const extraGuaranteeExcludedSkus = computed<string[]>(() => {
    return getRawAttributeAsArray(extraGuaranteeProduct.value, EXTRA_GUARANTEE_ATTRIBUTES.EXCLUDED_SKUS);
  });
  const extraGuaranteeExcludedBrands = computed<string[]>(() => {
    return getRawAttributeAsArray(extraGuaranteeProduct.value, EXTRA_GUARANTEE_ATTRIBUTES.EXCLUDED_BRANDS);
  });
  const extraGuaranteeExcludedRelations = computed<ExcludedRelation[]>(() => {
    return getRawAttributeAsArray(extraGuaranteeProduct.value, EXTRA_GUARANTEE_ATTRIBUTES.EXCLUDED_RELATIONS);
  });

  const isGuaranteeExcluded = (product: Maybe<ProductVariant>) => {
    return !extraGuaranteeProduct.value ||
      !isAWareProduct(product?.sku) ||
      isSkuExcluded(product, extraGuaranteeExcludedSkus.value) ||
      isRelationExcluded(product, extraGuaranteeExcludedRelations.value) ||
      isBrandExcluded(product, extraGuaranteeExcludedBrands.value, languageAndCountry.value);
  };

  return {
    isGuaranteeExcluded
  };
};
