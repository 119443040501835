const IMAGE_BANNER_DESKTOP_WIDTH: number = 768;
const IMAGE_BANNER_MOBILE_WIDTH: number = 375;
const IMAGE_SERVER_PREFIX = 'media';
const STAGE_ASSETS_DOMAIN = 'assets-stage.expondo.com';
const ASSETS_DOMAIN = 'assets.expondo.com';

const LOADING_MODE = {
  LAZY: 'lazy',
  EAGER: 'eager'
};

export {
  IMAGE_BANNER_DESKTOP_WIDTH,
  IMAGE_BANNER_MOBILE_WIDTH,
  IMAGE_SERVER_PREFIX,
  STAGE_ASSETS_DOMAIN,
  ASSETS_DOMAIN,
  LOADING_MODE
};
