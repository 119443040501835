import { useRouteExtended } from '~/composables';
import { ROUTES } from '~/constants/routes';
import { QUERY_KEY } from '~/constants/algolia';

export const useBrandPage = () => {
  const { getAdjustedSlug } = useRouteExtended();

  const getBrandPageUrl = (brandName: string): string => {
    return `${getAdjustedSlug(ROUTES.SEARCH)}?${QUERY_KEY.BRAND_FILTER}=${brandName}`;
  };

  return {
    getBrandPageUrl
  };
};
