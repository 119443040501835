import localizationDefinitions from '~/i18n/localizationDefinitions';
import { getPrefix } from '~/helpers/routes/prefixes';
import { DEFAULT_REDIRECTION_CODE } from '~/constants/seo';

export default ({ app: { context: { route, i18n } }, redirect }) => {
  const currentPrefix = route?.path && getPrefix(route.path);
  const acceptedLanguages =
    localizationDefinitions.getByCountryCode(i18n.localeProperties.country)?.getLanguages() || [];
  if (
    currentPrefix &&
    (!acceptedLanguages.includes(currentPrefix) || acceptedLanguages[0] === currentPrefix)
  ) {
    redirect(DEFAULT_REDIRECTION_CODE, '/', route.query);
  }
};
