import { ComponentData, ComponentProps, ExtractedComponent } from '~/types/cms/CmsComponentData';

const extractProps = (data: ComponentData): ComponentProps => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { component, ...props } = data;
  return props;
};

export const extractComponents = function (items: ComponentData[]): ExtractedComponent[] {
  if (!items || !items.length) return [];

  return items.map((item: ComponentData): ExtractedComponent => {
    return {
      componentName: item.component,
      props: extractProps(item)
    };
  });
};
