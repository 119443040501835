const { countryCodes } = require('../../i18n/enums/countryCodes');
const nuxtMultiCacheConfig = require('../../config/shared/configs/nuxtMultiCache');
const salesforceConfig = require('../../config/shared/configs/salesforce');
const blackFridayCampaign = require('../../config/shared/configs/blackFridayCampaign');

module.exports = {
  ...salesforceConfig,
  quickAddToCartRestrictedCountries: [countryCodes.AT, countryCodes.DE],
  itemsNeededForFirstDiscount: 3,
  itemsNeededForSecondDiscount: 5,
  itemsNeededForThirdDiscount: 10,
  firstDiscountPercentage: 5,
  secondDiscountPercentage: 10,
  thirdDiscountPercentage: 15,
  logFilesFolder: 'logs',
  ...nuxtMultiCacheConfig,
  newsletterOptionalCountries: [countryCodes.HU, countryCodes.PL],
  newTrustFeaturesCountries: [countryCodes.DE, countryCodes.PL],
  expondoVatId: 'DE263760174',
  trustpilotWidgetId: '5419b6a8b0d04a076446a9ad', // Micro Review Count widget
  trustpilotMiniWidgetId: '53aa8807dec7e10d38f59f32', // Mini Review Count widget
  trustpilotMicroComboWidgetId: '5419b6ffb0d04a076446a9af', // Micro Combo Widget
  trustpilotWidgetCaruselId: '53aa8912dec7e10d38f59f36',
  trustpilotWidgetMiniCaruselId: '539ad0ffdec7e10e686debd7',
  trustPilotBusinessUnitId: '6180f0006bc505ee409c6af4',
  trustPilotBusinessUnitName: 'expondo.com',
  seoRedirectionServerMiddlewarePath: '/seo-redirection/get-redirection',
  typesensePort: 443,
  typesenseProtocol: 'https',
  datadogClientToken: 'pubbedf0e40f9257ddaafa5eca11676e63c',
  webSocketPort: 2087,
  ...blackFridayCampaign
};
