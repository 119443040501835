import type { Category, RawCustomField } from '@vsf-enterprise/commercetools-types';
import { isRootCategory } from './isRootCategory';
import { CATEGORY_CUSTOM_FIELDS } from '~/constants/commerceTools.js';
import { StringBoolean } from '~/types/StringBoolean';
import { CATEGORY_TREE, CategoryTree } from '~/constants/categoryTree';

export const addEmptyChildren = (tree: Category[]): Category[] => tree.map(category => {
  if (category.children) {
    return { ...category, children: addEmptyChildren(category.children) };
  }
  category.children = [];

  return category;
});

export const extractChildCategories = (tree: Category[] | Readonly<Category[]>): Maybe<Category[]> => {
  return tree[0]?.children;
};

export const removeEmptyCategories = (tree: Category[], activeLanguageAndCountry: string): Category[] => {
  const hasActiveProducts = (category: Category, activeLanguageAndCountry: string): boolean => {
    let hasActiveProductsFlag = false;
    if (isRootCategory(category)) {
      hasActiveProductsFlag = true;
      return hasActiveProductsFlag;
    }
    category.custom?.customFieldsRaw?.forEach((customField: RawCustomField) => {
      if (customField.name === CATEGORY_CUSTOM_FIELDS.HAS_PRODUCTS) {
        if (customField.value[activeLanguageAndCountry] === StringBoolean.true) {
          hasActiveProductsFlag = true;
        }
      }
    });
    return hasActiveProductsFlag;
  };

  const removeEmptyCategoriesRecursive = (categories: Category[], activeLanguageAndCountry: string): Category[] => {
    const filteredCategories: Category[] = [];

    categories.forEach((category: Category) => {
      if (hasActiveProducts(category, activeLanguageAndCountry)) {
        if (category.children) {
          category.children = removeEmptyCategoriesRecursive(category.children, activeLanguageAndCountry);
        }
        if (category.parent) {
          category.parent = removeEmptyCategoriesRecursive([category.parent], activeLanguageAndCountry)[0];
        }
        if (category.ancestors) {
          category.ancestors = removeEmptyCategoriesRecursive(category.ancestors, activeLanguageAndCountry);
        }
        filteredCategories.push(category);
      }
    });

    return filteredCategories;
  };

  return removeEmptyCategoriesRecursive(tree, activeLanguageAndCountry);
};

export const findCategoryTreeByKey = (key?: Maybe<string>): CategoryTree | undefined => {
  return Object.values(CATEGORY_TREE).find(tree => tree.key === key);
};
