import { UseUserBillingErrors, UseUserShippingErrors } from '@vue-storefront/core';

const getUserAddressErrorOccurred = (error: UseUserBillingErrors|UseUserShippingErrors) => {
  return !!(error.addAddress ||
    error.setDefaultAddress ||
    error.updateAddress ||
    error.deleteAddress ||
    error.load);
};

export default getUserAddressErrorOccurred;
