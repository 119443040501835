


























import { computed, defineComponent } from '@vue/composition-api';
import { userGetters } from '@vsf-enterprise/commercetools';
import { useUserExtended } from '~/composables';
import { useMyAccount } from '~/composables/useMyAccount';
import { UseMyAccountPage } from '~/types/myaccount/UseMyAccountPage';

export default defineComponent({
  name: 'MyAccountDropdown',
  middleware: [
    'is-authenticated'
  ],
  setup(_props, { emit }) {
    const { urlList } = useMyAccount();
    const { user } = useUserExtended();

    const firstName = computed(() => userGetters.getFirstName(user.value));
    const lastName = computed(() => userGetters.getLastName(user.value));
    const emailAddress = computed(() => userGetters.getEmailAddress(user.value));

    const changePage = (page: ReturnType<UseMyAccountPage>) => {
      page.goToThatPage();
      emit('close');
    };

    return { changePage, urlList, user, userGetters, firstName, lastName, emailAddress };
  }
});
