<template>
  <transition name="sf-fade">
    <client-only>
      <div v-if="!isMobile">
        <slot />
      </div>
    </client-only>
  </transition>
</template>

<script>
import { useUiMode } from '~/composables';

export default {
  name: 'ClientDesktopOnly',
  setup() {
    const { isMobile } = useUiMode();
    return { isMobile };
  }
};
</script>
