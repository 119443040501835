const config = require('../../config');
const { StringBoolean } = require('../../types/StringBoolean');

const isWebSocketEnabled = process.env.ENABLE_WEBSOCKET === StringBoolean.true ||
  process.env.enableWebSocket === StringBoolean.true;

function isWebSocketEnabledForCountry(countryCode) {
  return isWebSocketEnabled && config.ENABLE_WEBSOCKET[countryCode];
}

module.exports = { isWebSocketEnabled, isWebSocketEnabledForCountry };
