import { Logger, useVSFContext } from '@vue-storefront/core';
import { computed } from '@vue/composition-api';
import { useCart } from '@vsf-enterprise/commercetools';
import { DISCOUNT_FIELDS } from '~/constants/commerceTools';
import { parseVolumeDiscounts } from '~/helpers/voumeDiscounts/parseVolumeDiscounts';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  VolumeDiscountsGetters,
  VolumeDiscountsMutations,
  VolumeDiscountsState
} from '~/types/vuex/VolumeDiscounts';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { VolumeDiscountGroups } from '~/types/volumeDiscounts';

export default () => {
  const { $ct } = useVSFContext();
  const {
    cart
  } = useCart();

  const store = defineModule<
    typeof MODULE_NAMES.VOLUME_DISCOUNTS_MODULE_NAME,
    VolumeDiscountsMutations,
    VolumeDiscountsState,
    VolumeDiscountsGetters
  >(MODULE_NAMES.VOLUME_DISCOUNTS_MODULE_NAME);

  const volumeDiscountGroups = computed(() => store.state.volumeDiscountGroups);

  const isGroupActive = (groupName: string) => {
    return store.getters.isGroupActive.value(groupName);
  };

  const load = async (discountGroup: string) => {
    if (volumeDiscountGroups.value[discountGroup]) {
      return;
    }
    try {
      const res = await $ct.api.getVolumeDiscounts({
        discountGroup,
        sort: [`${DISCOUNT_FIELDS.SORT_ORDER} asc`]
      });
      store.mutations.setGroup({
        groupKey: discountGroup,
        value: parseVolumeDiscounts(res?.data?.cartDiscounts?.results)
      });
    } catch (err) {
      Logger.error('error.useVolumeDiscount', String(err));
    }
  };

  const isDiscountCodeActive = computed(() => cart?.value?.discountCodes?.length !== 0);

  const hasNonEmptyArray = (volumeDiscountGroups: VolumeDiscountGroups): boolean =>
    Object.keys(volumeDiscountGroups)
      .some(key => {
        const group = volumeDiscountGroups?.[key];
        return Array.isArray(group) && group.length > 0;
      });

  const isVolumeDiscountApplicableForCart = computed(() => {
    return hasNonEmptyArray(volumeDiscountGroups.value);
  });

  const isDiscountConflict = computed(() => {
    return isVolumeDiscountApplicableForCart.value && isDiscountCodeActive.value;
  });

  return {
    isGroupActive,
    volumeDiscountGroups,
    load,
    isDiscountConflict,
    isDiscountCodeActive,
    isVolumeDiscountApplicableForCart
  };
};
