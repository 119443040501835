import { Gtm } from '~/types/@nuxtjs/gtm';
import { ViewHitsTags } from '~/plugins/integrations/tagManager/helpers/ViewHitsTags';
import type { TriggerViewHitsEventParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerViewHitsTagsWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerViewHitsEventParameters) => {
    const { userToken, index, objectIDs } = parameters;
    const viewHitsTags = new ViewHitsTags({ userToken, index, objectIDs });

    $gtm.push(viewHitsTags);
  };
};
