// TODO - get rid of this file once we can extract implementation from vsf-entreprise package - https://expondo.atlassian.net/browse/INSP-987
import type {
  Address, ResourceIdentifierInput, AddressInput, ProductVariant
} from '@vsf-enterprise/commercetools-types';
import { PRODUCT_VARIANT_ID } from '~/constants/commerceTools';
import virtualProductSku from '~/constants/virtualProductSku';
import { getStreetName } from '~/helpers/address/getStreetAddress';

export const addPayment = (payment: ResourceIdentifierInput) => ({
  addPayment: { payment }
});

export const addExtraGuaranteeLineItem = (parentSku: ProductVariant['sku']) => ({
  addLineItem: {
    sku: virtualProductSku,
    custom: {
      type: {
        key: 'ex_custom_line_items_types',
        typeId: 'type'
      },
      fields: [
        {
          name: 'parent_sku',
          value: JSON.stringify(parentSku)
        }
      ]
    }
  }
});

export const setBillingAddressAction = (
  billingDetails: Partial<Address>): { setBillingAddress: { address: AddressInput } } => ({
  setBillingAddress: {
    address: {
      id: billingDetails.id,
      title: billingDetails.title,
      salutation: billingDetails.salutation,
      firstName: billingDetails.firstName,
      lastName: billingDetails.lastName,
      streetName: getStreetName(billingDetails),
      streetNumber: '', // empty string is needed by Adyen Card Payment
      additionalStreetInfo: billingDetails.additionalStreetInfo,
      postalCode: billingDetails.postalCode,
      city: billingDetails.city,
      region: billingDetails.region,
      state: billingDetails.state,
      country: billingDetails.country,
      company: billingDetails.company,
      department: billingDetails.department,
      building: billingDetails.building,
      apartment: billingDetails.apartment,
      pOBox: billingDetails.pOBox,
      phone: billingDetails.phone,
      mobile: billingDetails.mobile,
      email: billingDetails.email,
      fax: billingDetails.fax,
      additionalAddressInfo: billingDetails.additionalAddressInfo
    }
  }
});

export const setCustomerEmail = (email: string) => ({
  setCustomerEmail: { email }
});

export const setShippingAddressAction = (
  shippingDetails: Partial<Address>): { setShippingAddress: { address: AddressInput } } => ({
  setShippingAddress: {
    address: {
      id: shippingDetails.id,
      title: shippingDetails.title,
      salutation: shippingDetails.salutation,
      firstName: shippingDetails.firstName,
      lastName: shippingDetails.lastName,
      streetName: getStreetName(shippingDetails),
      streetNumber: '', // empty string is needed by Adyen Card Payment
      additionalStreetInfo: shippingDetails.additionalStreetInfo,
      postalCode: shippingDetails.postalCode,
      city: shippingDetails.city,
      region: shippingDetails.region,
      state: shippingDetails.state,
      country: shippingDetails.country,
      company: shippingDetails.company,
      department: shippingDetails.department,
      building: shippingDetails.building,
      apartment: shippingDetails.apartment,
      pOBox: shippingDetails.pOBox,
      phone: shippingDetails.phone,
      mobile: shippingDetails.mobile,
      email: shippingDetails.email,
      fax: shippingDetails.fax,
      additionalAddressInfo: shippingDetails.additionalAddressInfo
    }
  }
});

export const addToCartSimplifiedObject = (sku: string) => {
  return {
    id: PRODUCT_VARIANT_ID,
    sku,
    images: [],
    attributesRaw: [],
    assets: []
  };
};
