import type { Address, Customer } from '@vsf-enterprise/commercetools-types';
import { UserBillingGetters, UserShippingGetters } from '@vue-storefront/core/lib/src/types';

export function getCurrentCountryAddresses(shipping: Readonly<Partial<Customer>>,
  addressGetter: UserBillingGetters<Partial<Customer>, Partial<Address>>
  | UserShippingGetters<Partial<Customer>, Partial<Address>>,
  country: string): Partial<Address>[] {
  const addresses: Partial<Address>[] = addressGetter.getAddresses(shipping);
  return addresses?.filter((address) => address.country.toLowerCase() === country);
}
