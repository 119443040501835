/**
 * Extracts the user ID from the Google Analytics device ID.
 * @example `GA1.1.xxxxxxxxxx.xxxxxxxxxx => xxxxxxxxxx.xxxxxxxxxx`
 * @link https://support.google.com/analytics/answer/11397207
 */
export function extractGoogleAnalyticsUserIdFromCookie(gaCookie: string): string {
  if (gaCookie) {
    // Remove the Google Analytics tracker from the device ID.
    const userIdParts = gaCookie.split('.').slice(-2);
    if (userIdParts.length === 2) {
      return userIdParts.join('.');
    }
  }
  return '';
}
