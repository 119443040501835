import { getDiscountMinVolume } from '~/helpers/voumeDiscounts/getDiscountMinVolume';
import { VolumeDiscount, VolumeDiscountRaw } from '~/types/volumeDiscounts';

export const parseVolumeDiscounts = (rawDiscounts?: VolumeDiscountRaw[]): VolumeDiscount[] => {
  return rawDiscounts?.flatMap(discount => {
    const volume = getDiscountMinVolume(discount.target?.predicate);
    if (!volume) {
      return [];
    }

    const discountPercentage = (discount.value?.permyriad || 0) / 100;
    if (!discountPercentage) {
      return [];
    }

    return [{ volume, discountPercentage }];
  }) || [];
};
