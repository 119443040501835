import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { productGetters } from '@vsf-enterprise/commercetools';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';

export const getProductPriceAfterDiscounts = (product: ProductVariant) =>
  productGetters.getPrice(product).regular ?? 0;

export const extractStandardPrice = (attributesLocalized?: ProductProcessedAttributes): number|null => {
  return attributesLocalized?.standardPrice
    ? JSON.parse(attributesLocalized.standardPrice)?.centAmount / 100
    : null;
};
