
import { computed } from '@nuxtjs/composition-api';
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import { usePageEventLocation } from '~/composables';

export const useProductBoxClickLocation = () => {
  const { getCurrentPageType } = usePageEventLocation();

  const productBoxClickLocation = computed(() => {
    const pageType = getCurrentPageType();
    switch (pageType) {
      case EVENT_SOURCE.HOMEPAGE:
        return EVENT_SOURCE.HOMEPAGE;
      case EVENT_SOURCE.PDP:
        return EVENT_SOURCE.ACCESSORIES;
      case EVENT_SOURCE.SEARCH_LISTING:
        return EVENT_SOURCE.SEARCH_LISTING;
      case EVENT_SOURCE.CATEGORY_LISTING:
        return EVENT_SOURCE.CATEGORY_LISTING;
      default:
        return '';
    }
  });

  return {
    productBoxClickLocation
  };
};
