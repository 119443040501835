import { computed } from '@nuxtjs/composition-api';
import { useWindowSize } from '@vueuse/core';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import { AnchorNavMutations } from '~/types/vuex/AnchorNav';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import type { AnchorNavOptions } from '~/types/product/AnchorNav';

export default function () {
  const store = defineModule<typeof MODULE_NAMES.ANCHOR_NAV_MODULE_NAME,
  AnchorNavMutations,
  {},
  {}>(MODULE_NAMES.ANCHOR_NAV_MODULE_NAME);

  const activeSection = computed(() => store.state.activeSection);
  const stickyAnchorNav = computed(() => store.state.stickyAnchorNav);
  const stickyAnchorNavHeight = computed(() => stickyAnchorNav.value?.offsetHeight || 0);
  const { height: windowHeight } = useWindowSize();
  const observerRootMargin = computed(() =>
    `-${stickyAnchorNavHeight.value}px 0px -${windowHeight.value - stickyAnchorNavHeight.value}px 0px`
  );

  const setActiveSection = (id: AnchorNavOptions) => {
    store.mutations.setActiveSection(id);
  };

  const setStickyAnchorNav = (element: HTMLElement) => {
    store.mutations.setStickyAnchorNav(element);
  };

  return {
    setActiveSection,
    setStickyAnchorNav,
    activeSection,
    stickyAnchorNavHeight,
    observerRootMargin
  };
}
