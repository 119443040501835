










import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ExternalLink',
  props: {
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      required: true
    },
    blank: {
      type: Boolean,
      default: false
    }
  }
});
