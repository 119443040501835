const COMPONENTS = {
  TRUSTED_PAYMENTS: {
    SLUG: 'trusted-payments'
  },
  APP_FOOTER: {
    SLUG: 'app-footer'
  },
  PHONE_NUMBER: {
    SLUG: 'phone-number'
  },
  CREATE_ACCOUNT_PROMO: {
    SLUG: 'create-account-promo'
  },
  CREATE_ACCOUNT_INFO: {
    SLUG: 'create-account-info'
  },
  NOTIFICATION_BANNER: {
    SLUG: 'notification-banner'
  },
  PROMO_BANNERS: {
    SLUG: 'promo-banners'
  },
  SHOP_BY_DEPARTMENT: {
    SLUG: 'shop-by-department'
  },
  TRUSTED_BRANDS: {
    SLUG: 'trusted-brands'
  },
  CAMPAIGN_BANNER: {
    SLUG: 'campaign-banner'
  },
  CAMPAIGN_TITLE: {
    SLUG: 'campaign-title'
  },
  CAMPAIGN_ADVERT: {
    SLUG: 'campaign-advert-banner'
  }
};

const PAGE_DELIVERY_KEYS = {
  HOME: {
    SLUG: 'home'
  },
  LANDER: {
    SLUG: 'lander'
  }
};

const INTERNAL_CMS_BASE_PATH = '/cms-schemas/';
const INTERNAL_CMS_COMPONENTS_PATH = `${INTERNAL_CMS_BASE_PATH}components/`;
const INTERNAL_CMS_PAGES_PATH = `${INTERNAL_CMS_BASE_PATH}pages/published/`;

const PRODUCT_GROUPS_SLIDER = {
  MIN_ITEMS: 3,
  SLIDER_GAP: 16,
  MAX_VISIBLE_ITEMS: 6,
  MOBILE_IMAGE_HEIGHT: 100,
  CAROUSEL_ITEM_WIDTH: '225px'
};

module.exports = {
  COMPONENTS,
  PAGE_DELIVERY_KEYS,
  INTERNAL_CMS_COMPONENTS_PATH,
  INTERNAL_CMS_PAGES_PATH,
  PRODUCT_GROUPS_SLIDER
};
