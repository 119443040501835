





























import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import VueI18n from 'vue-i18n';
import {
  ButtonSizes,
  ButtonSizesArray,
  ButtonType,
  ButtonTypesArray
} from '~/components/atoms/Buttons/Button/ButtonTypes';
import { usePrefix } from '~/composables';

export default defineComponent({
  name: 'Button',
  components: {
    SfButton
  },
  props: {
    /**
     * The TYPE of button
     * @values primary, secondary, tertiary...
     * */
    type: {
      type: String as PropType<ButtonType>,
      default: 'primary',
      validator: (val: ButtonType) => ButtonTypesArray.includes(val)
    },
    /** Is this a form submit button? */
    isSubmitType: {
      type: Boolean,
      default: false
    },
    /** Translated text for the button */
    text: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: ''
    },
    /**
     * Desired button size
     * @values sm, md, lg
     */
    size: {
      type: String as PropType<ButtonSizes>,
      default: 'lg',
      validator: (val: ButtonSizes) => ButtonSizesArray.includes(val)
    },
    /** URL to follow on click */
    url: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isRoundChevronMobile: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const formattedText = computed(() => String(props.text));
    const { addPrefixToSlugIfNecessary } = usePrefix();
    const goToRoute = computed(() => addPrefixToSlugIfNecessary(props.url));
    const loadingClass = computed(() => props.loading && 'animate-pulse button--loading');

    const classList = computed(() => {
      const classes = [];

      if (props.type !== 'raw') {
        classes.push('w-full', props.size);
      }

      classes.push(loadingClass.value, props.type);

      return classes;
    });

    return {
      loadingClass,
      formattedText,
      goToRoute,
      classList
    };
  }
});
