import { VOLUME_LESS_OR_EQUAL_THAN } from '~/constants/regex';

export const getDiscountMinVolume = (predicate?: string): Maybe<number> => {
  if (!predicate) {
    return null;
  }

  const volumeString = predicate.match(VOLUME_LESS_OR_EQUAL_THAN)?.groups?.volume || '';
  const volume = parseInt(volumeString);

  return volume || null;
};
