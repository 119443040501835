import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';

export const getCartRecommendationsData = (product?: ProductVariantExtended) => {
  const breadcrumbs = product?._breadcrumbs;
  if (!breadcrumbs || !breadcrumbs[0]) {
    return {};
  }
  const firstBreadcrumbs = breadcrumbs[0];
  return {
    sourceSku: product?.sku,
    categoryLvl2: firstBreadcrumbs[2]?.name,
    categoryLvl3: firstBreadcrumbs[3]?.name,
    productTitle: product?._name
  };
};
