import axios from 'axios';
const listOfProviders = ['google', 'facebook'];

export const createUserCookie = (token, cookie, provider) => {
  if (listOfProviders.includes(provider)) {
    const tokenType = 'Bearer';
    const sessionLength = 60 * 60 * 24;
    /** Strategy token splitted as the structure of it looks like this: 'Bearer TOKEN_VALUE' */
    const strategyToken = token.split(' ')[1];
    return axios.post(process.env.AUTH_SERVER_SYNC_ENDPOINT, {
      accessToken: strategyToken,
      tokenType,
      provider,
      sessionTtl: sessionLength
    }, {
      auth: {
        username: process.env.AUTH_SERVER_USERNAME,
        password: process.env.AUTH_SERVER_PASSWORD
      }
    })
      .then((response) => {
        if (cookie && !cookie?.scope.includes('customer_id') && cookie.access_token) {
          cookie.scope += ` customer_id:${response.data.customerId}`;
          cookie.access_token = strategyToken;
        }
        return cookie;
      });
  }
};
