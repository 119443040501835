/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { ConsentRequest } from '~/types/integrations/cia/event/consent/ConsentRequest';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { ConsentProps } from '~/types/integrations/cia/event/consent/ConsentProps';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { locale, userId, posthogDistinctId } = instance.state;

  return (
    consents: ConsentProps,
    gdprPhone: string = '',
    additionalProps: Object = {},
    emailToken: string = ''
  ): Promise<AxiosResponse> => {
    if (isEmpty(consents)) {
      return;
    }

    const payload: ConsentRequest = {
      locale: locale.value,
      consents,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      additional_props: additionalProps,
      posthog_identifier: posthogDistinctId.value
    };

    if (gdprPhone) {
      /** getting rid of empty spaces in phone number string */
      payload.gdpr_phone = gdprPhone.replace(/\s+/g, '');
    }

    if (emailToken) {
      payload.email_token = emailToken;
    }

    if (userId.value) {
      payload.user_id = userId.value;
    }

    return axios.post('consent', payload);
  };
};
