import { EVENT_VIEW_PARENT_PRODUCTS } from '~/constants/googleTagManager';
import { ViewParentProducts, EcommerceViewParentProducts } from '~/types/integrations/tagManager/TagViewParentProducts';

export class ViewParentProductsTags {
  event = EVENT_VIEW_PARENT_PRODUCTS
  ecommerce: EcommerceViewParentProducts = {
    detail: {
      accessory_id: '',
      items: {
        parent_count: 0,
        parent_ids: []
      }
    }
  }

  assignSourceAccessoryId (accessorySku: string) {
    this.ecommerce.detail.accessory_id = accessorySku;
  }

  assigParentProductsValues (viewParentProductItems: ViewParentProducts) {
    this.ecommerce.detail.items = viewParentProductItems;
  }
}
