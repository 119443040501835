import type { Category } from '@vsf-enterprise/commercetools-types';

function compareOrderHint(categoryA: Category, categoryB: Category): number {
  return parseFloat(categoryA.orderHint) - parseFloat(categoryB.orderHint);
}

export function sortCategories(categories?: Maybe<Category[]>): void {
  if (!categories) {
    return;
  }
  categories.sort(compareOrderHint);
}
