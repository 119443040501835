import type { IVueI18n } from 'vue-i18n';

export const i18nToAlgoliaMainIndex = (i18n: IVueI18n): string => {
  return i18n.localeProperties.algoliaIndexName;
};

export const i18nToCurrentCountryCode = (i18n: IVueI18n): string => {
  return i18n.localeProperties.country;
};

export const i18nToCurrentDomain = (i18n: IVueI18n): Maybe<string> => {
  return i18n.localeProperties.domain;
};

export const i18nToLanguageAndCountry = (i18n: IVueI18n): string => {
  return i18n.localeProperties.code;
};
