import { ref, onMounted, onUnmounted } from '@vue/composition-api';
import { throttle } from 'lodash';
import { FLOATING_ADD_TO_CART_DELAY, MAIN_ADD_TO_CART_ID } from '~/constants/floatingAddToCart';

export const useFloatingATCSection = () => {
  const showFloatingAddToCart = ref<boolean>(false);
  const mainAddToCart = ref<Nullable<HTMLElement>>(null);

  const areWeBelowMainATC = (): boolean => {
    if (!mainAddToCart.value) {
      return false;
    }

    const buttonRect = mainAddToCart.value.getBoundingClientRect();
    return buttonRect.bottom <= 0;
  };

  const checkSectionVisibility = throttle(() => {
    showFloatingAddToCart.value = areWeBelowMainATC();
  }, FLOATING_ADD_TO_CART_DELAY);

  const addScrollEvent = () => { window.addEventListener('scroll', checkSectionVisibility, { passive: true }); };
  const removeScrollEvent = () => { window.removeEventListener('scroll', checkSectionVisibility); };

  onMounted(() => {
    mainAddToCart.value = document.getElementById(MAIN_ADD_TO_CART_ID);
    addScrollEvent();
    checkSectionVisibility();
  });

  onUnmounted(() => {
    removeScrollEvent();
  });

  return { showFloatingAddToCart };
};
