import { useRouteExtended } from '~/composables';
import { ROUTES } from '~/constants/routes';

export const useRouteBestPriceGuarantee = () => {
  const { getAdjustedSlug } = useRouteExtended();

  const priceGuaranteeUrl = getAdjustedSlug(ROUTES.BEST_PRICES);

  return {
    priceGuaranteeUrl
  };
};
