import {
  REMOVE_FROM_CART_EVENT_NAME,
  REMOVE_FROM_CART_EVENT_DETAIL,
  REMOVE_FROM_CART_DEFAULT_EVENT_TYPE,
  PRIMARY_CATEGORY
} from '~/constants/googleTagManager';

import { TagProduct } from '~/types/integrations/tagManager/TagProduct';

export class RemoveFromCartTags {
  event = REMOVE_FROM_CART_EVENT_NAME
  eventInfo = { detail: REMOVE_FROM_CART_EVENT_DETAIL, text_displayed: '', triggered_in: '' };

  /* eslint eslint-comments/no-use: off */
  // eslint-disable-next-line camelcase
  public category: { event_type: string, primary_category: string };
  public ecommerce: { items?: Array<TagProduct> }

  constructor(productTags: TagProduct, textDisplayed: string = '', triggeredIn: string = '') {
    this.category = {
      event_type: REMOVE_FROM_CART_DEFAULT_EVENT_TYPE,
      primary_category: PRIMARY_CATEGORY
    };
    this.eventInfo.text_displayed = textDisplayed;
    this.eventInfo.triggered_in = triggeredIn;

    this.ecommerce = { items: [productTags] };
  }
}
