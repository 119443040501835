import { PromotionTags } from '~/plugins/integrations/tagManager/helpers/PromotionTags';
import { preparePromotionTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerPromotionTagsParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerPromotionTagsWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerPromotionTagsParameters) => {
    const promotionTags = new PromotionTags();

    const tagsForPromotion = preparePromotionTags(parameters.promotionImageName, parameters.promotionImagePosition);
    if (parameters.isClick) {
      promotionTags.assignClickPromotionValues(tagsForPromotion);
    } else {
      promotionTags.assignViewPromotionValues(tagsForPromotion);
    }

    $gtm.push(promotionTags);
  };
};
