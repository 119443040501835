import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { ViewProductTags } from '~/plugins/integrations/tagManager/helpers/ViewProductTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';

export const triggerViewProductTagsWrapper = ($gtm: Gtm, languageAndCountry: string) => {
  return (product: ProductVariant, currency: string, inStock: boolean) => {
    const viewProductTags = new ViewProductTags();
    const productTags = prepareProductTags(
      product,
      {
        currency,
        languageAndCountry,
        quantity: 1,
        inStock
      }
    );

    viewProductTags.assignProductValues(productTags);
    viewProductTags.assignActionField(productTags.item_category2);
    $gtm.push(viewProductTags);
  };
};
