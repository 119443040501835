import { RECOMMENDATION_VIEW } from '~/constants/googleTagManager';
import { TagProductBox } from '~/types/integrations/tagManager/TagProductBox';
import { EcommerceSimilarProductsView } from '~/types/integrations/tagManager/TagViewProduct';
import {
  BLOOMREACH_TYPE_NAME,
  BloomreachTypeName,
  RECOMMENDATION_DESCRIPTION
} from '~/types/localization/localizationHelperTypes';

export class RecommendationsShownTags {
  event: Maybe<ValueOf<typeof RECOMMENDATION_VIEW>> = null

  eventInfo = {
    detail: ''
  }

  ecommerce: EcommerceSimilarProductsView = {
    items: []
  }

  constructor(recommendationType?: BloomreachTypeName) {
    this.setEventInfo(recommendationType);
  }

  private setEventInfo (recommendationType?: BloomreachTypeName) {
    const currentEvent = recommendationType || BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS;
    this.event = RECOMMENDATION_VIEW[currentEvent];
    this.eventInfo.detail = RECOMMENDATION_DESCRIPTION[currentEvent];
  }

  assignProductValues (productTags: TagProductBox) {
    this.ecommerce.items.push(productTags);
  }
}
