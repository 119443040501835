import { renderToString } from '~/helpers/algolia/algoliaSSR/algoliaSSR';
import { ALGOLIA_GLOBAL_STATE_NAME, AlgoliaGlobalStateName } from '~/constants/algolia';

export const algoliaSSRRequiredFields = (
  globalStateName: AlgoliaGlobalStateName = ALGOLIA_GLOBAL_STATE_NAME.ALGOLIA_STATE
): any => ({
  serverPrefetch() {
    return this.instantsearch
      .findResultsState({
        component: this,
        renderToString
      }).then((algoliaState: unknown) => {
        this.$ssrContext.nuxt[globalStateName] = algoliaState;
      });
  },
  provide() {
    return {
      $_ais_ssrInstantSearchInstance: this.instantsearch
    };
  },
  beforeMount() {
    const results =
      (this.$nuxt.context && this.$nuxt.context.nuxtState[globalStateName]) ||
      window.__NUXT__[globalStateName];

    this.instantsearch.hydrate(results);

    // Remove the SSR state so it can't be applied again by mistake
    delete this.$nuxt.context.nuxtState[globalStateName];
    delete window.__NUXT__[globalStateName];
  }
});
